<template>
  <div class="more-section__items">
    <MoreSectionLink
      v-for="item in children"
      :key="item.label"
      v-bind="{ item, disabled }"
      @click="handleClick"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useNavigationStore } from '@/store/navigation'

import MoreSectionLink from './MoreSectionLink.vue'

type Props = {
  parent?: string
  disabled?: boolean
}

type Emits = {
  click: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const navigationStore = useNavigationStore()

const children = computed(() =>
  props.parent ? navigationStore.getChildNavigation(props.parent) : [],
)

const handleClick = () => {
  emit('click')
}
</script>

<style></style>
