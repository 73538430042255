<template>
  <UIInputDropdown
    v-model="modelValue"
    v-bind="{
      data,
      dataRefid,
      disabled,
      hideListOnLoad: true,
      error,
      focusOnLoad,
      label,
      silentError,
      size,
    }"
    placeholder="Find an option"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { AssetClass } from '..'
import { DataFieldSizes } from '@types'

import { ASSET_FIELD } from '../utils/const'

import { orderedList } from '@/helpers/common'

import { useAssetsStore } from '@/store/assets'

import { UIInputDropdown } from '@ui'

type Props = {
  instance: AssetClass
  disabled?: boolean
  label?: string
  focusOnLoad?: boolean
  dataRefid?: string
  size?: DataFieldSizes
  restrictions?: string[]
}

const props = defineProps<Props>()

const assetsStore = useAssetsStore()

const modelValue = props.instance.field<string>(ASSET_FIELD.TYPE)

const isSavedAsset = inject('isSavedAsset', ref(false))
const injectedRestrictions = inject<string[]>('restrictions', [])

const error = computed(() => props.instance.errors.type)
const silentError = computed(() => !isSavedAsset.value)

const data = computed(() =>
  orderedList(assetsStore.getAssetsTypes.filter(filterTypesByRestrictions)),
)

const filterTypesByRestrictions = (type: string) =>
  (!injectedRestrictions?.length || injectedRestrictions.includes(type)) &&
  (!props.restrictions || props.restrictions.includes(type))
</script>

<script lang="ts">
export default {
  name: 'AssetFormType',
}
</script>
