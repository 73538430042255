<template>
  <MenuItem
    v-bind="{ item }"
    :show-triggers="['hover']"
    :hide-triggers="['hover']"
    :popper-show-triggers="['hover']"
    :popper-hide-triggers="['hover']"
  >
    <RouterLink
      :to="{ name: item.route, params: item.routeParams }"
      class="main-desktop-panel__button"
      :data-refid="dataRefId"
      :class="mainClasses"
    >
      <div class="main-desktop-panel__button-icon">
        <component :is="item.icon" aria-hidden="true" />
      </div>
      <div class="main-desktop-panel__button-caption">
        {{ item.label }}
      </div>
    </RouterLink>
    <template v-if="!wide" #before>
      <div class="main-desktop-panel-item__caption">{{ item.label }}</div>
    </template>
  </MenuItem>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { upperFirst } from 'lodash'

import { NavigationItem } from '@types'

import MenuItem from './components/MenuItem.vue'

type Props = {
  item: NavigationItem
  wide?: boolean
}

const props = defineProps<Props>()

const dataRefId = computed(
  () =>
    `panelMenuItem${props.item.label
      ?.split(' ')
      .map(item => upperFirst(item))
      .join('')}`,
)

const mainClasses = computed(() => ({
  'main-desktop-panel-item--active': props.item.active,
}))
</script>

<style>
.main-desktop-panel-item {
  &--active {
    @apply bg-body-dark;
    @apply !text-primary dark:!text-indigo-500;
  }

  &__caption {
    @apply py-2 px-4;
    @apply text-xs font-medium uppercase;
    @apply text-gray-400;
  }
}
</style>
