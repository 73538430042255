<template>
  <div class="wizzard__form">
    <div class="real-estate-step-2__grid">
      <component
        :is="transaction.getFormDate()"
        :instance="transaction"
        label="Date of purchase"
      />
      <component
        v-bind="{ focusOnLoad }"
        :is="transaction.getFormAmount2()"
        :instance="transaction"
        label="Cost"
        hide-sign
        signed
      />
    </div>
    <component
      :is="transaction.getFormDescription()"
      :instance="transaction"
      label="Description"
      placeholder="Optional"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AssetClass, TransactionClass } from '@types'

type Props = {
  transaction: TransactionClass
  asset: AssetClass
}

const props = defineProps<Props>()

defineOptions({ inheritAttrs: false })

const focusOnLoad = computed(() => !props.transaction.entries[1]?.amount)
</script>

<style>
.real-estate-step-2__grid {
  @apply grid grid-cols-1 sm:grid-cols-2 gap-4;
}
</style>
