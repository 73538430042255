<template>
  <component :is="child.icon" class="ui-dropdown__item-icon" />
  <div class="ui-dropdown__item-text">
    {{ child.label }}
  </div>
  <div
    v-if="child.key"
    v-tooltip.right="tooltipLabel"
    class="main-menu-item-child__pin"
    :class="pinClasses"
    @click="togglePin"
  >
    <component :is="pinComponent" aria-hidden="true" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { NavigationItem } from '@types'

import { useUserSettingsStore } from '@/store/user/settings'

import { useLocale } from '@/plugins/i18n'

import PinIcon from '@/assets/images/icons/pin.svg'
import PinnedIcon from '@/assets/images/icons/pinned.svg'

type Props = {
  child: NavigationItem
}

const props = defineProps<Props>()

const { t } = useLocale('layouts.Main')
const userSettingsStore = useUserSettingsStore()

const isPinned = computed(
  () =>
    props.child.key &&
    userSettingsStore.getPinnedMenu.includes(props.child.key),
)

const pinComponent = computed(() => (isPinned.value ? PinnedIcon : PinIcon))

const pinClasses = computed(() => ({
  'main-menu-item-child__pin--pinned': isPinned.value,
}))

const tooltipLabel = computed(() =>
  isPinned.value ? t('Unpin view') : t('Pin view'),
)

const togglePin = () => {
  props.child.key && userSettingsStore.togglePinMenu(props.child.key)
}
</script>

<style>
.main-menu-item-child {
  &__pin {
    @apply w-5 h-5;
    @apply flex items-center justify-center;
    @apply -mr-1 ml-2;
    @apply rounded-full;
    @apply text-light-text;
    @apply hover:bg-indigo-100;

    &--pinned {
      @apply text-indigo-300 dark:text-indigo-600;
    }

    svg {
      @apply w-3 h-3;
    }
  }
}
</style>
