import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { defineStore } from 'pinia'

import { ContactClass } from '@types'

import { generateId } from '@/entities/utils/helpers'
import { isDirtyList, unsavedGridBlocker } from '../utils/helpers'

import { ROUTE_NAME } from '@/const'

import { useMainStore } from '@/store/main'
import { useContactsStore } from './'
import { useRepositoriesStore } from '../repositories'

export const useContactsBunchStore = defineStore('contacts-bunch', () => {
  // INIT

  const mainStore = useMainStore()
  const contactsStore = useContactsStore()
  const repositoryStore = useRepositoriesStore()

  const router = useRouter()

  const contactsList = computed(() => contactsStore.getList)

  // STORE

  const list = ref<Map<string, ContactClass>>(new Map())

  // ACTIONS

  const createElement = () => {
    const id = generateId()
    const repository_id = repositoryStore.getCurrentRepository?.id
    const element = new ContactClass({ id, repository_id }, true)
    const entries = Array.from(list.value.entries())
    list.value = new Map([[id, element], ...entries])
    return element
  }

  const unshiftElement = (element: ContactClass) => {
    const entries = Array.from(list.value.entries())
    list.value = new Map([[element.id, element], ...entries])
    return element
  }

  const duplicateElement = (id: string) => {
    const entries = Array.from(list.value.entries())
    const index = entries.findIndex(item => item[0] === id)
    if (index > -1) {
      const id = generateId()
      const entry = entries[index][1]
      const data = entry.get()
      const element = new ContactClass({ ...data, id }, true)
      const prefixBunch = entries.slice(0, index + 1)
      const postfixBunch = entries.slice(index + 1)
      list.value = new Map([...prefixBunch, [id, element], ...postfixBunch])
    }
  }

  // GETTERS

  const getList = computed(() => (list.value.size ? list.value : new Map()))

  const getElementById = (id: string) => list.value.get(id) as ContactClass

  const isDirty = computed(() => isDirtyList(list.value))

  // FILL STORE

  watch(contactsList, value => {
    value.forEach(item => {
      if (!list.value.get(item.id)) {
        const instance = new ContactClass(item)
        list.value.set(item.id, instance)
      }
    })
  })

  // ADD BLOCKER IF DIRTY

  watch(isDirty, value => {
    if (value) {
      mainStore.addBlocker(
        'contactsHasChanges',
        unsavedGridBlocker('contacts', () =>
          router.push({ name: ROUTE_NAME.CONTACTS }),
        ),
      )
    } else {
      mainStore.removeBlocker('contactsHasChanges')
    }
  })

  // CLEAR STORE

  const clear = () => {
    list.value = new Map()
  }

  return {
    createElement,
    unshiftElement,
    duplicateElement,

    getList,
    getElementById,
    isDirty,

    clear,
  }
})
