<template>
  <div v-if="alerts.length" class="app-alerts">
    <UIAlert
      v-for="alert in alerts"
      :key="alert.id"
      :type="alert.type"
      :closeable="alert.closeable"
      @remove="remove(alert.id)"
    >
      {{ alert.message }}
    </UIAlert>
  </div>
</template>

<script setup lang="ts">
import { useAlerts } from '@/plugins/alerts'

import { UIAlert } from '@ui'

defineOptions({
  name: 'AppAlerts',
})

const { alerts, remove } = useAlerts()
</script>

<style scoped>
.app-alerts {
  @apply flex flex-col;
  @apply md:gap-2 md:my-2;
}
</style>
