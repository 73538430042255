export const STEPS_LIST = [
  {
    name: 'Investing or Holding Entity',
  },
  {
    name: 'Investment',
  },
  {
    name: 'Valuation',
  },
  {
    name: 'Asset tags',
    description: 'optional',
  },
  {
    name: 'Contributions',
  },
  {
    name: 'Distributions',
  },
  {
    name: 'Summary',
  },
]

export const TRANSACTION_TYPE = ['subscribe and commit', 'subscribe and fund']

export const ASSET_CLASS = 'Asset Class'

export const ASSET_CLASS_LIST = [{ value: 'Alternative Investments' }]

export const INVESTMENT_STRATEGY = 'Investment Strategy'
export const INVESTMENT_STRATEGY_LIST = [
  { value: 'Private Equity' },
  { value: 'Private Credit' },
  { value: 'Growth & Venture' },
  { value: 'Hedge Fund' },
]

export const CONTRIBUTION_TYPES = [
  'capital call',
  'capital contribution',
  'asset fees',
]
export const DISTRIBUTION_TYPES = [
  'capital return',
  'recallable capital return',
  'cash dividend',
  'cash interest',
  'realized gain or loss',
  'current income',
]
