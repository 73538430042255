<template>
  <UIInputDropdown
    ref="fieldRef"
    v-model="modelValue"
    v-bind="{
      ...$attrs,
      data,
      dataRefid,
      disabled,
      error,
      label,
      silentError,
      size,
    }"
    id-key="key"
    placeholder="Find an option"
  >
    <template #item="{ item }">
      <div class="transaction-form-type__item" data-refid="inputDropdownItem">
        <div
          class="transaction-form-type__item-text"
          :class="
            item.readonly
              ? 'transaction-form-type__item-text--readonly'
              : undefined
          "
        >
          {{ item.value }}
        </div>
        <RouterLink
          v-if="item.readonly"
          :to="{ name: ROUTE_NAME.SUBSCRIPTION }"
          class="transaction-form-type__item-plan"
          @click.stop
        >
          Upgrade to PRO
        </RouterLink>
      </div>
    </template>
  </UIInputDropdown>
</template>

<script setup lang="ts">
import { computed, inject, ref, useTemplateRef, watch } from 'vue'

import { TransactionClass } from '..'
import { DataFieldSizes } from '@types'

import { TRANSACTION_FIELD } from '../utils/const'

import { compareEntries } from './utils/helpers'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'

import { UIInputDropdown } from '@ui'
import { ROUTE_NAME } from '@/const'

type Props = {
  dataRefid?: string
  instance: TransactionClass
  label?: string
  size?: DataFieldSizes
  disabled?: boolean
  restrictions?: string[]
}

const props = defineProps<Props>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = useTemplateRef('fieldRef')

const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

const modelValue = props.instance.field<string>(TRANSACTION_FIELD.TYPE)

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const error = computed(() => props.instance.errors.type)
const silentError = computed(() => !isSavedTransaction.value)

const data = computed(() => {
  let list = transactionsSettingsStore.getTypes
  if (props.restrictions) {
    list = list.filter(item => props.restrictions?.includes(item.value))
  }
  return list.map(item => ({
    key: item.value,
    ...item,
  }))
})

const currencyID = computed(() => {
  const asset =
    assetsBunchStore.getElementByName(
      repositoriesStore.getCurrentRepositoryCurrency,
    ) ||
    assetsBunchStore.getElementByTicker(
      repositoriesStore.getCurrentRepositoryCurrency,
    )
  return asset?.id
})

watch(modelValue, (value, oldValue) => {
  if (value === oldValue) return
  compareEntries(props.instance, assetsBunchStore, currencyID.value)
})
</script>

<style lang="postcss">
.transaction-form-type {
  &__item {
    @apply flex flex-auto items-center;
  }

  &__item-text {
    @apply flex-auto;

    &--readonly {
      @apply opacity-40;
    }
  }

  &__item-plan {
    @apply bg-indigo-500;
    @apply py-0.5 px-2;
    @apply rounded-full;
    @apply text-xs;
    @apply text-white;
    @apply cursor-pointer;
  }
}
</style>
