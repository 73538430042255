<template>
  <SelectRepository>
    <template
      #default="{
        items,
        postfixIcon,
        repositoryId,
        repositoryName,
        isActionsAllowed,
        handleClickItem,
      }"
    >
      <UIDropdown
        v-bind="{ items }"
        :model-value="repositoryId"
        :show-triggers="['hover']"
        :hide-triggers="['hover']"
        :popper-show-triggers="['hover']"
        :popper-hide-triggers="['hover']"
        id-key="id"
        value-key="name"
        placement="left-start"
        data-refid="repositoriesDropdown"
        popper-class="main-desktop-panel-repository"
        @click:item="handleClickItem"
      >
        <template #before>
          <div v-if="!isPanelOpened" class="main-desktop-panel-item__caption">
            Repositories
          </div>
        </template>
        <div class="main-desktop-panel__button">
          <div class="main-desktop-panel__button-icon">
            <RectangleStackIcon aria-hidden="true" />
            <component
              v-if="postfixIcon"
              :is="postfixIcon"
              class="main-desktop-panel__button-status-icon"
              aria-hidden="true"
            />
          </div>
          <div class="main-desktop-panel__button-caption">
            {{ repositoryName }}
          </div>
        </div>
        <template #item="{ item }">
          <SelectRepositoryItem v-bind="{ item }" @delete="handleDelete" />
        </template>
        <template #after="{ hide }">
          <SelectRepositoryActions v-if="isActionsAllowed" @click="hide" />
          <SelectRepositoryLimit v-else />
          <SelectRepositoryDelete
            v-if="deleteRepository"
            :repository="deleteRepository"
            @close="handleCancelDelete"
          />
        </template>
      </UIDropdown>
    </template>
  </SelectRepository>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'

import { OpenedFlag, Repository } from '@types'

import { PANEL_OPENED } from '@/const'

import SelectRepository from './components/SelectRepository.vue'
import SelectRepositoryActions from './components/SelectRepositoryActions.vue'
import SelectRepositoryDelete from './components/SelectRepositoryDelete.vue'
import SelectRepositoryItem from './components/SelectRepositoryItem.vue'
import SelectRepositoryLimit from './components/SelectRepositoryLimit.vue'
import { RectangleStackIcon } from '@heroicons/vue/24/outline'
import { UIDropdown } from '@ui'

const isPanelOpened = inject<OpenedFlag>(PANEL_OPENED)

const deleteRepository = ref<Repository>()

const handleDelete = (item: Repository) => {
  deleteRepository.value = item
}

const handleCancelDelete = () => {
  deleteRepository.value = undefined
}
</script>

<style>
.main-desktop-panel-repository {
  :deep() .ui-dropdown__items {
    @apply min-h-[7.5rem];
    @apply !max-h-[calc(100vh-10rem)];
  }

  .v-popper__arrow-container {
    @apply block;
  }
}
</style>
