export const ASSET_NAME = 'Asset'
export const ASSET_TYPE = 'Asset Type'
export const ACCOUNT_NAME = 'Account'
export const ACCOUNT_TYPE = 'Account Type'
export const CATEGORY_ACCOUNT_NAME = 'Category Account'
export const CATEGORY_ACCOUNT_TYPE = 'Category Account Type'

export const VALUE_NAME = 'Value'
export const GAIN_NAME = 'Gain'

export const VALUE_START_NAME = 'Value start'
export const CAPITAL_FLOW_NAME = 'Capital flow'
export const VALUE_END_NAME = 'Value end'
export const IRR_NAME = 'IRR'
export const MOIC_NAME = 'MOIC'

export const AVAILABLE_WIDGETS_KEY = Symbol()

export const CUSTOM_VIEW_NAME = 'Custom'
