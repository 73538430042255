<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog
      v-bind="{ zIndex, size: '2md' }"
      title="Add view"
      @hide="handleClose"
    >
      <div class="add-view-dialog">
        <AddDialogItem
          v-bind="{ item }"
          v-for="item in items"
          :key="item.name"
          @click="handleClickItem"
          @upgrade="handleClickUpgrade"
        />
        <AddDialogItem
          v-bind="{
            item: {
              label: CUSTOM_VIEW_NAME,
              icon: DocumentIcon,
            },
          }"
          @click="handleClickCustom"
        />
      </div>
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { TransitionRoot } from '@headlessui/vue'

import { AddDialogItem as TAddDialogItem } from './utils/types'
import { ModalClass, ModalState, UserPlanName, Widget } from '@types'

import {
  DASHBOARD_VIEWS,
  DEFAULT_DESKTOP_DASHBOARDS,
  ROUTE_NAME,
} from '@/const'
import { CUSTOM_VIEW_NAME } from '../utils/const'

import { generateSlug } from './utils/helpers'

import { useUserSubscriptionsStore } from '@/store/user/subscriptions'

import AddDialogItem from './AddDialogItem.vue'
import { UIDialog } from '@ui'
import { DocumentIcon } from '@heroicons/vue/24/outline'

type Props = {
  modal: ModalClass<any>
}

type Emits = {
  click: [data: Widget[], name: string, slug: string]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const router = useRouter()

const userSubscriptionsStore = useUserSubscriptionsStore()

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const plan = computed(() => userSubscriptionsStore.getCurrentPlanName)

const getDashboards = computed(() => Object.values(DASHBOARD_VIEWS.desktop))

const items = computed(() =>
  getDashboards.value?.map(item => ({
    label: item.name,
    name: item.slug,
    icon: item.icon,
    // TODO: figure out how to check the rights of the plan not so strictly
    unavailable:
      plan.value === UserPlanName.STANDARD && item.slug === 'commitments',
    // unavailable: !DEFAULT_DESKTOP_DASHBOARDS[plan.value].includes(item),
  })),
)

const handleClose = () => {
  props.modal.conditionalClose()
}

const handleClickItem = (data: TAddDialogItem) => {
  props.modal.close()
  const widgets =
    getDashboards.value.find(item => item.slug === data.name)?.widgets || []
  emit('click', widgets || [], data.label, generateSlug())
}

const handleClickCustom = () => {
  handleClickItem({
    label: CUSTOM_VIEW_NAME,
  })
}

const handleClickUpgrade = () => {
  props.modal.close()
  router.push({ name: ROUTE_NAME.SUBSCRIPTION })
}
</script>

<style>
.add-view-dialog {
  @apply grid grid-cols-2 md:grid-cols-3;
  @apply gap-3 p-4;
}
</style>
