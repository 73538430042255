<template>
  <div class="ui-skeleton" :class="mainClasses" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  rounded?: boolean
  static?: boolean
  variant?: 'dark' | 'semi-dark'
}

const props = defineProps<Props>()

const mainClasses = computed(() => ({
  'ui-skeleton--rounded': props.rounded,
  'ui-skeleton--static': props.static,
  [`ui-skeleton--${props.variant}`]: !!props.variant,
}))

defineOptions({
  name: 'UISkeleton',
})
</script>

<style scoped lang="postcss">
.ui-skeleton {
  @apply min-h-[1rem];
  @apply shrink-0;
  @apply bg-gray-100 dark:bg-gray-750;
  @apply animate-pulse;

  &--rounded {
    @apply rounded;
  }

  &--static {
    @apply animate-none;
  }

  &--dark {
    @apply bg-gray-300 dark:bg-gray-700;
  }

  &--semi-dark {
    @apply bg-gray-200 dark:bg-gray-700;
  }
}
</style>
