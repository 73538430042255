<template>
  <WizzardContainer
    v-if="asset && transaction"
    v-model="currentStep"
    v-model:visited="visited"
    v-bind="{ completed, skipped, isOpen, zIndex }"
    :steps="STEPS_LIST"
    title="Add Private Fund"
    shrink
    @close="handleClose"
  >
    <template #top>
      <PrivateFundType v-model="transactionType" />
    </template>
    <component
      :is="currentComponent"
      v-bind="{
        hasExistingAccounts,
        account,
        asset,
        transaction,
        prices,
        tags,
        assignTags,
        contributions,
        distributions,
      }"
      v-model:useExistingAccount="useExistingAccount"
      v-model:prices="prices"
      @update:tags="tags = $event"
      @update:contributions="contributions = $event"
      @update:distributions="distributions = $event"
    />
    <template #buttons>
      <UIButton
        v-bind="{ disabled }"
        :label="buttonLabel"
        @click="handleButtonClick(currentStep === 3)"
        tabindex="11"
      />
      <UIButton
        v-if="isSkipButtonVisible"
        label="Skip"
        variant="secondary"
        @click="handleButtonClick()"
        tabindex="10"
      />
    </template>
    <template v-if="loading" #loading>
      Please wait. <br />We are creating your private fund...
    </template>
  </WizzardContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'

import {
  AssetClass,
  AssetPriceForm,
  ModalClass,
  ModalState,
  TagClass,
  TransactionClass,
} from '@types'

import {
  ASSET_CLASS,
  ASSET_CLASS_LIST,
  INVESTMENT_STRATEGY,
  INVESTMENT_STRATEGY_LIST,
  STEPS_LIST,
  TRANSACTION_TYPE,
} from './utils/const'
import { ASSET_FIELD, NOTIFICATION_DELAY } from '@/const'

import { getCurrentDate } from '@/helpers/dates'
import { useNotifications } from '@/plugins/notification'
import { getAssetListValues } from '@/entities/transactions/components/utils/helpers'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useAssetsStore } from '@/store/assets'
import { useRepositoriesStore } from '@/store/repositories'
import { useTagsBunchStore } from '@/store/tags/bunch'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'

import { UIButton } from '@ui'
import {} from '@/entities/transactions'
import PrivateFundStep1 from './PrivateFundStep1.vue'
import PrivateFundStep2 from './PrivateFundStep2.vue'
import PrivateFundStep3 from './PrivateFundStep3.vue'
import PrivateFundStep4 from './PrivateFundStep4.vue'
import PrivateFundStep5 from './PrivateFundStep5.vue'
import PrivateFundStep6 from './PrivateFundStep6.vue'
import PrivateFundStep7 from './PrivateFundStep7.vue'
import PrivateFundType from './PrivateFundType.vue'
import WizzardContainer from '../components/WizzardContainer.vue'

type Props = {
  modal: ModalClass<any>
}

const props = defineProps<Props>()

defineOptions({
  components: {
    PrivateFundStep1,
    PrivateFundStep2,
    PrivateFundStep3,
    PrivateFundStep4,
    PrivateFundStep5,
    PrivateFundStep6,
    PrivateFundStep7,
  },
})

const { error, success } = useNotifications()

const assetsStore = useAssetsStore()
const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()
const tagsBunchStore = useTagsBunchStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

const currentStep = ref(0)
const loading = ref(false)

const useExistingAccount = ref(true)

const transactionType = ref<(typeof TRANSACTION_TYPE)[number]>(
  TRANSACTION_TYPE[0],
)
const account = ref<AssetClass>()
const asset = ref<AssetClass>()
const transaction = ref<TransactionClass>()
const prices = ref<AssetPriceForm[]>([])
const tags = ref({
  [ASSET_CLASS]: ASSET_CLASS_LIST[0].value,
  [INVESTMENT_STRATEGY]: INVESTMENT_STRATEGY_LIST[0].value,
})
const assignTags = ref<boolean>()
const contributions = ref<TransactionClass[]>([])
const distributions = ref<TransactionClass[]>([])

const visited = ref<number[]>([])
const completed = ref<number[]>([])
const skipped = ref<number[]>([])

const currentComponent = computed(
  () => `PrivateFundStep${currentStep.value + 1}`,
)

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const buttonLabel = computed(() => {
  const length = STEPS_LIST.length - 1
  switch (currentStep.value) {
    case 3:
      return 'Assign'
    case length:
      return 'Create fund'
    default:
      return 'Next step'
  }
})
const isSkipButtonVisible = computed(() => currentStep.value === 3)

const accountId = computed(() => transaction.value?.entries[0].account_id)

const isValidAccount = computed(() => {
  return (
    visited.value.includes(0) &&
    ((useExistingAccount.value && accountId.value) || account.value?.isValid)
  )
})

const isValidAsset = computed(() => {
  return visited.value.includes(1) && asset.value?.isValid
})

const isValidTransaction = computed(() => {
  if (!transaction.value) return false
  const keys = Object.keys(transaction.value.errors)
  return (
    visited.value.includes(1) &&
    (transaction.value.isValid ||
      keys.every(key =>
        [
          'entries[0].account_id',
          'entries[0].asset_id',
          'entries[1].asset_id',
        ].includes(key),
      ))
  )
})

const disabled = computed(() => {
  switch (currentStep.value) {
    case 0:
      return !isValidAccount.value
    case 1:
      return !isValidAsset.value || !isValidTransaction.value
  }
  return (
    !isValidAsset.value || !isValidAccount.value || !isValidTransaction.value
  )
})

const repositoryId = computed(() => repositoriesStore.getCurrentRepository?.id)
const repositoryCurrency = computed(
  () => repositoriesStore.getCurrentRepositoryCurrency,
)
const currencyAssetID = computed(() => {
  const asset =
    assetsBunchStore.getElementByName(
      repositoriesStore.getCurrentRepositoryCurrency,
    ) ||
    assetsBunchStore.getElementByTicker(
      repositoriesStore.getCurrentRepositoryCurrency,
    )
  return asset?.id
})

const hasExistingAccounts = computed(() => {
  if (!transaction.value?.settings) return
  const data = Array.from(assetsBunchStore.getList.values()).map(instance =>
    instance.get(),
  )
  return !!getAssetListValues(
    transaction.value.settings.entries[0].account,
    data,
  )?.length
})

const handleClose = () => {
  props.modal.close()
}

const handleCreate = async () => {
  if (!asset.value || !transaction.value) return
  loading.value = true
  try {
    const createAccount = !useExistingAccount.value && account.value
    if (createAccount) {
      await account.value?.store()
      account.value &&
        transaction.value.pasteAccountId1(account.value.id, false)
    }
    if (assignTags.value) {
      const tagsIds = []
      for await (const [tag_name, tag_value] of Object.entries(tags.value)) {
        if (!tag_value) return
        const tagInstance = tagsBunchStore.getElementByPair(tag_name, tag_value)
        let tagId
        if (tagInstance?.id) {
          tagId = tagInstance?.id
        } else {
          const tag = new TagClass({ tag_name, tag_value })
          await tag.store()
          tagId = tag.id
        }
        tagsIds.push(tagId)
      }
      if (tagsIds.length) {
        asset.value.field<string[]>(ASSET_FIELD.TAGS).value = tagsIds
      }
    }
    await asset.value.store()
    transaction.value.pasteAssetId1(asset.value.id, false)
    // assign prices
    await Promise.all(
      prices.value.map(
        price =>
          asset.value &&
          assetsStore.addAssetPrice(
            {
              ...price,
              asset_id: asset.value.id,
            },
            false,
          ),
      ),
    )
    // save contributions and distributions
    await Promise.all(
      [...contributions.value, ...distributions.value].map(item => {
        // assign account and asset
        accountId.value && item.pasteAccountId1(accountId.value, false)
        asset.value && item.pasteAssetId1(asset.value.id, false)
        return item.store()
      }),
    )
    // save subscribe and commit
    await transaction.value.store()
    await success(
      {
        message: 'Your Private Fund has been successfully created',
      },
      NOTIFICATION_DELAY,
    )
    // open asset summary
    asset.value?.openSummary(undefined, true)
    handleClose()
  } catch (e) {
    typeof e !== 'object' && (await error({ message: 'Something went wrong' }))
  } finally {
    loading.value = false
  }
}

const handleButtonClick = (assign?: boolean) => {
  if (assign) {
    assignTags.value = true
  } else if (currentStep.value === 3) {
    assignTags.value = false
  }
  switch (currentStep.value) {
    case 2:
      toggleItem(!!prices.value.length, 2)
      break
    case 4:
      toggleItem(!!contributions.value.length, 4)
      break
    case 5:
      toggleItem(!!distributions.value.length, 5)
      break
  }
  if (currentStep.value === STEPS_LIST.length - 1) {
    handleCreate()
    return
  }
  currentStep.value++
}

const pushItem = (array: number[], index: number) => {
  const itemIndex = array.findIndex(item => item === index)
  if (itemIndex !== -1) return
  array.push(index)
}

const pullItem = (array: number[], index: number) => {
  const itemIndex = array.findIndex(item => item === index)
  if (itemIndex === -1) return
  array.splice(itemIndex, 1)
}

const toggleItem = (value: boolean | undefined, index: number) => {
  if (value === false) {
    pushItem(skipped.value, index)
    pullItem(completed.value, index)
  } else if (value) {
    pushItem(completed.value, index)
    pullItem(skipped.value, index)
  }
}

watch(
  hasExistingAccounts,
  value => {
    if (value === undefined) return
    useExistingAccount.value = value
  },
  { immediate: true },
)

watch(assignTags, value => {
  toggleItem(value, 3)
})

watch(isValidAccount, value => {
  value ? pushItem(completed.value, 0) : pullItem(completed.value, 0)
})

watch(
  () => isValidAsset.value && isValidTransaction.value,
  value => {
    value ? pushItem(completed.value, 1) : pullItem(completed.value, 1)
  },
)

watch(
  () => account.value?.field(ASSET_FIELD.NAME).value,
  value => {
    account.value && (account.value.field(ASSET_FIELD.TICKER).value = value)
  },
)

watch(
  () => asset.value?.field(ASSET_FIELD.NAME).value,
  value => {
    asset.value && (asset.value.field(ASSET_FIELD.TICKER).value = value)
  },
)

watch(transactionType, value => {
  transaction.value?.pasteType(value)
})

watch(
  isOpen,
  value => {
    if (!value) {
      transactionType.value = TRANSACTION_TYPE[0]
      useExistingAccount.value = true
      account.value = undefined
      asset.value = undefined
      transaction.value = undefined
      prices.value = []
      currentStep.value = 0
      completed.value = []
      skipped.value = []
      visited.value = []
      contributions.value = []
      distributions.value = []
      tags.value[ASSET_CLASS] = ASSET_CLASS_LIST[0].value
      tags.value[INVESTMENT_STRATEGY] = INVESTMENT_STRATEGY_LIST[0].value
      assignTags.value = undefined
      return
    }
    account.value = new AssetClass({
      type: undefined,
      name: '',
      ticker: '',
      repository_id: repositoryId.value,
      currency: repositoryCurrency.value,
    })
    asset.value = new AssetClass({
      type: undefined,
      name: '',
      ticker: '',
      repository_id: repositoryId.value,
      currency: repositoryCurrency.value,
    })
    transaction.value = new TransactionClass({
      type: transactionType.value,
      date: getCurrentDate({}),
      entries: [
        { account_id: '', amount: 1, asset_id: '' },
        {
          account_id: '',
          amount: null,
          asset_id: `${currencyAssetID.value}`,
        },
      ],
    })
    setTimeout(() => {
      account.value?.validateData()
      asset.value?.validateData()
      transaction.value?.validateData()
    }, 0)
  },
  { immediate: true },
)

onMounted(() => {
  if (!transactionsSettingsStore.initFlag) {
    transactionsSettingsStore.fetch()
  }
})
</script>
