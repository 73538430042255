<template>
  <template v-if="isComponentVisible">
    <slot v-bind="{ errors, notifies }" />
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { TransactionError } from '@types'
import { TaskNotify } from '@/components/App/Notifications/utils/types'

import { TRANSACTION_FIELD } from '@/const'

import { filterNotify, prepareNotify } from './utils/helpers'
import { isDate, stringToLocalDateString } from '@/helpers/dates'

import { useTasksStore } from '@/store/tasks'
import { useTransactionsStore } from '@/store/transactions'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'

defineOptions({ name: 'MainNotifications' })

const tasksStore = useTasksStore()
const transactionsStore = useTransactionsStore()
const transactionsBunchStore = useTransactionsBunchStore()

const errors = computed(() => {
  if (!transactionsStore.initFlag) return []
  return (
    transactionsStore.getErrors?.reduce(
      (calc: TaskNotify[], item: TransactionError) => {
        const entries = item.errors?.entries
        if (entries) {
          const instance = transactionsBunchStore.getList.get(item.id)
          Object.values(entries).forEach(entry => {
            Object.values(entry).forEach(message => {
              calc.push({
                id: item.id,
                datetime: instance.field(TRANSACTION_FIELD.DATE),
                status: instance.field(TRANSACTION_FIELD.TYPE),
                message: isDate(message)
                  ? `${stringToLocalDateString(message)}`
                  : message,
              })
            })
          })
        }
        return calc
      },
      [],
    ) || []
  )
})

const notifies = computed(() => {
  return tasksStore.getFinished.filter(filterNotify).map(prepareNotify)
})

const isComponentVisible = computed(
  () => errors.value?.length || notifies.value?.length,
)
</script>
