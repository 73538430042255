<template>
  <UILoading
    v-if="isRepositoriesInit"
    full
    message="Allposit loading your repositories..."
  />
  <component :is="mainComponent" v-else />
  <AppInvites />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useResponsive } from '@/plugins/responsiveUI'

import { useRepositoriesStore } from '@/store/repositories'

import { AppInvites } from '@app'
import { UILoading } from '@ui'

import MainDesktop from './MainDesktop.vue'
import MainMobile from './MainMobile.vue'

defineOptions({ name: 'MainLayout' })

const repositoriesStore = useRepositoriesStore()

const { isMobile, isTablet } = useResponsive()

const mainComponent = computed(() =>
  isMobile.value || isTablet.value ? MainMobile : MainDesktop,
)

const isRepositoriesInit = computed(() => !repositoriesStore.initFlag)
</script>
