<template>
  <div class="main-desktop-tabs-scroller" :class="mainClasses">
    <UIButton
      v-if="isScrollable"
      :disabled="isLeftButtonDisabled"
      :icon="ArrowLeftIcon"
      variant="light-gray"
      size="xsmall"
      @click="handleClickLeft"
    />
    <div ref="scrollRef" class="main-desktop-tabs-scroller__container">
      <MainDesktopTabsItem
        v-for="item of items"
        :key="item.key"
        v-bind="{ item, disabled }"
      />
    </div>
    <UIButton
      v-if="isScrollable"
      :disabled="isRightButtonDisabled"
      :icon="ArrowRightIcon"
      variant="light-gray"
      size="xsmall"
      @click="handleClickRight"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs, useTemplateRef } from 'vue'
import { useElementBounding, useScroll } from '@vueuse/core'

import { NavigationItem } from '@types'

import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/20/solid'
import MainDesktopTabsItem from './MainDesktopTabsItem.vue'
import { UIButton } from '@ui'

const SCROLL_STEP = 205

type Props = {
  items: NavigationItem[]
  disabled?: boolean
}

const props = defineProps<Props>()

const scrollRef = useTemplateRef('scrollRef')

const { width } = useElementBounding(scrollRef)

const { arrivedState, x } = useScroll(scrollRef, {
  behavior: 'smooth',
})

const { left, right } = toRefs(arrivedState)

const isScrollable = computed(
  () =>
    !props.disabled &&
    scrollRef.value &&
    Math.round(width.value) < scrollRef.value.scrollWidth,
)

const isLeftButtonDisabled = computed(() => left.value)
const isRightButtonDisabled = computed(() => right.value)

const mainClasses = computed(() => ({
  'main-desktop-tabs-scroller--scrollable': isScrollable.value,
}))

const handleClickLeft = () => {
  x.value -= SCROLL_STEP
}

const handleClickRight = () => {
  x.value += SCROLL_STEP
}
</script>

<style>
.main-desktop-tabs-scroller {
  @apply flex items-center;
  @apply overflow-hidden;

  &--scrollable {
    @apply -ml-2 -mr-1;
  }

  &__container {
    @apply h-12;
    @apply flex;
    @apply px-1 gap-4;
    @apply overflow-auto;
    @apply scrollbar-none;
  }
}
</style>
