<template>
  <div class="app-user-info">
    <div class="app-user-info__plan">
      <div>Your current plan is:</div>
      <div class="app-user-info__plan-name">{{ currentPlan }}</div>
      <router-link
        :to="{ name: ROUTE_NAME.SUBSCRIPTION }"
        class="app-user-info__plan-link"
        @click="hide"
      >
        Go to subscription
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { ROUTE_NAME } from '@/const'

import { useUserSubscriptionsStore } from '@/store/user/subscriptions'

type Props = {
  hide?: () => void
}

defineProps<Props>()

const userSubscriptionsStore = useUserSubscriptionsStore()

const currentPlan = computed(() => userSubscriptionsStore.getCurrentPlanName)
</script>

<style lang="postcss">
.app-user-info {
  @apply md:w-52;
  @apply mb-1;

  &__plan {
    @apply flex flex-col items-end;
    @apply gap-1 px-4 pt-2 pb-3 mb-1;
    @apply border-b border-gray-100 dark:border-gray-600;
    @apply text-gray-500 dark:text-gray-400;
    @apply text-sm sm:text-xs;
  }

  &__plan-name {
    @apply bg-indigo-500;
    @apply py-0.5 px-2;
    @apply rounded-full;
    @apply text-white uppercase;
  }

  &__plan-link {
    @apply text-indigo-500 dark:text-indigo-400;
    @apply underline hover:no-underline;
  }
}
</style>
