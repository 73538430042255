<template>
  <div class="select-repository-delete">
    <div class="select-repository-delete__text">
      Are you sure you want to delete
      <span class="select-repository-delete__name">
        {{ displayName }}
      </span>
      repository?
    </div>
    <div class="select-repository-delete__actions">
      <UICheckbox
        v-model="enableDelete"
        label="Please check to confirm delete"
        data-refid="disableDelete"
      />
      <UICheckbox
        v-if="enableDelete"
        v-model="exportBeforeDelete"
        label="Export before deleting"
        data-refid="exportBeforeDelete"
      />
    </div>
    <div class="select-repository-delete__buttons">
      <UIButton label="Cancel" variant="light" @click="handleCancel" />
      <UIButton
        v-bind="{ disabled }"
        label="Delete"
        variant="red"
        @click="handleConfirm"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { Repository } from '@types'

import { handleCatchedError } from '@/helpers/common'

import { useRepositoriesStore } from '@/store/repositories'

import { UIButton, UICheckbox } from '@ui'

type Props = {
  repository: Repository
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const repositoriesStore = useRepositoriesStore()

const enableDelete = ref(false)
const exportBeforeDelete = ref(true)

const disabled = computed(() => !enableDelete.value)

const displayName = computed(() => props.repository.name)

const handleCancel = () => {
  emit('close')
}

const handleConfirm = async () => {
  try {
    await repositoriesStore.deleteRepository(props.repository.id)
    await repositoriesStore.fetchRepositories()
    emit('close')
  } catch (e) {
    handleCatchedError(e as string, { id: props.repository.id })
  }
}
</script>

<style scoped lang="postcss">
.select-repository-delete {
  @apply absolute inset-0;
  @apply flex flex-col items-center justify-center;
  @apply px-4 gap-4;
  @apply bg-white dark:bg-gray-800 bg-opacity-95;
  @apply text-sm text-gray-800 dark:text-gray-200;

  &__text {
    @apply text-center;
  }

  &__name {
    @apply text-indigo-600 dark:text-indigo-400;
  }

  &__actions {
    @apply flex flex-col;
    @apply gap-y-2;
  }

  &__buttons {
    @apply flex items-center;
    @apply gap-x-2;
  }
}
</style>
