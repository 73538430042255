<template>
  <div
    class="main-mobile-header-user-link group"
    :class="mainClasses"
    @click.prevent="handleClick"
  >
    <div class="main-mobile-header-user-link__text">
      {{ item.label }}
    </div>
    <component :is="item.icon" class="main-mobile-header-user-link__icon" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { DropdownItem } from '@types'

type Props = {
  item: DropdownItem<any>
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const route = useRoute()

const currentRouteName = computed(
  () =>
    (route.matched[0]?.name ||
      route.matched[0]?.path?.replace('/', '')) as string,
)

const mainClasses = computed(() => ({
  'main-mobile-header-user-link--active':
    props.item.key === currentRouteName.value,
}))

const handleClick = () => {
  props.item.action?.(() => emit('close'))
}
</script>

<style lang="postcss">
.main-mobile-header-user-link {
  @apply flex items-center justify-end;
  @apply gap-2 px-4 py-2;
  @apply hover:bg-gray-100 dark:hover:bg-gray-750;
  @apply text-gray-700 hover:text-gray-900;
  @apply dark:text-gray-300 dark:hover:text-gray-100;
  @apply text-sm;
  @apply cursor-pointer;

  &--active {
    @apply bg-gray-50 text-primary-active;
    @apply dark:bg-gray-700 dark:text-indigo-300;
  }

  &__icon {
    @apply w-5 h-auto;
  }

  &__text {
    @apply truncate;
  }
}
</style>
