<template>
  <div class="input-field group" :class="mainClasses">
    <InputFieldLabel v-if="label" :for="id" v-bind="labelAttrs" />
    <div class="input-field__area" :class="containerClasses">
      <div
        v-if="isLeadingVisible"
        class="input-field__icon input-field__icon--leading"
        :class="iconClasses"
      >
        <slot name="leading" />
      </div>
      <input
        ref="inputRef"
        v-bind="{
          ...inputAttrs,
          ...$attrs,
          autocomplete,
          disabled,
          id,
          placeholder,
          readonly,
        }"
        v-model="modelValue"
        :data-refid="dataRefid"
        class="input-field__input"
        :class="inputClasses"
        @keyup="handleKeyUp"
        @keydown.enter.prevent="handleClickEnter"
        @keydown.tab="handleClickTab"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <div
        v-if="isClearIconVisible"
        class="input-field__icon input-field__icon--clear"
        :class="iconClasses"
        @click="handleClickClear"
      >
        <XMarkIcon aria-hidden="true" />
      </div>
      <div
        v-if="isExclamationIconVisible"
        class="input-field__icon input-field__icon--error"
        :class="iconClasses"
      >
        <ExclamationCircleIcon aria-hidden="true" />
      </div>
      <div
        v-if="isTrailingVisible"
        class="input-field__icon input-field__icon--trailing"
        :class="iconClasses"
      >
        <slot name="trailing" />
      </div>
    </div>
    <div v-if="isFloatError" class="input-field__error" :class="errorClasses">
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  ref,
  useSlots,
  useTemplateRef,
} from 'vue'
import { pick } from 'lodash'

import { CommonEmits, CommonProps, InputData } from '../utils/types'

import InputFieldLabel from './InputFieldLabel.vue'
import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

type Props = CommonProps

type Emits = CommonEmits

const { size = 'default', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<InputData>()

defineExpose({
  blur() {
    inputRef.value?.blur()
  },
  focus() {
    inputRef.value?.focus()
  },
  select() {
    inputRef.value?.select()
  },
  clear() {
    handleClickClear()
  },
  begin() {
    if (!modelValue.value) return
    inputRef.value?.setSelectionRange(
      modelValue.value.toString().length,
      modelValue.value.toString().length,
    )
  },
})

const slots = useSlots()

const inputRef = useTemplateRef('inputRef')

const savedValue = ref()

const labelAttrs = computed(() =>
  pick(props, ['label', 'float', 'error', 'size', 'silentError']),
)

const inputAttrs = computed(() =>
  pick(props, ['placeholder', 'autocomplete', 'disabled', 'readonly']),
)

const id = computed(() => getCurrentInstance()?.uid.toString())

const isLeadingVisible = computed(
  () => slots.leading?.()?.[0]?.children?.length,
)
const isTrailingVisible = computed(
  () => slots.trailing?.()?.[0]?.children?.length,
)

const isError = computed(() => props.error && !props.silentError)
const isFloatError = computed(() => props.error && props.float)

const mainClasses = computed(() => ({
  'input-field--error': isError.value,
}))

const containerClasses = computed(() => ({
  [`input-field__area--${size}`]: size,
  'input-field__area--disabled': props.disabled,
  'input-field__area--error': isError.value,
}))

const inputClasses = computed(() => ({
  [`input-field__input--${size}`]: size,
  'input-field__input--readonly': props.readonly,
  'input-field__input--error': isError.value,
  [`input-field__input--icon`]:
    isExclamationIconVisible.value ||
    isTrailingVisible.value ||
    isClearIconVisible.value,
}))

const iconClasses = computed(() => ({
  [`input-field__icon--${size}`]: size,
}))

const errorClasses = computed(() => ({
  'input-field__error--silent': props.silentError,
}))

const isExclamationIconVisible = computed(() => isError.value)

const isClearIconVisible = computed(
  () => !props.readonly && props.clearable && modelValue.value,
)

const handleKeyUp = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    if (
      !props.unrecoverable &&
      savedValue.value &&
      savedValue.value !== modelValue.value
    ) {
      e?.stopImmediatePropagation()
      modelValue.value = savedValue.value
    } else {
      inputRef.value?.blur()
      emit('click:esc', e)
    }
  } else {
    emit('key:up', e)
  }
}

const handleClickEnter = (event: KeyboardEvent) => {
  emit('click:enter', event)
}

const handleClickTab = (event: KeyboardEvent) => {
  emit('click:tab', event)
}

const handleFocus = () => {
  emit('focus')
}

const handleBlur = () => {
  emit('blur')
}

const handleClickClear = () => {
  modelValue.value = ''
  inputRef.value?.focus()
}

onBeforeMount(() => {
  savedValue.value = modelValue.value
})
onMounted(() => {
  if (props.focusOnLoad) {
    setTimeout(() => {
      inputRef.value?.focus()
    }, 0)
  }
})
</script>

<script lang="ts">
export default {
  name: 'InputField',
}
</script>

<style lang="postcss">
.input-field {
  @apply relative;

  &__area {
    @apply relative;
    @apply flex;
    @apply rounded-md;
    @apply bg-white dark:bg-gray-800;
    @apply border;
    @apply border-gray-300 focus-within:border-indigo-500;
    @apply dark:border-gray-600 dark:focus-within:border-indigo-500;
    @apply outline outline-1 outline-transparent;
    @apply focus-within:outline-indigo-500;

    &--disabled {
      @apply opacity-50;
    }

    &--error {
      @apply border-red-300 dark:border-red-700;
      @apply focus-within:border-red-500 focus-within:outline-red-500;
    }

    &--xxsmall,
    &--xsmall {
      @apply px-1.5;
      @apply rounded;
    }
    &--small {
      @apply px-2;
    }
    &--default {
      @apply px-3;
    }
    &--large {
      @apply px-4;
    }
    &--xlarge {
      @apply px-6;
    }
  }

  &__error {
    @apply max-w-full group-hover:max-w-none;
    @apply absolute top-full left-0 mt-0.5;
    @apply px-1;
    @apply bg-red-50 dark:bg-red-900;
    @apply border rounded;
    @apply border-red-50 group-hover:border-red-100;
    @apply dark:border-red-900 dark:group-hover:border-red-800;
    @apply text-red-600 dark:text-red-400;
    @apply text-xs;
    @apply truncate;
    @apply z-10 group-hover:z-20;

    &--silent {
      @apply border-gray-100 dark:border-gray-700;
      @apply bg-gray-50 dark:bg-gray-750;
      @apply text-gray-400 dark:text-gray-500;
    }
  }

  &__input {
    @apply w-full;
    @apply first:!pl-0 last:!pr-0;
    @apply bg-transparent;
    @apply border-none !ring-0;
    @apply placeholder-gray-400;
    @apply text-sm;

    &--readonly {
      @apply !text-gray-400 dark:!text-gray-500;
    }

    &--error {
      @apply text-red-900 dark:text-red-300;
      @apply placeholder-gray-500 dark:placeholder-gray-400;
    }

    &--xxsmall,
    &--xsmall,
    &--small {
      @apply text-xs;
    }
    &--xxsmall,
    &--xsmall {
      @apply p-1.5;
    }
    &--small {
      @apply p-2;
      @apply leading-4;
    }
    &--default {
      @apply py-2 px-3;
    }
    &--large,
    &--xlarge {
      @apply text-base;
    }
    &--large {
      @apply px-4 py-2;
    }
    &--xlarge {
      @apply px-6 py-3;
    }
  }

  &__icon {
    @apply flex items-center;
    @apply px-1;
    @apply text-gray-400 dark:text-gray-500;
    @apply sm:text-sm;

    svg {
      @apply h-5 w-5;
    }

    &--clear {
      @apply last:-mr-1;
      @apply hover:text-gray-500 dark:hover:text-gray-400;
      @apply cursor-pointer;
    }

    &--error {
      @apply !text-red-500 dark:!text-red-400;
    }

    &--xxsmall,
    &--xsmall {
      svg {
        @apply h-3.5 w-3.5;
      }
    }
    &--small {
      svg {
        @apply h-4 w-4;
      }
    }

    &--error,
    &--leading,
    &--trailing {
      @apply first:-ml-1;
      @apply last:-mr-1;
    }
  }
}
</style>
