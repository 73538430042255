<template>
  <div class="create-actions">
    <slot v-bind="{ items, disabled }">
      <button
        v-for="item in items"
        :key="item.label"
        v-bind="{ disabled }"
        class="create-actions__item"
        @click="item.action"
      >
        <div class="create-actions__item-icon">
          <component :is="item.icon" />
        </div>
        <span class="create-actions__item-label">
          {{ item.label }}
        </span>
      </button>
      <div v-if="syncing" class="create-actions__loading">
        <UILoading message="Your accounts are now being refreshed" />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed, markRaw } from 'vue'

import { useModalsStore } from '@/store/modals'
import { useLinkedDataConnector } from '../LinkedData/hooks/useLinkedDataConnector'

import { UILoading } from '@ui'
import { BanknotesIcon, HomeModernIcon } from '@heroicons/vue/24/outline'
import PrivateFundIcon from '@/assets/images/icons/private-fund.svg'
import AddAccount from '@/views/LinkedData/Connectors/components/AddAccount.vue'
import PrivateFund from '@/modules/wizards/PrivateFund/PrivateFund.vue'
import RealEstate from '@/modules/wizards/RealEstate/RealEstate.vue'

import { useTasksService } from '@/services/tasks'

type Emits = {
  click: []
}

const emit = defineEmits<Emits>()

const { loading, syncing } = useLinkedDataConnector()

const modalsStore = useModalsStore()
useTasksService()

const disabled = computed(() => syncing.value || loading.value)

const items = computed(() => [
  {
    icon: BanknotesIcon,
    label: 'Connect Bank, Investment & Crypto Accounts',
    action: handleClickAddAccount,
  },
  {
    icon: HomeModernIcon,
    label: 'Add Real Estate',
    action: handleClickCreateRealEstate,
  },
  {
    icon: PrivateFundIcon,
    label: 'Add Private Fund',
    action: handleClickCreatePrivateFund,
  },
])

const handleClickAddAccount = () => {
  emit('click')
  const modalInstance = modalsStore.init('add-account', markRaw(AddAccount))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickCreateRealEstate = () => {
  emit('click')
  const modalInstance = modalsStore.init('real-estate', markRaw(RealEstate))
  modalInstance?.open(modalsStore.getZIndex())
}

const handleClickCreatePrivateFund = () => {
  emit('click')
  const modalInstance = modalsStore.init('private-fund', markRaw(PrivateFund))
  modalInstance?.open(modalsStore.getZIndex())
}
</script>

<style scoped lang="postcss">
.create-actions {
  @apply flex flex-col md:flex-row gap-4;
  @apply py-8;

  &__item {
    @apply w-full max-w-[10rem];
    @apply aspect-square md:aspect-auto;
    @apply p-3;
    @apply flex flex-col gap-2;
    @apply items-center;
    @apply border rounded-md;
    @apply border-gray-300 dark:border-gray-700;
    @apply hover:bg-gray-50 dark:hover:bg-gray-900;
  }

  &__item-icon {
    @apply flex items-center justify-center;
    @apply flex-auto md:flex-none;
    @apply shrink-0;
    @apply text-gray-400;

    svg {
      @apply w-12 h-12;
    }
  }

  &__item-label {
    @apply md:h-full;
    @apply flex items-center;
    @apply text-sm font-medium;
    @apply text-gray-700 dark:text-gray-300;
  }

  &__loading {
    @apply absolute inset-0;
    @apply flex items-center justify-center;
    @apply bg-white dark:bg-gray-800 bg-opacity-80;
  }
}
</style>
