<template>
  <div class="institutions-search__input">
    <UILabeledField
      v-model="institutionSearch"
      placeholder="Find institution"
      clearable
    />
  </div>
  <UILoading v-if="institutionsLoading" class="institutions-search__loading" />
  <UILayoutNoData
    v-else-if="debouncedInstitutionSearch && !instatutionsList.length"
    class="institutions-search__no-data"
    inline
    hide-question-icon
  />
  <div v-else class="institutions-search__list">
    <InstitutionsSearchItem
      v-for="(item, index) in instatutionsList"
      :key="item.institution_id"
      v-bind="{ item }"
      :recommended="isItemRecommended(index)"
      @click="handleClickInstitution(item)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { debouncedRef } from '@vueuse/core'

import { handleCatchedError } from '@/helpers/common'

import { InstatutionData } from '../../utils/types'
import { DEBOUNCE_DELAY } from '@/const'
import { DEFAULT_INSTITUTIONS } from '../../utils/const'

import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'

import { UILabeledField, UILayoutNoData, UILoading } from '@ui'
import InstitutionsSearchItem from './InstitutionsSearchItem.vue'

defineExpose({
  clearSearch() {
    institutionSearch.value = ''
  },
})

type Emits = {
  'click-institution': [data: InstatutionData]
}
const emit = defineEmits<Emits>()

const linkedDataConnectorsStore = useLinkedDataConnectorsStore()

const institutionSearch = ref('')
const debouncedInstitutionSearch = debouncedRef(
  institutionSearch,
  DEBOUNCE_DELAY,
)
const institutionsLoading = ref(false)
const instatutionsList = ref<InstatutionData[]>(DEFAULT_INSTITUTIONS)

const controller = ref<AbortController>()

const isItemRecommended = (index: number) =>
  !!debouncedInstitutionSearch.value && index === 0

const handleClickInstitution = async (item: InstatutionData) => {
  emit('click-institution', item)
}

watch(debouncedInstitutionSearch, async value => {
  controller.value?.abort()
  if (!value) {
    instatutionsList.value = DEFAULT_INSTITUTIONS
    return
  }
  institutionsLoading.value = true
  try {
    controller.value = new AbortController()
    instatutionsList.value = await linkedDataConnectorsStore.searchInstitutions(
      value,
      controller.value?.signal,
    )
  } catch (e) {
    handleCatchedError(e as string)
  } finally {
    institutionsLoading.value = false
  }
})
</script>

<style lang="postcss" scoped>
.institutions-search {
  &__input {
    @apply mb-4;
  }

  &__loading,
  &__no-data {
    @apply mt-2 mb-6;
  }

  &__list {
    @apply grid grid-cols-1 gap-2;
  }
}
</style>
