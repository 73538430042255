<template>
  <button class="institution" :class="institutionClasses">
    <div class="institution__logo">
      <img
        :src="logo"
        :alt="item.name"
        :title="item.name"
        @error="setPlaceholderLogo"
      />
    </div>
    <div class="institution__text">
      {{ item.name }}
    </div>
    <div v-if="item.url" class="institution__url">
      {{ item.url }}
    </div>
    <div class="institution__source">
      {{ item.source }}
    </div>
    <div v-if="item.open_banking" class="ob">
      <span>Open Banking</span>
      <div class="ob__icon"><ShieldCheckIcon /></div>
    </div>
    <ArrowRightIcon />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { InstatutionData } from '../../utils/types'
import { ArrowRightIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline'

import { INSTITUTION_LOGO_PLACEHOLDER } from '../../utils/const'

type Props = {
  item: InstatutionData
  recommended?: boolean
}
const props = defineProps<Props>()

const institutionClasses = computed(() => ({
  'institution--recommended': props.recommended,
}))

const logo = computed(() => props.item.logo || INSTITUTION_LOGO_PLACEHOLDER)

const setPlaceholderLogo = (event: Event) => {
  const target = event.target as HTMLImageElement
  target.src = INSTITUTION_LOGO_PLACEHOLDER
}
</script>

<style lang="postcss" scoped>
.institution {
  @apply pl-20 py-2 pr-9;
  @apply min-h-20;
  @apply text-left;
  @apply rounded-lg;
  @apply text-gray-800 dark:text-gray-200;
  @apply bg-white dark:bg-gray-800;
  @apply border border-gray-300 dark:border-gray-700;
  @apply hover:border-gray-700 dark:hover:border-gray-500;
  @apply cursor-pointer overflow-hidden;
  @apply transition-colors;
  @apply relative;

  &--recommended {
    @apply border-indigo-500 dark:border-indigo-400;
    @apply hover:border-indigo-600 dark:hover:border-indigo-500;

    @apply after:absolute after:bg-indigo-500 after:dark:bg-indigo-400;
    @apply after:top-0 after:right-0;
    @apply after:pl-1 after:pr-0.5;
    @apply after:content-['Recommended'];
    @apply after:text-white after:rounded-bl-md;
    @apply after:text-[0.5rem] after:uppercase after:font-semibold;
  }

  > svg {
    @apply w-5 h-5;
    @apply absolute;
    @apply right-2;
    @apply top-1/2 -translate-y-1/2;
    @apply text-gray-700 dark:text-gray-500;
  }

  &__logo {
    @apply w-16 h-16 p-1;
    @apply absolute;
    @apply left-2 top-2;
    @apply bg-white;

    &--empty {
      @apply text-gray-300 dark:text-gray-700;
    }

    img {
      @apply w-full h-full;
      @apply object-contain;
    }
  }

  &__text {
    @apply text-base;
  }

  &__url,
  &__source {
    @apply px-0;
    @apply max-w-full;
    @apply text-xs;
    @apply text-gray-500 dark:text-gray-400;
    @apply overflow-hidden text-ellipsis;
    @apply whitespace-nowrap;
  }

  &__source {
    @apply bottom-1;
    @apply capitalize;
    @apply text-[0.625rem];
  }
  &__url {
    @apply mb-1;
    @apply bottom-6;
  }

  .ob {
    @apply flex items-center gap-1;
    @apply text-[0.5rem];
    @apply text-gray-400 dark:text-gray-500;
    @apply absolute bottom-0 right-0;

    &__icon {
      @apply w-5 h-5 p-0.5;
      @apply text-white;
      @apply bg-green-700;
      @apply rounded-tl-md;
    }
  }
}
</style>
