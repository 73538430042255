<template>
  <div class="asset-prices-form">
    <div class="asset-prices-form__input">
      <UIDatePickerField
        v-model="formData.date"
        v-bind="{
          disabled,
          placeholder: 'Date',
        }"
      />
    </div>
    <div class="asset-prices-form__input">
      <UILabeledField
        v-model="formData.close"
        ref="closeFieldRef"
        data-refid="assetPricesFormClose"
        v-bind="{
          disabled,
          placeholder,
          type: 'number',
        }"
        focus-on-load
      />
    </div>
    <UIButton
      data-refid="assetPricesFormAdd"
      v-bind="{
        disabled: isButtonDisabled,
        icon: PlusIcon,
        label: '',
      }"
      @click="handleSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, useTemplateRef } from 'vue'

import { AssetPriceForm } from './utils/types'

import { getCurrentDate } from '@/helpers/dates'

import { UIButton, UILabeledField, UIDatePickerField } from '@ui'
import { PlusIcon } from '@heroicons/vue/24/outline'

type Props = {
  disabled?: boolean
  placeholder?: string
}

type Emits = {
  submit: [data: AssetPriceForm]
}

const { placeholder = 'Close', disabled } = defineProps<Props>()
const emit = defineEmits<Emits>()

const closeFieldRef = useTemplateRef('closeFieldRef')

const formData = reactive<AssetPriceForm>({
  date: getCurrentDate({}),
  close: null,
})

const isButtonDisabled = computed(
  () => disabled || !formData.date || formData.close === null,
)

const handleSubmit = () => {
  emit('submit', formData)
  setTimeout(() => {
    formData.close = null
    closeFieldRef.value?.focus()
  }, 100)
}
</script>

<style scoped>
.asset-prices-form {
  @apply flex;
  @apply gap-x-2 sm:gap-x-3;

  &__input {
    @apply flex-auto;
  }
}
</style>
