<template>
  <template v-if="item">
    <div
      v-if="item.active || disabled"
      ref="mainRef"
      class="main-desktop-tabs-item"
      :class="itemClasses"
    >
      <MainDesktopTabsItemLabel v-bind="{ item, disabled }" />
    </div>
    <router-link
      v-else
      ref="mainRef"
      :to="{ name: item.route, params: item.routeParams }"
      class="main-desktop-tabs-item"
      :data-refid="dataRefId"
      :class="itemClasses"
    >
      <MainDesktopTabsItemLabel v-bind="{ item }" />
    </router-link>
  </template>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import { upperFirst } from 'lodash'

import { NavigationItem } from '@types'

import MainDesktopTabsItemLabel from './MainDesktopTabsItemLabel.vue'

type Props = {
  item?: NavigationItem
  disabled?: boolean
}

const props = defineProps<Props>()

defineExpose({
  getEl() {
    // @ts-ignore
    return mainRef.value?.$el || mainRef.value
  },
})

const mainRef = useTemplateRef('mainRef')

const dataRefId = computed(
  () =>
    `mainMenuItem${props.item?.label
      ?.split(' ')
      .map(item => upperFirst(item))
      .join('')}`,
)

const itemClasses = computed(() => ({
  'main-desktop-tabs-item--active': props.item?.active,
  'main-desktop-tabs-item--disabled': props.disabled,
}))
</script>

<style>
.main-desktop-tabs-item {
  @apply flex items-center;
  @apply text-sm text-gray-600 dark:text-gray-300;
  @apply hover:text-primary dark:hover:text-indigo-300;
  @apply border-b-2 border-transparent;
  @apply whitespace-nowrap;

  &--disabled {
    @apply opacity-50;
  }

  &--active {
    @apply text-primary dark:text-indigo-300;
    @apply border-primary dark:border-indigo-300;
    @apply !opacity-90;
  }

  &--active:not(&--disabled) {
    @apply !opacity-100;
  }
}
</style>
