<template>
  <slot
    v-if="currentRepository"
    v-bind="{
      items,
      repositoryId,
      repositoryName,
      postfixIcon,
      isActionsAllowed,
      handleClickItem,
    }"
  />
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { Repository } from '@types'

import { SET_CURRENT_REPOSITORY } from '@/const'

import { useRepositoriesStore } from '@/store/repositories'
import { useUserSubscriptionsStore } from '@/store/user/subscriptions'

import { UserGroupIcon } from '@heroicons/vue/24/outline'

const repositoriesStore = useRepositoriesStore()
const userSubscriptionsStore = useUserSubscriptionsStore()

const setCurrentRepository = inject<(id?: string) => void>(
  SET_CURRENT_REPOSITORY,
)

const items = computed(() => repositoriesStore.list)

const currentRepository = computed(() => repositoriesStore.getCurrentRepository)
const repositoryId = computed(() => repositoriesStore.getCurrentRepository?.id)
const repositoryName = computed(() => currentRepository.value?.name)

const postfixIcon = computed(() =>
  currentRepository.value && currentRepository.value.collaborators_count > 1
    ? UserGroupIcon
    : undefined,
)

const isActionsAllowed = computed(
  () => items.value.length < userSubscriptionsStore.getMaxReposNumber,
)

const handleClickItem = (item: Repository) => {
  if (item.id === currentRepository.value?.id) return
  setCurrentRepository && setCurrentRepository(item.id)
}
</script>
