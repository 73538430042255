<template>
  <div class="add-dialog-item">
    <UIButton
      :icon="item?.icon"
      :disabled="!!displayPlan"
      variant="light"
      class="add-dialog-item__button"
      @click="handleClick"
    >
      <div class="add-dialog-item__text">
        {{ displayLabel }}
      </div>
    </UIButton>
    <div
      v-if="displayPlan"
      v-tooltip="'Upgrade your plan to access the widget'"
      class="add-dialog-item__plan"
      @click="handleClickUpgrade"
    >
      {{ displayPlan }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AddDialogItem } from './utils/types'

import { UIButton } from '@ui'

type Props = {
  item: AddDialogItem
}

type Emits = {
  click: [data: AddDialogItem]
  upgrade: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const displayLabel = computed(() => props.item?.label)
const displayPlan = computed(() => {
  return props.item.unavailable ? 'Upgrade to PRO' : undefined
})

const handleClick = () => {
  emit('click', props.item)
}

const handleClickUpgrade = () => {
  emit('upgrade')
}
</script>

<style>
.add-dialog-item {
  @apply relative;

  &__button {
    @apply p-0;
    @apply flex-col;
    @apply gap-1;
    @apply aspect-square;
  }

  .btn {
    @apply w-full;
    @apply !p-0;

    &__icon {
      @apply w-10 h-10;
      @apply text-gray-400;
    }
  }

  &__text {
    @apply min-h-[2.2rem];
    @apply flex items-center justify-center;
    @apply px-1;
    @apply sm:text-xs sm:leading-none;
  }

  &__plan {
    @apply absolute -top-1 -right-1;
    @apply bg-indigo-500;
    @apply py-0.5 px-2;
    @apply rounded-full;
    @apply text-xs;
    @apply text-white;
    @apply cursor-pointer;
  }
}
</style>
