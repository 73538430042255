<template>
  <div :class="mainClasses">
    <UILoadingIcon />
    <span v-if="message">{{ message }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import UILoadingIcon from './LoadingIcon.vue'

type Props = {
  message?: string
  full?: boolean
  inset?: boolean
  leftAligned?: boolean
}

const { message = 'Loading...', ...props } = defineProps<Props>()

const mainClasses = computed(() => ({
  loader: true,
  'loader--full': props.full,
  'loader--inset': props.inset,
  'loader--left-aligned': props.leftAligned,
}))
</script>

<script lang="ts">
export default {
  name: 'UILoading',
}
</script>

<style lang="postcss">
.loader {
  @apply flex justify-center items-center;
  @apply space-x-3;
  @apply text-gray-400 dark:text-gray-600;

  &--left-aligned {
    @apply justify-start;
  }

  &--full {
    @apply fixed inset-0;
    @apply bg-white dark:bg-gray-900;
    @apply bg-opacity-75;
    @apply z-30;
  }

  &--inset {
    @apply absolute inset-0;
    @apply bg-white dark:bg-gray-900;
    @apply bg-opacity-75;
    @apply z-30;
  }
}
</style>
