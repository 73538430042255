import { ref } from 'vue'

import { AlertType } from '@/components/UI/Alert/utils/types'
import { Alert, AlertInstance } from './utils/types'

import { MIN_DELAY } from './utils/const'

import { getUuid } from './utils/helpers'

const alerts = ref<AlertInstance[]>([])

const push = async (alert: Alert): Promise<AlertInstance> => {
  return new Promise(resolve => {
    const alertItem = {
      id: getUuid(),
      created_at: new Date().getTime(),
      ...alert,
    }
    alerts.value.push(alertItem)
    resolve(alertItem)
  })
}

const alertCreator = async (type: AlertType, alert: Alert) => {
  return await push({
    ...alert,
    type,
  })
}

const methods = {
  update: async (id: string, alert: Alert, timeout?: number): Promise<void> => {
    return new Promise(resolve => {
      const alertIndex = alerts.value.findIndex(item => item.id === id)
      if (~alertIndex) {
        alerts.value[alertIndex] = {
          ...alerts.value[alertIndex],
          ...alert,
        }
      }
      if (timeout) {
        setTimeout(async () => await methods.remove(id), timeout)
      }
      resolve()
    })
  },
  remove: async (id: string): Promise<void> => {
    const instance = alerts.value.find(alert => alert.id === id)
    if (instance) {
      const diff = new Date().getTime() - instance.created_at
      if (diff < MIN_DELAY) {
        await new Promise(resolve => setTimeout(resolve, MIN_DELAY - diff))
      }
    }
    return new Promise(resolve => {
      const removeIndex = alerts.value.findIndex(item => item.id === id)
      ~removeIndex && alerts.value.splice(removeIndex, 1)
      resolve()
    })
  },
  success: async (alert: Alert): Promise<AlertInstance> =>
    alertCreator('success', alert),
  error: async (alert: Alert): Promise<AlertInstance> =>
    alertCreator('error', alert),
  warn: async (alert: Alert): Promise<AlertInstance> =>
    alertCreator('warn', alert),
  progress: async (alert: Alert): Promise<AlertInstance> =>
    alertCreator('progress', alert),
  clearAll: async () => {
    alerts.value = []
  },
}

export const useAlerts = () => {
  return {
    alerts,
    ...methods,
  }
}
