<template>
  <div class="private-fund-type">
    <UIButton
      v-for="value in TRANSACTION_TYPE"
      v-bind="{ value }"
      :label="value"
      :variant="type === value ? 'secondary' : 'light-secondary'"
      @click="handleClick(value)"
    />
  </div>
</template>

<script setup lang="ts">
import { UIButton } from '@ui'

import { TRANSACTION_TYPE } from './utils/const'

const type = defineModel<(typeof TRANSACTION_TYPE)[number]>()

const handleClick = (value: (typeof TRANSACTION_TYPE)[number]) => {
  type.value = value
}
</script>

<style scoped>
.private-fund-type {
  @apply flex gap-2;
  @apply mb-4 -mt-4 md:-mt-6 -mx-4 md:-mx-6 py-2 px-4 md:px-6;
  @apply bg-body-gray;

  .btn {
    @apply flex-auto;
  }
}
</style>
