<template>
  <div
    class="main-desktop-panel__button"
    :class="mainClasses"
    @click="handleToggle"
  >
    <div v-tooltip.right="tooltip" class="main-desktop-panel__button-icon">
      <TreeIcon aria-hidden="true" />
    </div>
    <div class="main-desktop-panel__button-caption">{{ t('Asset tree') }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { OpenedFlag } from './utils/type'

import { PANEL_OPENED, TREE_OPENED } from './utils/const'

import { useLocale } from '@/plugins/i18n'

import TreeIcon from '@/assets/images/icons/tree.svg'

const { t } = useLocale('layouts.Main')

const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)
const isPanelOpened = inject<OpenedFlag>(PANEL_OPENED)

const tooltip = computed(() =>
  isPanelOpened?.value ? undefined : t('Asset tree'),
)

const mainClasses = computed(() => ({
  'main-desktop-panel-tree--active': isTreeOpened?.value,
}))

const handleToggle = () => {
  isPanelOpened && (isPanelOpened.value = false)
  isTreeOpened && (isTreeOpened.value = !isTreeOpened.value)
}
</script>

<style>
.main-desktop-panel-tree {
  &--active {
    @apply -mr-[calc(0.5rem+1px)];
    @apply !rounded-r-none;
    @apply bg-body-dark;
    @apply !border-common-border !border-r-0;
    @apply !text-primary dark:!text-indigo-500;
  }
}
</style>
