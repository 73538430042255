<template>
  <AddAccountArchlab ref="archlabRef" />
  <AddAccountOFX ref="ofxRef" />
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'

import { LinkedDataManualLoader } from '@types'

import AddAccountArchlab from './AddAccountArchlab.vue'
import AddAccountOFX from './AddAccountOFX.vue'

defineExpose({
  processing(data: string) {
    switch (data as LinkedDataManualLoader) {
      case LinkedDataManualLoader.ARCHLAB:
        archlabRef.value?.show(true)
        break
      case LinkedDataManualLoader.OFX:
        ofxRef.value?.show(true)
        break
    }
  },
})

const archlabRef = useTemplateRef('archlabRef')
const ofxRef = useTemplateRef('ofxRef')

defineOptions({
  name: 'ConnectorsImportAccount',
})
</script>
