<template>
  <div class="main-desktop-panel-tree-section">
    <MainTree />
    <TogglePanelButton
      v-tooltip="t('Close Asset tree')"
      class="main-desktop-panel-tree-section__toggler"
      :value="true"
      @toggle="handleClose"
    />
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { OpenedFlag } from './utils/type'

import { TREE_OPENED } from './utils/const'

import { useLocale } from '@/plugins/i18n'

import TogglePanelButton from './components/TogglePanelButton.vue'
import MainTree from './components/Tree.vue'

const { t } = useLocale('layouts.Main')

const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)

const handleClose = () => {
  isTreeOpened && (isTreeOpened.value = false)
}
</script>

<style>
.main-desktop-panel-tree-section {
  @apply w-[22rem];
  @apply relative;
  @apply flex flex-col shrink-0;
  @apply bg-body-dark;
  @apply border-r border-common-border;

  &__toggler {
    @apply absolute right-2 bottom-2;
    @apply hover:bg-body;
  }
}
</style>
