<template>
  <div
    class="ui-dropdown__item group"
    :class="mainClasses"
    :data-refid="`dropdownItem${index + 1}`"
    @click.prevent="handleClick"
  >
    <slot>
      <component :is="item.icon" class="ui-dropdown__item-icon" />
      <div class="ui-dropdown__item-text">
        {{ value }}
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { DropdownItem } from '../utils/types'

type T = any

type Props = {
  item: DropdownItem<T>
  value: string

  index: number

  idKey?: keyof T
  valueKey?: keyof T

  active: boolean
  static?: boolean
  reverse?: boolean
}

type Emits = {
  click: [event: MouseEvent]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const mainClasses = computed(() => ({
  'ui-dropdown__item--active': props.active,
  'ui-dropdown__item--static': props.static,
  'ui-dropdown__item--reverse': props.reverse,
}))

const handleClick = (event: MouseEvent) => {
  emit('click', event)
}

defineOptions({
  name: 'DropdownItem',
})
</script>

<style>
.ui-dropdown {
  &__item {
    @apply flex;
    @apply gap-2 px-4 py-2;
    @apply hover:bg-gray-100 dark:hover:bg-gray-750;
    @apply text-gray-700 hover:text-gray-900;
    @apply dark:text-gray-300 dark:hover:text-gray-100;
    @apply text-sm;
    @apply cursor-pointer;

    &--static {
      @apply py-1.5;
      @apply hover:bg-transparent;
      @apply !text-gray-500 dark:!text-gray-400;
      @apply text-xs;
      @apply cursor-default;
    }

    &--active {
      @apply bg-gray-50 text-indigo-600;
      @apply dark:bg-gray-700 dark:text-indigo-300;
    }

    &--danger {
      @apply !text-red-500 dark:!text-red-400;
    }

    &--reverse {
      @apply flex-row-reverse;
    }

    &-icon {
      @apply w-4 h-auto;
    }

    &-text {
      @apply truncate;
    }

    &--static &-text {
      @apply max-w-sm;
      @apply whitespace-normal;
    }
  }
}
</style>
