<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog v-bind="{ zIndex }" title="Connect" size="md" @hide="handleClose">
      <template #title>
        <button
          v-if="isMoreOptions"
          class="back-btn"
          @click="isMoreOptions = false"
        >
          <ArrowLeftIcon />
        </button>
        <span>{{ addAccountTitle }}</span>
      </template>
      <div v-if="isMoreOptions" class="add-account__buttons">
        <UIButton
          v-for="button in buttonsList"
          :key="button.label"
          :label="button.label"
          full
          variant="secondary"
          @click="button.action()"
        />
      </div>
      <template v-if="!isMoreOptions">
        <template v-if="hasExistingConnectors">
          <div class="add-account__caption">Use existing connector:</div>
          <div class="add-account__list">
            <ExistingConnector
              v-for="stranger in strangers"
              :key="stranger.id"
              :connector="stranger"
              @click="handleClickExisting(stranger)"
            />
          </div>
          <div class="add-account__caption">or new:</div>
        </template>
        <InstitutionsSearch
          ref="InstitutionsSearchRef"
          @click-institution="handleClickInstitution"
        />
      </template>
      <template v-if="!isMoreOptions" #buttons>
        <UIButton
          label="More options"
          full
          variant="secondary"
          @click="isMoreOptions = true"
        />
      </template>
    </UIDialog>
  </TransitionRoot>
  <AddAccountArchlab ref="archlabRef" @cancel="handleCancel" />
  <AddAccountAkoya ref="akoyaRef" @cancel="handleCancel" />
  <AddAccountOFX ref="ofxRef" @cancel="handleCancel" />
  <AddAccountYodlee ref="yodleeRef" />
</template>

<script setup lang="ts">
import { computed, inject, ref, useTemplateRef, watch } from 'vue'

import { LinkedDataConnector, ModalClass, ModalState } from '@types'
import { LinkedDataConnectors } from '../../utils/enums'
import { InstatutionData } from '../../utils/types'

import { IS_PROD_MODE } from '@/const/common'

import { handleClickAccount } from './utils/helpers'
import { handleCatchedError } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'
import { useLinkedDataConnector } from '../../hooks/useLinkedDataConnector'

import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'
import { useLinkedDataStore } from '@/store/linkedData'
import { useModalsStore } from '@/store/modals'

import { TransitionRoot } from '@headlessui/vue'
import { ArrowLeftIcon } from '@heroicons/vue/24/solid'
import { UIButton, UIDialog } from '@ui'
import ExistingConnector from './ExistingConnector.vue'
import InstitutionsSearch from './InstitutionsSearch.vue'
import AddAccountAkoya from './AddAccountAkoya.vue'
import AddAccountArchlab from './AddAccountArchlab.vue'
import AddAccountOFX from './AddAccountOFX.vue'
import AddAccountYodlee from './AddAccountYodlee.vue'

type Props = {
  modal: ModalClass<any>
}

const props = defineProps<Props>()

const linkedDataStore = useLinkedDataStore()
const linkedDataConnectorsStore = useLinkedDataConnectorsStore()
const modalsStore = useModalsStore()

const { error } = useNotifications()

const InstitutionsSearchRef = useTemplateRef('InstitutionsSearchRef')

const { plaid: handlePlaid, yodlee: handleYodlee } = useLinkedDataConnector(
  (flag: boolean) => {
    yodleeRef.value?.toggle(flag)
  },
  (flag: boolean) => {
    flag ? props.modal.show(modalsStore.getZIndex()) : props.modal.close()
  },
)

const isProdMode = inject(IS_PROD_MODE)

const loading = ref(false)

const archlabRef = useTemplateRef('archlabRef')
const akoyaRef = useTemplateRef('akoyaRef')
const ofxRef = useTemplateRef('ofxRef')
const yodleeRef = useTemplateRef('yodleeRef')

const isMoreOptions = ref(false)

const strangers = ref<LinkedDataConnector[]>()

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const addAccountTitle = computed(() =>
  isMoreOptions.value ? 'More options' : 'Connect',
)

const hasExistingConnectors = computed(() => strangers.value?.length)

const buttonsList = computed(() => {
  const list = [
    {
      label: `Use ${LinkedDataConnectors.PLAID} connector`,
      action: handlePlaid,
    },
    {
      label: `Use ${LinkedDataConnectors.YODLEE} connector`,
      action: handleYodlee,
    },
    {
      label: `Use ${LinkedDataConnectors.OFX} connector`,
      action: () => {
        props.modal.close()
        ofxRef.value?.show()
      },
    },
  ]
  if (!isProdMode) {
    list.push({
      label: `Use ${LinkedDataConnectors.ARCHLAB} connector`,
      action: () => {
        props.modal.close()
        archlabRef.value?.show()
      },
    })
  }
  return list
})

const handleClose = () => {
  props.modal.close()
}

const handleCancel = () => {
  props.modal.show(modalsStore.getZIndex())
}

const handleClickExisting = async ({ id, name }: LinkedDataConnector) => {
  props.modal.close()
  loading.value = true
  try {
    await linkedDataConnectorsStore.map(id)
    await Promise.all([
      await linkedDataStore.fetch(),
      await linkedDataConnectorsStore.fetch(),
    ])
  } catch (e) {
    handleCatchedError(e as string)
    error({
      message: `Error while mapping Existing connector "${name}"`,
    })
    return
  } finally {
    loading.value = false
  }
}

const handleClickInstitution = async (item: InstatutionData) => {
  switch (item.source) {
    case 'plaid':
      handlePlaid(item.institution_id, item.routing_number)
      break
    case 'yodlee':
      handleYodlee(item.institution_id)
      break
    default:
      loading.value = true
      await handleClickAccount(item.source, item, (name, url, logo) => {
        akoyaRef.value?.show(name, url, logo)
      })
      loading.value = false
  }
}

watch(
  isOpen,
  async value => {
    if (!value) {
      InstitutionsSearchRef.value?.clearSearch()
      return
    }
    try {
      const data = await linkedDataConnectorsStore.fetchStrangers()
      strangers.value = data
    } catch (e) {
      handleCatchedError(e as string)
      return
    }
  },
  { immediate: true },
)

defineOptions({
  name: 'ConnectorsAddAccount',
})
</script>

<style lang="postcss" scoped>
.back-btn {
  @apply w-6 h-6;
  @apply cursor-pointer;
  @apply text-gray-400 hover:text-gray-500;
  @apply dark:text-gray-300 dark:hover:text-gray-400;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
  @apply focus:ring-indigo-500 dark:focus:ring-indigo-400;
}
.add-account {
  &__caption {
    @apply mb-2 py-1;
    @apply rounded;
    @apply font-medium text-xs;
    @apply text-center;
    @apply bg-indigo-50 dark:bg-indigo-900;
    @apply text-indigo-600 dark:text-indigo-400;
  }

  &__list {
    @apply mb-4;
    @apply grid gap-2 sm:grid-cols-2;
  }

  &__buttons {
    @apply space-y-4;
  }
}
</style>
