<template>
  <div class="toggle-panel-button" @click="handleToggle">
    <component :is="toggleComponent" class="toggle-panel-button__icon" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
} from '@heroicons/vue/24/solid'

type Props = {
  value?: boolean
}

type Emits = {
  toggle: [data: boolean]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const toggleComponent = computed(() =>
  props.value ? ChevronDoubleLeftIcon : ChevronDoubleRightIcon,
)

const handleToggle = () => {
  emit('toggle', !props.value)
}
</script>

<style>
.toggle-panel-button {
  @apply w-8 h-8;
  @apply flex items-center justify-center;
  @apply cursor-pointer;
  @apply rounded-md;
  @apply hover:bg-body-dark;
  @apply text-light-text;

  &__icon {
    @apply w-4 h-4;
  }
}
</style>
