<template>
  <UIDropdown
    v-bind="{ triggers }"
    :disabled="isDropdownDisabled"
    ref="dropdownRef"
  >
    <slot v-bind="{ disabled }" />
    <template #popper="{ hide: callback }">
      <div class="main-view-tab-dropdown">
        <div
          v-for="el in actions"
          :key="el.label"
          class="ui-dropdown__item"
          :class="el.classes"
          @click="el.action({ callback, key: item.key })"
        >
          <component
            :is="el.icon"
            class="ui-dropdown__item-icon main-view-tab-dropdown__icon"
          />
          <span class="ui-dropdown__item-text main-view-tab-dropdown__text">
            {{ el.label }}
          </span>
        </div>
      </div>
    </template>
  </UIDropdown>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

import { NavigationItem, NavigationItemAction } from '@types'

import { useUserSettingsStore } from '@/store/user/settings'

import { useLocale } from '@/plugins/i18n'
import { useResponsive } from '@/plugins/responsiveUI'

import PinIcon from '@/assets/images/icons/pin.svg'
import PinnedIcon from '@/assets/images/icons/pinned.svg'

import { UIDropdown } from '@ui'
import { PencilIcon } from '@heroicons/vue/24/outline'

type Props = {
  item: NavigationItem
  disabled?: boolean
  triggers?: string[]
}

type Emits = {
  edit: []
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  show: () => dropdownRef.value?.show(),
  hide: () => dropdownRef.value?.hide(),
})

const { t } = useLocale('layouts.Main')
const userSettingsStore = useUserSettingsStore()

const { isMobile, isTablet } = useResponsive()

const dropdownRef = useTemplateRef('dropdownRef')

const isMobileView = computed(() => isMobile.value || isTablet.value)

const isDropdownDisabled = computed(
  () => props.disabled || !actions.value.length,
)

const isPinned = computed(
  () =>
    props.item?.key && userSettingsStore.getPinnedMenu.includes(props.item.key),
)

const pinIcon = computed(() => (isPinned.value ? PinnedIcon : PinIcon))

const pinLabel = computed(() =>
  isPinned.value ? t('Unpin view') : t('Pin view'),
)

const actions = computed(() => {
  const actions: NavigationItemAction[] = []
  props.item.editable &&
    actions.push({
      label: 'Rename',
      icon: PencilIcon,
      action: ({ callback }) => {
        emit('edit')
        callback?.()
      },
    })
  props.item.actions && actions.push(...props.item.actions)
  !isMobileView.value &&
    actions.push({
      label: pinLabel.value,
      icon: pinIcon.value,
      action: ({ callback }) => togglePin(callback),
    })
  return actions
})

const togglePin = (callback?: () => void) => {
  props.item.key && userSettingsStore.togglePinMenu(props.item.key)
  callback?.()
}
</script>

<style>
.main-view-tab-dropdown {
  @apply min-w-32;
  @apply py-1;

  &__text {
    @apply text-xs;
  }

  &__icon {
    @apply w-3 aspect-square;
  }
}
</style>
