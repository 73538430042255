<template>
  <div class="ui-list" :style="mainStyles">
    <ListHeader
      v-for="column in columns"
      :key="column.name"
      :name="column.name"
      :header-class="column.headerClass"
    />
    <div class="ui-list__header" />
    <ListRow
      v-for="(item, index) in items"
      :key="index"
      v-bind="{ columns, item }"
      @remove="handleRemove(index)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { ListColumn, ListItem } from './utils/types'

import ListHeader from './ListHeader.vue'
import ListRow from './ListRow.vue'

type Props = {
  columns: ListColumn[]
  items: ListItem[]
}

type Emits = {
  remove: [index: number]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const mainStyles = computed(() => ({
  'grid-template-columns': `${props.columns.map(column => column.content).join(' ')} min-content`,
}))

const handleRemove = (index: number) => emit('remove', index)
</script>

<style>
.ui-list {
  @apply grid;
}
</style>
