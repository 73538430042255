<template>
  <div class="wizzard__form">
    <TagItem
      v-model="assetClass"
      :name="ASSET_CLASS"
      :list="ASSET_CLASS_LIST"
    />
    <TagItem
      v-model="investmentStrategy"
      :name="INVESTMENT_STRATEGY"
      :list="INVESTMENT_STRATEGY_LIST"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
  ASSET_CLASS,
  ASSET_CLASS_LIST,
  INVESTMENT_STRATEGY,
  INVESTMENT_STRATEGY_LIST,
} from './utils/const'

import TagItem from '../components/TagItem.vue'

type Props = {
  tags: Record<string, string>
}
const { tags } = defineProps<Props>()

defineOptions({ inheritAttrs: false })

type Emits = {
  'update:tags': [data: Record<string, string>]
}
const emit = defineEmits<Emits>()

const assetClass = computed({
  get() {
    return tags[ASSET_CLASS]
  },
  set(value) {
    emit('update:tags', { ...tags, [ASSET_CLASS]: value })
  },
})

const investmentStrategy = computed({
  get() {
    return tags[INVESTMENT_STRATEGY]
  },
  set(value) {
    emit('update:tags', { ...tags, [INVESTMENT_STRATEGY]: value })
  },
})
</script>
