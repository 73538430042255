<template>
  <div class="select-repository-actions">
    <UIButton
      data-refid="repositoryImport"
      label="Import"
      :icon="ArrowDownTrayIcon"
      variant="light"
      @click="handleImport"
    />
    <UIButton
      data-refid="repositoryCreate"
      label="Create new"
      :icon="PlusIcon"
      @click="handleCreate"
    />
  </div>
</template>

<script lang="ts" setup>
import { markRaw } from 'vue'

import { useModalsStore } from '@/store/modals'

import RepositoryImportDialog from '@/views/Repositories/RepositoryImportDialog.vue'
import RepositorySlideover from '@/views/Repositories/RepositorySlideover.vue'
import { ArrowDownTrayIcon, PlusIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui'

type Emits = {
  click: []
}

const emit = defineEmits<Emits>()

const modalsStore = useModalsStore()

const handleImport = () => {
  emit('click')
  const modalInstance = modalsStore.init(
    'repository-import',
    markRaw(RepositoryImportDialog),
  )
  modalInstance?.open(modalsStore.getZIndex())
}

const handleCreate = () => {
  emit('click')
  const modalInstance = modalsStore.init(
    'repository-create',
    markRaw(RepositorySlideover),
  )
  modalInstance?.open(modalsStore.getZIndex(), {
    focusOnLoad: true,
  })
}
</script>

<style scoped lang="postcss">
.select-repository-actions {
  @apply flex items-center justify-between;
  @apply py-3 px-4 gap-3;
  @apply border-t border-gray-100 dark:border-gray-600;

  :deep() .btn {
    @apply whitespace-nowrap;
  }
}
</style>
