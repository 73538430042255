<template>
  <div v-bind="$attrs" @click="handleClick">
    <PlusIcon class="main-mobile-footer-icon" aria-hidden="true" />
    <div class="main-mobile-footer-caption">{{ t('Add asset') }}</div>
  </div>
</template>

<script setup lang="ts">
import { inject, markRaw } from 'vue'

import { ReadonlyMode } from '@types'

import { READONLY_MODE } from '@/const'

import { useLocale } from '@/plugins/i18n'

import { useModalsStore } from '@/store/modals'

import AddAssetDialog from './components/AddAssetDialog.vue'
import PlusIcon from '@/assets/images/icons/plus.svg'

const { t } = useLocale('layouts.Main')

const modalsStore = useModalsStore()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const handleClick = () => {
  if (isReadonly?.value) return
  const modalInstance = modalsStore.init(
    'main-add-asset',
    markRaw(AddAssetDialog),
  )
  modalInstance?.open(modalsStore.getZIndex())
}
</script>
