<template>
  <div class="no-data">
    <div class="no-data__container" :class="containerClasses">
      <span class="no-data__icon" :class="iconClasses">
        <component :is="icon" aria-hidden="true" />
      </span>
      <div class="no-data__content" :class="contentClasses">
        <h3>No data</h3>
        <p>
          <slot name="text-description" />
          <span
            v-if="!showBottomQuestion && !hideQuestionIcon"
            class="help-icon"
          >
            &nbsp;
            <QuestionMarkCircleIcon @click="emit('click:help')" />
          </span>
        </p>
      </div>
      <div v-if="$slots.default" class="no-data__main" :class="mainClasses">
        <slot name="default" />
      </div>
    </div>
    <div v-if="showBottomQuestion && !hideQuestionIcon" class="no-data__bottom">
      <QuestionMarkCircleIcon
        class="no-data__bottom-icon"
        @click="emit('click:help')"
      />
      &nbsp;
      <span class="no-data__bottom-question">
        <slot name="text-question" />
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Component } from 'vue'
import { computed, useSlots } from 'vue'

import {
  QuestionMarkCircleIcon,
  CircleStackIcon,
} from '@heroicons/vue/24/outline'

type Props = {
  icon?: Component
  hideQuestionIcon?: boolean
  inline?: boolean
}

type Emits = {
  'click:help': []
}

const { icon = CircleStackIcon, inline } = defineProps<Props>()

const emit = defineEmits<Emits>()

const slots = useSlots()

const showBottomQuestion = computed(() => !!slots['text-question'])

const containerClasses = computed(() => ({
  'no-data__container--inline': inline,
}))
const iconClasses = computed(() => ({
  'no-data__icon--inline': inline,
}))
const contentClasses = computed(() => ({
  'no-data__content--inline': inline,
}))
const mainClasses = computed(() => ({
  'no-data__main--inline': inline,
}))
</script>

<script lang="ts">
export default {
  name: 'LayoutNoData',
}
</script>

<style scoped lang="postcss">
.no-data {
  @apply flex flex-col justify-center items-center;
  @apply text-gray-400 dark:text-gray-500;

  &__container {
    @apply pt-6 pb-8 px-8 sm:px-16;
    @apply border rounded-2xl;
    @apply border-gray-200 dark:border-gray-700;
    @apply text-center;

    &--inline {
      @apply flex items-center p-6;
      @apply text-left;
    }
  }
  &__icon {
    @apply inline-flex items-center justify-center;
    @apply h-16 w-16;
    @apply rounded-full;
    @apply bg-indigo-50 dark:bg-indigo-800;

    &--inline {
      @apply mr-4;
    }

    svg {
      @apply h-7 w-7;
      @apply text-indigo-700 dark:text-indigo-300;
    }
  }

  &__content {
    @apply flex flex-col;

    h3 {
      @apply mt-4 font-medium;
      @apply text-gray-800 dark:text-gray-100;
    }

    p {
      @apply flex items-center mt-2;
      @apply text-gray-600 dark:text-gray-300;
    }

    &--inline {
      @apply p-2;

      h3,
      p {
        @apply mt-0;
      }
    }
  }

  &__main {
    @apply mt-6;

    &--inline {
      @apply sm:mt-0 sm:ml-16;
    }
  }

  &__bottom {
    @apply flex items-center justify-center mt-4;
  }
  &__bottom-icon {
    @apply w-5 h-5;
    @apply text-indigo-700 dark:text-indigo-300;
    @apply cursor-pointer;
  }
  &__bottom-question {
    @apply text-gray-600 dark:text-gray-300;
    @apply text-sm;
  }
}

.help-icon {
  @apply flex items-center;

  svg {
    @apply w-5 h-5;
    @apply text-indigo-700 dark:text-indigo-300;
    @apply cursor-pointer;
  }
}
</style>
