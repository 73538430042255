<template>
  <component
    :is="controllerComponent"
    ref="fieldRef"
    v-model="modelValue"
    v-bind="{
      ...$attrs,
      silentError,
      readonly,
    }"
    frozen
    id-key="key"
    placeholder="Find or create new"
    @create="handleCreate"
  />
  <UIButton
    v-if="isSummaryButtonVisible"
    label=""
    type="button"
    variant="light"
    :icon="EllipsisVerticalIcon"
    @click="handleClickMenu"
  />
</template>

<script setup lang="ts">
import { computed, inject, ref, useTemplateRef } from 'vue'

import { TransactionSettings } from '@types'

import { useAssetsBunchStore } from '@/store/assets/bunch'

import { UIButton, UIInputDropdown, UIInputEditableDropdown } from '@ui'
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid'

type Props = {
  showSummaryButton?: boolean
  settings?: TransactionSettings['entries'][number]['account']
  readonly?: boolean
  disallowCreate?: boolean
}

type Emits = {
  create: [data: string]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<string>()

defineExpose({
  focus() {
    fieldRef.value?.focus()
  },
})

const fieldRef = useTemplateRef('fieldRef')

const assetsBunchStore = useAssetsBunchStore()

const isSavedTransaction = inject('isSavedTransaction', ref(false))

const controllerComponent = computed(() =>
  props.disallowCreate ? UIInputDropdown : UIInputEditableDropdown,
)

const readonly = computed(() => !!props.settings?.value || props.readonly)
const silentError = computed(() => !isSavedTransaction.value)

const isSummaryButtonVisible = computed(
  () => props.showSummaryButton && !!modelValue.value,
)

const handleClickMenu = () => {
  if (!modelValue.value) return
  const asset = assetsBunchStore.getList.get(modelValue.value)
  asset?.openSummary(undefined, true)
}

const handleCreate = (data: string) => {
  emit('create', data)
}
</script>

<script lang="ts">
export default {
  name: 'TransactionAssetField',
}
</script>
