import { CapacitorConfig } from '@capacitor/cli'

const config: CapacitorConfig = {
  appId: 'com.allposit.app',
  appName: 'Allposit',
  webDir: './dist',
  loggingBehavior: 'debug',
  backgroundColor: '4438ca',
  server: {
    hostname: 'app.stg.allposit.com',
  },
  android: {
    flavor: 'stg',
  },
  ios: {
    contentInset: 'always',
  },
  plugins: {
    CapacitorHttp: {
      enabled: true,
    },
    CapacitorCookies: {
      enabled: true,
    },
  },
}

export default config
