<template>
  <UIProgressBar v-bind="{ progress }" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import useAnalyticsStore from '@/store/analytics'

import { UIProgressBar } from '@ui'

const analyticsStore = useAnalyticsStore()
const progress = computed(() => analyticsStore.progress)
</script>
