import { LinkedDataConnectors } from '@types'

import { useNotifications } from '@/plugins/notification'

import { useLinkedDataConnectorsStore } from '@/store/linkedData/connectors'

export const handleClickAccount = async (
  source: string,
  data: { name: string; institution_id?: string; logo?: string | null },
  akoyaCallback: (name: string, url: string, logo?: string | null) => void,
) => {
  let connector: LinkedDataConnectors | undefined = undefined
  const params: Record<string, string> = {}

  switch (source) {
    case 'akoya':
      connector = LinkedDataConnectors.AKOYA
      params.connector = data.institution_id || ''
      break
    case 'carta':
      connector = LinkedDataConnectors.CARTA
      break
    case 'coinbase':
      connector = LinkedDataConnectors.COINBASE
      break
    default:
      break
  }

  const { error } = useNotifications()

  if (!connector) {
    error({
      message: 'Connector not available',
    })
    return
  }

  const linkedDataConnectorsStore = useLinkedDataConnectorsStore()
  const url = await linkedDataConnectorsStore.getUrl(connector, params)
  if (!url) {
    error({
      message: `${source
        ?.charAt(0)
        .toUpperCase()}${source?.slice(1)} URL not available`,
    })
    return
  }

  if (connector === LinkedDataConnectors.AKOYA) {
    akoyaCallback(data.name, url, data.logo)
    return
  }

  location.href = url
}
