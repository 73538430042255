<template>
  <div class="main-desktop-panel__button" @click="handleClick">
    <div v-tooltip.right="tooltip" class="main-desktop-panel__button-icon">
      <PlusIcon aria-hidden="true" />
    </div>
    <div class="main-desktop-panel__button-caption">{{ t('Add asset') }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, markRaw } from 'vue'

import { OpenedFlag, ReadonlyMode } from '@types'

import { PANEL_OPENED, READONLY_MODE } from '@/const'

import { useLocale } from '@/plugins/i18n'

import { useModalsStore } from '@/store/modals'

import AddAssetDialog from './components/AddAssetDialog.vue'
import PlusIcon from '@/assets/images/icons/plus.svg'

const { t } = useLocale('layouts.Main')

const modalsStore = useModalsStore()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)
const isPanelOpened = inject<OpenedFlag>(PANEL_OPENED)

const tooltip = computed(() =>
  isPanelOpened?.value ? undefined : t('Add asset'),
)

const handleClick = () => {
  if (isReadonly?.value) return
  const modalInstance = modalsStore.init(
    'main-add-asset',
    markRaw(AddAssetDialog),
  )
  modalInstance?.open(modalsStore.getZIndex())
}
</script>
