<template>
  <label class="ui-radiobox" :class="mainClasses">
    <input
      v-model="modelValue"
      v-bind="{ disabled, value }"
      type="radio"
      class="ui-radiobox__control"
      @change="handleChange"
    />
    <span v-if="label || $slots.default" class="ui-radiobox__label">
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  label?: string

  disabled?: boolean

  value?: any
}

type Emits = {
  change: [data: boolean]
}

const { disabled } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<any>({ required: true })

defineOptions({
  name: 'UIRadiobox',
})

const mainClasses = computed(() => ({
  'ui-radiobox--disabled': disabled,
}))

const handleChange = (event: Event) => {
  const flag = (event?.target as HTMLInputElement).checked
  emit('change', flag)
}
</script>

<style>
.ui-radiobox {
  @apply relative;
  @apply flex items-start;
  @apply gap-3;

  &--disabled {
    @apply opacity-50;
  }

  &__control {
    @apply h-4 w-4;
    @apply shrink-0;
    @apply rounded-full;
    @apply bg-white dark:bg-gray-800;
    @apply checked:bg-indigo-600 dark:checked:bg-indigo-400;
    @apply focus:ring-indigo-500 dark:ring-offset-gray-700 dark:focus:ring-indigo-500;
    @apply border-gray-300 dark:border-gray-600;
    @apply text-indigo-600 dark:text-indigo-400;
    @apply checked:border-transparent;
    @apply cursor-pointer disabled:cursor-not-allowed;
  }

  &__label {
    @apply text-sm font-medium;
    @apply leading-tight;
  }
}
</style>
