<template>
  <template v-if="breadcrumbs">
    <UIButton
      v-if="isButtonsVisible"
      :to="toPrev"
      :disabled="!toPrev"
      :icon="ArrowLeftIcon"
      size="xsmall"
      variant="light-secondary"
      class="main-page-name__button"
    />
    <div class="main-page-name__container">
      <div
        v-for="(item, index) in breadcrumbs"
        :key="item"
        :class="`main-page-name__item main-page-name__item--level${index + 1}`"
      >
        {{ item }}
      </div>
    </div>
    <UIButton
      v-if="isButtonsVisible"
      :to="toNext"
      :disabled="!toNext"
      :icon="ArrowRightIcon"
      size="xsmall"
      variant="light-secondary"
      class="main-page-name__button"
    />
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { DASHBOARD_NAME, ROUTE_NAME } from '@/const'

import { useDashboardsSettingsStore } from '@/store/settings/dashboards'
import { useNavigationStore } from '@/store/navigation'
import { UIButton } from '@ui'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'

const route = useRoute()

const dashboardSettingsStore = useDashboardsSettingsStore()
const navigationStore = useNavigationStore()

const breadcrumbs = computed(() => navigationStore.getBreadcrumbs)

const list = computed(() => dashboardSettingsStore.getDashboards)

const isButtonsVisible = computed(() => {
  return (
    breadcrumbs.value[0] === DASHBOARD_NAME &&
    list.value &&
    list.value.length > 1
  )
})

const currentSlug = computed(() => route.params?.slug)

const currentDashboardIndex = computed(() => {
  return list.value?.findIndex(item => item.slug === currentSlug.value)
})

const prevSlug = computed(
  () =>
    currentDashboardIndex.value !== undefined &&
    currentDashboardIndex.value > 0 &&
    list.value?.[currentDashboardIndex.value - 1]?.slug,
)
const nextSlug = computed(
  () =>
    list.value &&
    currentDashboardIndex.value !== undefined &&
    currentDashboardIndex.value < list.value?.length - 1 &&
    list.value?.[currentDashboardIndex.value + 1]?.slug,
)

const toPrev = computed(() =>
  prevSlug.value
    ? {
        name: ROUTE_NAME.DASHBOARDS_DASHBOARD,
        params: { slug: prevSlug.value },
      }
    : undefined,
)

const toNext = computed(() =>
  nextSlug.value
    ? {
        name: ROUTE_NAME.DASHBOARDS_DASHBOARD,
        params: { slug: nextSlug.value },
      }
    : undefined,
)
</script>

<style scoped>
.main-page-name {
  &__container {
    @apply flex-auto;
    @apply overflow-hidden;
  }

  &__item {
    @apply text-xs;
    @apply tracking-normal;
    @apply text-center;
    @apply truncate;

    &--level1 {
      @apply font-medium;
      @apply uppercase;
    }
  }

  &__button {
    @apply text-indigo-50;
    @apply !bg-transparent hover:!bg-indigo-800;
  }
}
</style>
