<template>
  <AddAccountAkoya ref="akoyaRef" />
  <AddAccountYodlee ref="yodleeRef" />
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'

import { LinkedDataConnector } from '@types'

import { handleClickAccount } from './utils/helpers'
import { useLinkedDataConnector } from '../../hooks/useLinkedDataConnector'

import AddAccountAkoya from './AddAccountAkoya.vue'
import AddAccountYodlee from './AddAccountYodlee.vue'

type Emits = {
  close: []
  open: []
}

const emit = defineEmits<Emits>()

defineExpose({
  processing(connector: LinkedDataConnector) {
    switch (connector.access_method) {
      case 'plaid':
        handlePlaid(connector.id)
        break
      case 'yodlee':
        handleYodlee()
        break
      default: {
        handleClickAccount(
          connector.access_method,
          {
            name: connector.name,
            institution_id: connector.source,
            logo: connector.logo,
          },
          (name, url, logo) => {
            akoyaRef.value?.show(name, url, logo)
          },
        )
      }
    }
  },
})

const { plaid: handlePlaid, yodlee: handleYodlee } = useLinkedDataConnector(
  (flag: boolean) => {
    yodleeRef.value?.toggle(flag)
  },
  (flag: boolean) => {
    flag ? emit('open') : emit('close')
  },
)

const akoyaRef = useTemplateRef('akoyaRef')
const yodleeRef = useTemplateRef('yodleeRef')

defineOptions({
  name: 'ConnectorsReconnectAccount',
})
</script>
