<template>
  <div class="main-desktop-tabs-item-label" @contextmenu="handleContextMenu">
    <InputString v-if="isEditMode" v-model="labelValue" @save="handleSave" />
    <template v-else>
      <div v-if="isPinned" class="main-desktop-tabs-item-label__pin">
        <PinnedIcon aria-hidden="true" />
      </div>
      <ViewTabDropdown
        ref="dropdownRef"
        v-bind="{ item, disabled, triggers }"
        @edit="isEditMode = true"
      >
        <span class="main-desktop-tabs-item-label__text" :class="textClasses">
          {{ item.label }}
        </span>
      </ViewTabDropdown>
      <span v-if="isDirty" class="main-desktop-tabs-item-label__dirty"> *</span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef } from 'vue'

import { NavigationItem } from '@types'

import { useUserSettingsStore } from '@/store/user/settings'

import PinnedIcon from '@/assets/images/icons/pinned.svg'

import InputString from './components/InputString.vue'
import ViewTabDropdown from './components/ViewTabDropdown.vue'

type Props = {
  item: NavigationItem
  disabled?: boolean
}

const props = defineProps<Props>()

const userSettingsStore = useUserSettingsStore()

const dropdownRef = useTemplateRef('dropdownRef')

const isEditMode = ref(false)
const labelValue = ref(props.item.label || '')

const isDirty = computed(() => props.item?.isDirty)

const isPinned = computed(
  () =>
    props.item?.key && userSettingsStore.getPinnedMenu.includes(props.item.key),
)

const textClasses = computed(() => ({
  'main-desktop-tabs-item-label__text--active': props.item.active,
  'main-desktop-tabs-item-label__text--disabled': props.disabled,
}))

const triggers = computed(() => {
  return props.item.active ? ['click'] : []
})

const handleSave = () => {
  props.item.update?.(labelValue.value)
  isEditMode.value = false
}

const handleContextMenu = (event: MouseEvent) => {
  event.preventDefault()
  if (props.disabled) return
  dropdownRef.value?.show()
}
</script>

<style>
.main-desktop-tabs-item-label {
  @apply relative;

  &__text {
    @apply rounded-sm;

    &--active:not(&--disabled) {
      @apply hover:outline hover:outline-1 hover:outline-offset-2;
      @apply hover:outline-indigo-100 hover:dark:outline-gray-600;
      @apply cursor-pointer;
    }
  }

  &__pin {
    @apply absolute bottom-full -right-1;
    @apply translate-y-1;
    @apply p-[2px];
    @apply rounded-full;
    @apply bg-indigo-50 dark:bg-body-gray;
    @apply text-primary-active dark:text-indigo-400;

    svg {
      @apply w-2 aspect-square;
    }
  }

  &__dirty {
    @apply absolute bottom-0.5 left-full;
    @apply text-yellow-500;
  }
}
</style>
