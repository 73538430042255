<template>
  <AssetPricesForm
    v-bind="{ disabled }"
    class="asset-tab__form"
    @submit="handleSubmit"
  />
</template>

<script setup lang="ts">
import { AssetPriceForm } from './utils/types'

import AssetPricesForm from './AssetPricesForm.vue'

type Props = {
  disabled?: boolean
}

type Emits = {
  submit: [data: AssetPriceForm]
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const handleSubmit = (data: AssetPriceForm) => {
  emit('submit', data)
}
</script>

<script lang="ts">
export default {
  name: 'AssetTabPricesForm',
}
</script>

<style scoped>
@import url('./styles/asset.tabs.css');
</style>
