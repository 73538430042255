<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <SlidePanel
      v-if="type === ModalType.SLIDEPANEL"
      v-bind="{
        direction,
        hideMinimiseButton,
        isMinimized,
        size,
        subTitle,
        title,
        headerClass,
        wrapperClass,
        zIndex,
        withBack,
      }"
      @minimize="handleMinimize"
      @hide="handleHide"
    >
      <template #default><slot /></template>
      <template v-if="$slots.title" #title><slot name="title" /></template>
    </SlidePanel>
    <Dialog
      v-else-if="type === ModalType.DIALOG"
      v-bind="{
        contentClass: wrapperClass,
        hideCloseButton,
        icon,
        position,
        size,
        title,
        uncloseable,
        zIndex,
      }"
      @hide="handleHide"
    >
      <template #default><slot /></template>
      <template v-if="$slots.title" #title><slot name="title" /></template>
      <template v-if="$slots.buttons" #buttons>
        <slot name="buttons" />
      </template>
    </Dialog>
    <DialogAction
      v-else-if="type === ModalType.ACTION_DIALOG"
      v-bind="{
        actions,
        icon,
        position,
        size,
        title,
        wrapperClass,
        zIndex,
      }"
      @hide="handleHide"
    >
      <slot />
    </DialogAction>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { provide, ref, VNode, watch } from 'vue'

import {
  DialogActionButton,
  DialogIcon,
  ModalSize,
  SlidePanelDirection,
} from './utils/types'
import { ModalType } from './utils/enums'

import { useCheckDirtyForm } from '@/components/hooks/useCheckDirtyForm'

import { useModalsStore } from '@/store/modals'

import { TransitionRoot } from '@headlessui/vue'
import Dialog from './Dialog.vue'
import DialogAction from './DialogAction.vue'
import SlidePanel from './SlidePanel.vue'

export type Props = {
  isOpen: boolean
  isMinimized?: boolean
  type?: ModalType
  title?: string | VNode
  size?: ModalSize
  // slidepanel props:
  subTitle?: string
  headerClass?: string
  hideMinimiseButton?: boolean
  direction?: SlidePanelDirection
  withBack?: boolean
  //dialog props:
  icon?: DialogIcon
  position?: 'top' | 'center'
  uncloseable?: boolean
  hideCloseButton?: boolean
  //dialog actions props:
  actions?: DialogActionButton[]
  id?: number
  wrapperClass?: string
  checkDirty?: boolean
  checkDirtyCallback?: () => boolean
}

type Emits = {
  hide: []
  minimize: [data: boolean]
}

const {
  type = ModalType.DIALOG,
  size = 'sm',
  position = 'center',
  ...props
} = defineProps<Props>()

const emit = defineEmits<Emits>()

const modalsStore = useModalsStore()

const zIndex = ref(0)
const isOpened = ref(props.isOpen)

const checkDirtyForm = useCheckDirtyForm()
provide('modal-mark-dirty', checkDirtyForm.onDirtyChange())

const handleMinimize = (data: boolean) => {
  emit('minimize', data)
}

const handleHide = () => {
  emit('hide')
}

watch(
  () => props.isOpen,
  value => {
    isOpened.value = value
    if (value) {
      zIndex.value = modalsStore.getZIndex()
    }
  },
  { immediate: true },
)
</script>
<script lang="ts">
export default {
  name: 'UIModal',
  inheritAttrs: false,
}
</script>
