<template>
  <div class="wizzard__summary">
    <WizzardSummaryItem title="Date of purchase" :value="dateOfPurchase" />
    <WizzardSummaryItem title="Property name" :value="propertyName" />
    <WizzardSummaryItem title="Description" :value="description" />
    <WizzardSummaryItem title="Cost" :value="propertyCost" />
    <WizzardSummaryItem title="Description" :value="transactionNotes" />
    <WizzardSummaryItem
      v-for="(tag, index) in displayTags"
      :key="index"
      :title="tag[0]"
      :value="tag[1]"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject } from 'vue'

import { AssetClass, TransactionClass } from '@types'

import {
  ASSET_FIELD,
  TRANSACTION_ENTRY_FIELD,
  TRANSACTION_FIELD,
} from '@/const'

import { numberFormat } from '@/helpers/numbers'

import WizzardSummaryItem from '../components/WizzardSummaryItem.vue'

type Props = {
  transaction: TransactionClass
  asset: AssetClass
  tags: Record<string, string>
  assignTags?: boolean
}

const props = defineProps<Props>()

const currency = inject<ComputedRef<string>>('currency')

const dateOfPurchase = computed(
  () => props.transaction.field<string>(TRANSACTION_FIELD.DATE).value,
)

const propertyName = computed(
  () => props.asset.field<string>(ASSET_FIELD.NAME)?.value,
)

const description = computed(
  () => props.asset.field<string>(ASSET_FIELD.DESCRIPTION)?.value,
)

const propertyCost = computed(() => {
  const amount = props.transaction.entries?.[1][TRANSACTION_ENTRY_FIELD.AMOUNT]
  return amount
    ? numberFormat(Math.abs(amount), { currency: currency?.value })
    : undefined
})

const transactionNotes = computed(
  () => props.transaction.field<string>(TRANSACTION_FIELD.DESCRIPTION)?.value,
)

const displayTags = computed(
  () => (props.assignTags && Object.entries(props.tags)) || [],
)
</script>
