import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash'

import {
  UserPlan,
  UserPlanName,
  UserSwitchPlanResponse,
  UserSubscription,
} from '@types'

import { PLAN_FEATURES } from '@/const'

import api from '@/store/api'

import { prepareResponseError } from '../utils/helpers'

import { useRepositoriesStore } from '../repositories'

export const useUserSubscriptionsStore = defineStore(
  'user-subscriptions',
  () => {
    // INIT
    const repositoriesStore = useRepositoriesStore()

    const loading = ref(false)

    const currentSubscription = ref<UserSubscription>()

    const plansList = ref<UserPlan[]>()

    let abortController = new AbortController()

    const repositoryPlan = computed(() => repositoriesStore.getCurrentPlan)

    // GETTERS
    const getPlansList = computed(() =>
      plansList.value?.map(plan => ({
        ...plan,
        features: PLAN_FEATURES[plan.name],
      })),
    )

    const getCurrentSubscription = computed(() => {
      const subscription = cloneDeep(currentSubscription.value)
      if (!subscription) return
      subscription.plan.features = PLAN_FEATURES[subscription.plan.name]
      return subscription
    })

    const getCurrentPlanName = computed(
      () => getCurrentSubscription.value?.plan.name || UserPlanName.STANDARD,
    )

    const getCurrentPlan = computed(
      () => repositoryPlan.value || getCurrentSubscription.value?.plan,
    )

    const getCurrentPlanIndex = computed(() => {
      if (!getCurrentSubscription.value?.plan) return
      return (
        plansList.value?.findIndex(
          plan => plan.name === getCurrentSubscription.value?.plan.name,
        ) || 0
      )
    })

    const getPreviousPlan = computed(() => {
      if (getCurrentPlanIndex.value === undefined) return
      return getPlansList.value?.[getCurrentPlanIndex.value - 1]
    })

    const getNextPlan = computed(() => {
      if (getCurrentPlanIndex.value === undefined) return
      return getPlansList.value?.[getCurrentPlanIndex.value + 1]
    })

    const getAvailableWidgets = computed(
      () => getCurrentPlan.value?.dashboard_widgets || [],
    )

    const getDisabledViews = computed<string[]>(() => [])

    const getMaxReposNumber = computed(
      () => getCurrentSubscription.value?.plan.max_repos_number || 1,
    )

    // ACTIONS
    const fetchCurrentSubscription = async () => {
      loading.value = true
      try {
        const result = await api.get('subscription', {
          signal: abortController.signal,
        })
        currentSubscription.value = result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      } finally {
        loading.value = false
      }
    }

    const fetchHostedPageSession = async (planId: number) => {
      try {
        const result = await api.get(
          `subscription/hosted_page_session?plan_id=${planId}`,
        )
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const changePlan = async (
      plan_id: number,
      deactivated_repositories?: string[],
    ) => {
      try {
        const result = await api.post<UserSwitchPlanResponse>(
          'subscription/switch_plan',
          {
            plan_id,
            deactivated_repositories,
          },
        )
        return result.data
      } catch (e) {
        throw Error(prepareResponseError(e))
      }
    }

    const cancel = () => {
      abortController.abort()
      abortController = new AbortController()
    }

    const clear = () => {
      currentSubscription.value = undefined
    }

    ;(async () => {
      const planLsistUrl = new URL(
        '@/../public/plans-list.json',
        import.meta.url,
      ).href
      const data = await fetch(planLsistUrl).then(res => res.json())
      plansList.value = data
    })()

    return {
      loading,

      fetchCurrentSubscription,
      fetchHostedPageSession,
      changePlan,

      getPlansList,
      getCurrentSubscription,
      getCurrentPlanName,
      getPreviousPlan,
      getNextPlan,
      getAvailableWidgets,
      getDisabledViews,
      getMaxReposNumber,

      cancel,
      clear,
    }
  },
)
