import { computed, h, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { defineStore } from 'pinia'
import { sortBy } from 'lodash'

import { ErrorNodes, TagClass } from '@types'
import { EntityState } from '@/entities/utils/enums'

import { TAG_FIELD } from '@/entities/tags/utils/const'
import { NOTIFICATION_DELAY } from '@/const/common'
import { useRepositoriesStore } from '../repositories'
import { ROUTE_NAME } from '@/const'

import { generateId } from '@/entities/utils/helpers'
import { createLink, isDirtyList, unsavedGridBlocker } from '../utils/helpers'

import { useNotifications } from '@/plugins/notification'

import { useMainStore } from '@/store/main'
import { useTagsStore } from '.'

export const useTagsBunchStore = defineStore('tags-bunch', () => {
  // INIT

  const mainStore = useMainStore()
  const tagsStore = useTagsStore()
  const repositoryStore = useRepositoriesStore()

  const router = useRouter()
  const { error } = useNotifications()

  const tagsList = computed(() => tagsStore.getList)

  // STORE

  const list = ref<Map<string, TagClass>>(new Map())
  const errors = ref<ErrorNodes[]>()

  // ACTIONS

  const createElement = () => {
    const id = generateId()
    const repository_id = repositoryStore.getCurrentRepository?.id
    const element = new TagClass({ id, repository_id }, true)
    const entries = Array.from(list.value.entries())
    list.value = new Map([[id, element], ...entries])
    return element
  }

  const unshiftElement = (element: TagClass) => {
    const entries = Array.from(list.value.entries())
    list.value = new Map([[element.id, element], ...entries])
    return element
  }

  const duplicateElement = (id: string) => {
    const entries = Array.from(list.value.entries())
    const index = entries.findIndex(item => item[0] === id)
    if (index > -1) {
      const id = generateId()
      const entry = entries[index][1]
      const data = entry.get()
      const element = new TagClass({ ...data, id }, true)
      const prefixBunch = entries.slice(0, index + 1)
      const postfixBunch = entries.slice(index + 1)
      list.value = new Map([...prefixBunch, [id, element], ...postfixBunch])
    }
  }

  const showErrors = (result: ErrorNodes[] | undefined) => {
    if (!result) return
    error(
      { message: h('div', { class: 'space-y-2 text-xs' }, result) },
      NOTIFICATION_DELAY,
    )
  }

  const checkForErrors = () => {
    const result: ErrorNodes[] = []
    const listValues = Array.from(getList.value.values())
    const excludedIds: string[] = listValues
      .filter(instance => instance.state === EntityState.DELETED)
      .map(instance => instance.id)
    getList.value.forEach(item => {
      excludedIds.push(item.id)
      if (item.state === EntityState.DELETED) return
      const itemName = item.get().tag_name
      const itemValue = item.get().tag_value
      const errorEntries = Object.entries(item.errors)
      if (errorEntries.length) {
        const errors = errorEntries.map(
          item => `${item[0]?.toUpperCase()}: ${String(item[1]).toLowerCase()}`,
        )
        const message = h('div', [
          `Tag `,
          createLink(item.id, itemName, router, ROUTE_NAME.TAGS_ITEM),
          ` has validation error${errors.length > 1 ? 's' : ''}: `,
          errors.join(', '),
        ])
        result.push(message)
      }
      const duplicate = listValues.filter(element => {
        if (excludedIds.includes(element.id)) return false
        return (
          element.get().tag_name === itemName &&
          element.get().tag_value === itemValue
        )
      })
      if (duplicate.length) {
        const links = duplicate.map((item, index) => {
          excludedIds.push(item.id)
          return createLink(
            item.id,
            `[${index + 2}] `,
            router,
            ROUTE_NAME.TAGS_ITEM,
          )
        })
        const message = h('div', [
          `Tags `,
          createLink(item.id, '[1] ', router, ROUTE_NAME.TAGS_ITEM),
          ...links,
          `have the same name "${itemName}" or value "${itemValue}"`,
        ])
        result.push(message)
      }
    })
    if (result.length) {
      errors.value = result
      showErrors(result)
      return true
    } else {
      errors.value = undefined
      return false
    }
  }

  // GETTERS

  const getList = computed<Map<string, TagClass>>(() =>
    list.value.size ? list.value : new Map(),
  )
  const getEntries = computed(() => Array.from(getList.value.entries()))
  const getCommonList = computed(() => {
    return new Map(getEntries.value.filter(([, instance]) => instance.isCommon))
  })
  const getCategoriesList = computed(() => {
    return new Map(
      getEntries.value.filter(([, instance]) => instance.isCategory),
    )
  })
  const getSubcategoriesList = computed(() => {
    return new Map(
      getEntries.value.filter(([, instance]) => instance.isSubcategory),
    )
  })

  const getMetricTags = computed(() => {
    const tagNames = Array.from(getCommonList.value).map(
      ([, instance]) => instance.field<string>(TAG_FIELD.TAG_NAME).value,
    )
    return sortBy([...new Set(tagNames)])
  })

  const getErrors = computed(() => errors.value)

  const getElementById = (id: string) => list.value.get(id) as TagClass

  const getElementByName = (name?: string, skippedId?: string) => {
    return Array.from(getList.value.values()).find(
      (instance: TagClass) =>
        instance.field<string>(TAG_FIELD.TAG_NAME).value?.toLowerCase() ===
          name?.toLowerCase() && instance.id !== skippedId,
    )
  }

  const getElementByPair = (
    name?: string,
    value?: string,
    skippedId?: string,
  ) => {
    return Array.from(getList.value.values()).find(
      (instance: TagClass) =>
        instance.field<string>(TAG_FIELD.TAG_NAME).value?.toLowerCase() ===
          name?.toLowerCase() &&
        instance.field<string>(TAG_FIELD.TAG_VALUE).value?.toLowerCase() ===
          value?.toLowerCase() &&
        instance.id !== skippedId,
    )
  }

  const isDirty = computed(() => isDirtyList(list.value))

  // FILL STORE

  watch(tagsList, value => {
    value.forEach(item => {
      if (!list.value.get(item.id)) {
        const instance = new TagClass(item)
        list.value.set(item.id, instance)
      }
    })
  })

  // ADD BLOCKER IF DIRTY

  watch(isDirty, value => {
    if (value) {
      mainStore.addBlocker(
        'tagsHasChanges',
        unsavedGridBlocker('tags', () =>
          router.push({ name: ROUTE_NAME.TAGS }),
        ),
      )
    } else {
      mainStore.removeBlocker('tagsHasChanges')
    }
  })

  // CLEAR STORE

  const clear = () => {
    list.value = new Map()
  }

  return {
    createElement,
    unshiftElement,
    duplicateElement,
    checkForErrors,

    getList,
    getCommonList,
    getCategoriesList,
    getSubcategoriesList,
    getMetricTags,
    getErrors,
    getElementById,
    getElementByName,
    getElementByPair,
    isDirty,

    clear,
  }
})
