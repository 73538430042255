<template>
  <Notifications>
    <template #default="{ notifies, errors }">
      <div
        class="main-mobile-header-notifications main-mobile-header-button"
        @click="handleOpen"
      >
        <NotificationsIcon class="main-mobile-header-icon" />
      </div>
      <Teleport to="body">
        <UIModal
          v-bind="{ isOpen }"
          :type="ModalType.SLIDEPANEL"
          direction="to-left"
          title="Notifications"
          header-class="main-mobile-header-notifications__header"
          wrapper-class="main-mobile-header-notifications__list"
          hide-minimise-button
          with-back
          @hide="handleClose"
        >
          <AppNotifications
            v-bind="{ notifies, errors }"
            @click:item="handleClose"
          />
        </UIModal>
      </Teleport>
    </template>
  </Notifications>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { ModalType } from '@types'

import Notifications from './components/Notifications.vue'
import NotificationsIcon from './components/NotificationsIcon.vue'
import { AppNotifications } from '@app'
import { UIModal } from '@ui'

const isOpen = ref(false)

const handleOpen = () => {
  isOpen.value = true
}

const handleClose = () => {
  isOpen.value = false
}
</script>

<style scoped>
.main-mobile-header-notifications {
  @apply -ml-12;

  :deep() &__header {
    @apply py-2.5 pl-4 pr-4;
  }

  :deep() &__list {
    @apply !px-4;
  }
}
</style>
