<template>
  <div ref="target" class="app-global-search">
    <div v-circular-tab>
      <UILabeledField
        :model-value="search"
        placeholder="Start by typing something"
        autocomplete="off"
        :trailing="{ icon: MagnifyingGlassIcon }"
        focus-on-load
        disable-focus-delay
        disable-recovery-value
        clearable
        class="mb-2"
        data-refid="globalSearchField"
        @update:model-value="search = ($event ?? '').toString()"
      />
      <div v-if="isButtonsShown" class="app-global-search__tags">
        <button
          v-for="tag in preparedTags"
          :key="tag"
          v-bind="{ disabled }"
          class="app-global-search__tag-item"
          :class="getTagItemClasses(tag)"
          @click="currentTag = tag"
        >
          {{ tag }}
        </button>
      </div>
    </div>
    <div v-if="isSearchContainerShown" class="app-global-search__container">
      <div
        v-for="(item, index) in preparedFoundItems"
        :key="item.id"
        :class="globalSearchItemClasses(index)"
        :data-search-index="index + 1"
        data-refid="globalSearchItem"
        @click="clickSearchResult(index)"
      >
        <div>
          <component :is="highlightResult(item.result)" />
          <div class="app-global-search__item-type">
            <span>{{ item.type }}</span>
            <span v-if="item.additional">{{ item.additional }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, h, nextTick, onWatcherCleanup, ref, VNode, watch } from 'vue'
import { cloneDeep } from 'lodash'
import { useDebounceFn } from '@vueuse/core'

import { SearchSummary } from '@types'

import { ROUTE_NAME } from '@/const'
import {
  DEBOUNCE_DELAY,
  SEARCH_TAG_ALL,
  SEARCH_TAG_TRANSACTIONS,
} from '@/const/common'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useTransactionsStore } from '@/store/transactions'
import { useTransactionsSettingsStore } from '@/store/transactions/settings'
import { prepareTransactionsSearchList } from '@/store/search/utils/helpers'
import { handleCatchedError } from '@/helpers/common'

import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { UILabeledField } from '@ui'

type Props = {
  tags: string[]
  itemsFound: SearchSummary[]

  isOpen?: boolean
}

type Emits = {
  (e: 'result:click', data: SearchSummary): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const search = defineModel<string>('search', { default: '' })
const currentTag = defineModel<string>('currentTag', { default: '' })

const assetsBunchStore = useAssetsBunchStore()
const transactionsStore = useTransactionsStore()
const transactionsSettingsStore = useTransactionsSettingsStore()

const activeRow = ref<number>(0)
const target = ref<HTMLElement>()

const foundTransactions = ref<any[]>([])

const disabled = ref(false)

const clearedSearch = computed(() => search.value.trim())

const preparedTags = computed(() => {
  const tags = cloneDeep(props.tags)
  if (foundTransactions.value.length) {
    tags.push(SEARCH_TAG_TRANSACTIONS)
  }
  return tags
})

const preparedFoundItems = computed(() => {
  const items = cloneDeep(props.itemsFound)
  if ([SEARCH_TAG_ALL, SEARCH_TAG_TRANSACTIONS].includes(currentTag.value)) {
    items.push(...foundTransactions.value)
  }
  return items
})

const isSearchContainerShown = computed(
  () => preparedFoundItems.value && preparedFoundItems.value.length,
)

const isButtonsShown = computed(
  () => isSearchContainerShown.value || currentTag.value !== SEARCH_TAG_ALL,
)

const globalSearchItemClasses = (index: number) => {
  return [
    'app-global-search__item',
    {
      'app-global-search__item--active': activeRow.value === index + 1,
      'app-global-search__item--disabled': disabled.value,
    },
  ]
}

const getTagItemClasses = (tag: string) => {
  return {
    'app-global-search__tag-item--active': currentTag.value === tag,
  }
}

const highlightResult = (value: string): VNode | null => {
  if (clearedSearch.value === '') {
    return h('div', { className: 'app-global-search__text--medium' }, value)
  }
  let start = 0
  let startArray: (VNode | string)[] = []
  let hasLast = false
  const length = clearedSearch.value.length
  const slices = value.toLowerCase().split(clearedSearch.value.toLowerCase())
  if (slices[0] === '') {
    startArray = [
      h(
        'span',
        { className: 'app-global-search__text--highlighted' },
        value.slice(0, length),
      ),
    ]
    start += length
    slices.shift()
  }
  if (slices[slices.length - 1] === '') {
    hasLast = true
    slices.pop()
  }
  const results = slices.reduce((calc: (VNode | string)[], item) => {
    calc.push(value.slice(start, start + item.length))
    start += item.length
    calc.push(
      h(
        'span',
        { className: 'app-global-search__text--highlighted' },
        value.slice(start, start + length),
      ),
    )
    start += length
    return calc
  }, startArray)
  if (hasLast) {
    results.push(
      h(
        'span',
        { className: 'app-global-search__text--highlighted' },
        value.slice(start),
      ),
    )
  }
  return results.length
    ? h('div', { className: 'app-global-search__text' }, results)
    : null
}

const clickSearchResult = (index: number) => {
  if (!preparedFoundItems.value[index]) return
  emit('result:click', preparedFoundItems.value[index])
}

const fetch = useDebounceFn(async (signal: AbortSignal) => {
  if (clearedSearch.value === '') {
    foundTransactions.value = []
    return
  }
  try {
    const transactions = await transactionsStore.fetch(
      { search: clearedSearch.value },
      false,
      signal,
    )
    if (!transactions.data.length) {
      foundTransactions.value = []
      return
    }
    foundTransactions.value = transactions.data.map(
      prepareTransactionsSearchList(
        SEARCH_TAG_TRANSACTIONS,
        ROUTE_NAME.TRANSACTIONS_ITEM,
        transactionsSettingsStore.getList,
        assetsBunchStore.getNameById,
      ),
    )
  } catch (e) {
    handleCatchedError(e as string, search.value)
    foundTransactions.value = []
  } finally {
    disabled.value = false
  }
}, DEBOUNCE_DELAY)

const keyupListener = (e: KeyboardEvent) => {
  if (!props.isOpen) return
  if (e.code === 'Tab') {
    activeRow.value = 0
  }
  if (e.code === 'ArrowUp') {
    if (activeRow.value > 1) {
      activeRow.value = activeRow.value - 1
    }
  }
  if (e.code === 'ArrowDown') {
    if (activeRow.value < preparedFoundItems.value.length) {
      activeRow.value = activeRow.value + 1
    }
  }
  if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
    target.value
      ?.querySelector(`[data-search-index="${activeRow.value}"]`)
      ?.scrollIntoView()
  }
  if (e.code === 'Enter' && activeRow) {
    clickSearchResult(activeRow.value - 1)
  }
}

watch(
  () => props.isOpen,
  value => {
    if (value) {
      if (!transactionsSettingsStore.initFlag) {
        transactionsSettingsStore.fetch()
      }
      nextTick(() => {
        target.value?.addEventListener('keyup', keyupListener)
      })
    } else {
      target.value?.removeEventListener('keyup', keyupListener)
    }
  },
)

watch(search, (value, old) => {
  if (value === '' && old !== '') {
    currentTag.value = SEARCH_TAG_ALL
  } else if (activeRow.value) {
    activeRow.value = 0
  }
})

watch(currentTag, () => {
  activeRow.value = 0
})

watch(
  search,
  () => {
    const controller = new AbortController()
    disabled.value = true
    fetch(controller.signal)

    onWatcherCleanup(() => {
      controller.abort()
    })
  },
  { immediate: true },
)
</script>

<style scoped>
.app-global-search {
  @apply flex flex-col h-full;
  @apply md:h-auto md:block md:-m-4 md:-mb-6;

  &__container {
    @apply -mx-2 pb-2 overflow-y-auto;
    @apply md:max-h-[35rem];
  }

  &__tags {
    @apply md:flex mb-2;
    @apply md:overflow-x-auto md:whitespace-nowrap;
  }

  &__tag-item {
    @apply py-1 mr-1.5 mb-1.5 md:mb-0 px-3;
    @apply rounded-md text-sm md:text-xs;

    &:not(&--active) {
      @apply bg-gray-50 hover:bg-gray-100 text-gray-500;
      @apply dark:bg-gray-850 dark:hover:bg-gray-900 dark:text-gray-200;
      @apply cursor-pointer;
    }

    &--active {
      @apply bg-indigo-500 dark:bg-indigo-600;
      @apply text-white;
      @apply cursor-default;
    }
  }

  &__item {
    @apply py-3 pl-5 pr-4 cursor-pointer;
    @apply hover:bg-gray-50 dark:hover:bg-gray-850;

    &--active {
      @apply bg-gray-100 dark:bg-gray-800;
    }

    &--disabled {
      @apply opacity-30;
    }
  }

  &__item-type {
    @apply flex justify-between;
    @apply text-gray-400 dark:text-gray-500;
    @apply text-xs;
  }

  &__text {
    @apply mb-1 text-sm;
    @apply text-gray-600 dark:text-gray-300;

    &--medium {
      @apply font-medium;
    }
    &--highlighted {
      @apply text-indigo-600 dark:text-indigo-400;
    }
  }
}
</style>
