<template>
  <div class="wizzard__form">
    <AssetPricesForm
      :placeholder="`Investment value in ${currency}`"
      @submit="handleSubmit"
    />
    <UIList
      v-if="items?.length"
      v-bind="{ columns, items }"
      class="private-fund-prices"
      @remove="handleRemove"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject } from 'vue'
import { AssetClass, AssetPriceForm, TransactionClass } from '@types'

import { numberFormat } from '@/helpers/numbers'
import { stringToLocalDateString } from '@/helpers/dates'

import { useNotifications } from '@/plugins/notification'

import AssetPricesForm from '@/entities/assets/components/AssetPricesForm.vue'
import { UIList } from '@ui'

type Props = {
  transaction: TransactionClass
  asset: AssetClass
}

defineProps<Props>()

const items = defineModel<AssetPriceForm[]>('prices', { required: true })

defineOptions({ inheritAttrs: false })

const { error } = useNotifications()

const currency = inject<ComputedRef<string>>('currency')

const columns = computed(() => [
  {
    name: 'date',
    caption: 'Date',
    content: '1fr',
    formatter: (value: string) => `${stringToLocalDateString(value)}`,
  },
  {
    name: 'close',
    caption: 'Value',
    content: '1fr',
    headerClass: 'text-right',
    cellClass: 'justify-end',
    formatter: (value: number) => numberFormat(value),
  },
])

const handleSubmit = (data: AssetPriceForm) => {
  if (items.value.find(item => item.date === data.date)) {
    error({
      message: 'Price for this date already exists',
    })
    return
  }
  items.value.push({ ...data })
}

const handleRemove = (index: number) => {
  items.value.splice(index, 1)
}
</script>

<style>
.private-fund-prices {
  @apply max-h-[21.5rem];
  @apply overflow-y-auto;
}
</style>
