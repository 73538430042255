<template>
  <div
    v-for="column in columns"
    :key="column.name"
    class="ui-list__cell"
    :class="column.cellClass"
  >
    {{
      column.formatter ? column.formatter(item[column.name]) : item[column.name]
    }}
  </div>
  <div class="ui-list__cell">
    <UIButton
      :icon="TrashIcon"
      variant="light-red"
      size="small"
      @click="handleRemove"
    />
  </div>
</template>

<script setup lang="ts">
import { ListColumn, ListItem } from './utils/types'

import { TrashIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui'

type Props = {
  columns: ListColumn[]
  item: ListItem
}

type Emits = {
  remove: []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const handleRemove = () => emit('remove')
</script>

<style>
.ui-list {
  &__cell {
    @apply py-1 px-3;
    @apply flex items-center;
    @apply text-xs;

    @apply border-b border-common-border;
  }
}
</style>
