export const ROUTE_NAME = {
  INDEX: 'index',
  PERMISSION_DENIED: 'permission-denied',
  UPGRADE_PLAN: 'upgrade-plan',
  DASHBOARDS: 'dashboards',
  DASHBOARDS_DASHBOARD: 'dashboards-dashboard',
  SIGNIN: 'login',
  SIGNIN_CONFIRMATION: 'signin-confirmation',
  SIGNUP: 'signup',
  SIGNUP_CONFIRMATION_CODE: 'signup-confirmation-code',
  CONFIRM: 'confirm',
  FORGOT: 'forgot',
  PASSWORD: 'password',
  ANALYTICS_VALUE: 'analytics-value',
  ANALYTICS_INCOME: 'analytics-income',
  ANALYTICS_POSITION: 'analytics-position',
  ANALYTICS_VALUE_CHANGE: 'analytics-value-change',
  TRANSACTIONS: 'data-transactions',
  TRANSACTIONS_ITEM: 'data-transactions-item',
  ASSETS: 'data-assets',
  ASSETS_ITEM: 'data-assets-item',
  ASSETS_ITEM_SECURITY_MASTER: 'data-assets-item-security-master',
  ASSETS_ITEM_TAGS: 'data-assets-item-tags',
  ASSETS_ITEM_DOCUMENTS: 'data-assets-item-documents',
  ASSETS_ITEM_PRICES: 'data-assets-item-prices',
  ASSETS_ITEM_PRICES_CHART: 'data-assets-item-prices-chart',
  ASSETS_ITEM_PRICES_TABLE: 'data-assets-item-prices-table',
  ASSETS_ITEM_CONTACTS: 'data-assets-item-contacts',
  TAGS: 'data-tags',
  TAGS_ITEM: 'data-tags-item',
  INCOME_ACCOUNTS: 'data-income-accounts',
  INCOME_ACCOUNTS_ITEM: 'data-income-accounts-item',
  EXPENSE_ACCOUNTS: 'data-expense-accounts',
  EXPENSE_ACCOUNTS_ITEM: 'data-expense-accounts-item',
  CURRENCIES: 'data-currencies',
  CURRENCIES_ITEM: 'data-currencies-item',
  CURRENCIES_ITEM_SECURITY_MASTER: 'data-currencies-item-security-master',
  CURRENCIES_ITEM_PRICES: 'data-currencies-item-prices',
  CURRENCIES_ITEM_PRICES_CHART: 'data-currencies-item-prices-chart',
  CURRENCIES_ITEM_TAGS: 'data-currencies-item-tags',
  DOCUMENTS: 'data-documents',
  DOCUMENTS_ITEM: 'data-documents-item',
  CONTACTS: 'data-contacts',
  CONTACTS_ITEM: 'data-contacts-item',
  LINKED_DATA: 'linked-data',
  LINKED_DATA_CONNECTOR: 'linked-data-connector',
  LINKED_DATA_ACCOUNT: 'linked-data-account',
  LINKED_DATA_ACCOUNT_INFO: 'linked-data-account-info',
  LINKED_DATA_ACCOUNT_POSITIONS: 'linked-data-account-positions',
  LINKED_DATA_TRANSACTIONS: 'linked-data-transactions',
  LINKED_DATA_MAPPING_RULES: 'linked-data-mapping-rules',
  SETTINGS: 'settings',
  SETTINGS_ACCOUNT: 'settings-account',
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_PLANS: 'subscription-plans',
  POLICY: 'policy',
  TERMS: 'terms',
}
