<template>
  <div class="select-repository-item-menu" :class="mainClasses" @click.stop>
    <div class="select-repository-item-menu__button" @click="handleClick">
      <XMarkIcon
        v-if="isOpen"
        aria-hidden="true"
        class="select-repository-item-menu__button-icon"
      />
      <EllipsisVerticalIcon
        v-else
        aria-hidden="true"
        class="select-repository-item-menu__button-icon"
      />
    </div>
    <div v-if="isOpen" class="select-repository-item-menu__area">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { EllipsisVerticalIcon, XMarkIcon } from '@heroicons/vue/24/outline'

defineExpose({
  hide() {
    isOpen.value = false
  },
})

const isOpen = ref(false)

const mainClasses = computed(() => ({
  'ui-grid__actions--opened': isOpen.value,
}))

const handleClick = () => {
  isOpen.value = !isOpen.value
}
</script>

<style scoped lang="postcss">
.select-repository-item-menu {
  @apply relative;
  @apply flex items-center;
  @apply -my-1;

  &__button {
    @apply w-8 md:w-6 lg:w-8 h-8;
    @apply flex items-center justify-center;
    @apply text-gray-500 hover:text-indigo-700;
    @apply dark:text-gray-400 dark:hover:text-indigo-300;
    @apply !bg-opacity-80 hover:bg-indigo-50 dark:hover:bg-indigo-900;
    @apply cursor-pointer;
  }

  &__button-icon {
    @apply h-5 w-5;
  }

  &__area {
    @apply absolute -inset-y-1 right-full;
    @apply flex items-center;
    @apply bg-white dark:bg-gray-800;

    @apply after:content-[''];
    @apply after:w-10;
    @apply after:absolute after:inset-y-0 after:right-full;
    @apply after:bg-gradient-to-l;
    @apply after:from-white dark:after:from-gray-800;

    @apply group-hover:bg-gray-100 dark:group-hover:bg-gray-750;
    @apply group-hover:after:from-gray-100 dark:group-hover:after:from-gray-750;

    .ui-dropdown__item--active & {
      @apply bg-gray-50 dark:bg-gray-700;
      @apply after:from-gray-50 dark:after:from-gray-700;
    }

    :deep() .btn {
      @apply whitespace-nowrap;
    }
  }
}
</style>
