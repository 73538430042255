<template>
  <MenuItem v-bind="{ disabled }" :item="parent" placement="bottom-start">
    <div class="main-desktop-tabs__parent" :class="mainClasses">
      <component
        :is="parent.icon"
        v-if="parent.icon"
        class="main-desktop-tabs__parent-icon"
        aria-hidden="true"
      />
      <div class="main-desktop-tabs__parent-name">
        {{ parent.label }}
      </div>
      <ChevronDownIcon class="main-desktop-tabs__parent-chevron" />
    </div>
  </MenuItem>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { NavigationItem } from '@types'

import MenuItem from './components/MenuItem.vue'
import { ChevronDownIcon } from '@heroicons/vue/24/outline'

type Props = {
  parent: NavigationItem
  disabled?: boolean
}

const props = defineProps<Props>()

const mainClasses = computed(() => ({
  'main-desktop-tabs__parent--disabled': props.disabled,
}))
</script>

<style scoped>
.main-desktop-tabs {
  &__parent {
    @apply relative;
    @apply flex items-center;
    @apply gap-1 pr-2;
    @apply text-sm;
    @apply text-light-text;
    @apply cursor-pointer;

    @apply after:content-[''];
    @apply after:h-5;
    @apply after:absolute after:right-0;
    @apply after:border-r after:border-common-border;

    &--disabled {
      @apply opacity-50;
    }
  }

  &__parent-icon {
    @apply w-4 aspect-square;
    @apply shrink-0;
  }

  &__parent-chevron {
    @apply w-3 aspect-square;
    @apply shrink-0;
  }
}
</style>
