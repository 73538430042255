<template>
  <RepositoriesForm
    v-model="modelValue"
    v-bind="{
      currenciesList,
      disabled,
      focusOnLoad,
      goToRepo,
      readonly,
      wrapped,
    }"
    :disabled-go-to-repo="routerCanBePushed"
    @close="handleCloseForm"
    @submit="handleSubmitForm"
    @active-again="handleActiveAgain"
    @click:go-to-repo="handleClickGoToRepo"
    @update:dirty="dirty?.($event)"
  />
</template>

<script setup lang="ts">
import { computed, inject, onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { PartialBy, Repository, RepositoryPost, RepositoryPut } from '@types'

import { ROUTE_NAME, SET_CURRENT_REPOSITORY } from '@/const'
import { ACCESS_TYPE_READONLY } from '@/const/repositories'

import { handleCatchedError } from '@/helpers/common'
import { useNotifications } from '@/plugins/notification'

import useAnalyticsStore from '@/store/analytics'
import { useRepositoriesStore } from '@/store/repositories'
import { useTransactionsStore } from '@/store/transactions'

import RepositoriesForm from './components/Form.vue'

type RepositoryModel = PartialBy<RepositoryPut, 'id'>

type Props = {
  focusOnLoad?: boolean
  wrapped?: boolean
}

type Emits = {
  close: []
  saved: [data?: Repository]
}

const { focusOnLoad = true } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<RepositoryModel>()

const router = useRouter()

const { error } = useNotifications()

const analyticsStore = useAnalyticsStore()
const repositoriesStore = useRepositoriesStore()
const transactionsStore = useTransactionsStore()

const prevPriceSourcePrecedence = ref(
  modelValue.value?.settings?.price_source_precedence,
)

const readonly = computed(
  () =>
    !!modelValue.value?.user_repo_settings?.access_type &&
    ACCESS_TYPE_READONLY === modelValue.value.user_repo_settings?.access_type,
)

const currenciesList = computed(() => repositoriesStore.currenciesList)
const disabled = computed(() => repositoriesStore.loadingAction)

// eslint-disable-next-line @typescript-eslint/no-empty-function
const dirty = inject<(value: boolean) => void>('modal-mark-dirty', () => {})

const setCurrentRepository = inject<(id?: string) => void>(
  SET_CURRENT_REPOSITORY,
)

const goToRepo = ref<Repository>()

const handleCloseForm = () => {
  emit('close')
}

const handleSubmitForm = async (value: RepositoryModel) => {
  try {
    if (modelValue.value?.id) {
      dirty?.(false)
      emit('close')
      await repositoriesStore.updateRepository({
        ...modelValue.value,
        ...value,
      } as RepositoryPut)
      const refreshAnalytics =
        JSON.stringify(prevPriceSourcePrecedence.value) !==
        JSON.stringify(value.settings?.price_source_precedence)
      if (refreshAnalytics) {
        analyticsStore.markAsDeprecated()
        prevPriceSourcePrecedence.value =
          value.settings?.price_source_precedence
      }
      return
    } else {
      if (repositoriesStore.currentRepositoryId === undefined) {
        dirty?.(false)
      }
      if (
        repositoriesStore.list.find(
          repo => repo.name.toLowerCase() === value.name?.toLowerCase(),
        )
      ) {
        error({
          message:
            'This name of the repository already exists. Try something else',
        })
        return
      }
      goToRepo.value = await repositoriesStore.createRepository({
        ...modelValue.value,
        ...value,
      } as RepositoryPost)
      emit('saved', goToRepo.value)
    }
    await repositoriesStore.fetchRepositories()
  } catch (e) {
    handleCatchedError(e as string, value)
  }
}

const handleActiveAgain = () => {
  goToRepo.value = undefined
}

const routerCanBePushed = ref(false)
const handleClickGoToRepo = (id: string) => {
  setCurrentRepository && setCurrentRepository(id)
  emit('close')
  routerCanBePushed.value = true
}

const transactionsLoading = computed(() => transactionsStore.loading)
watch(transactionsLoading, (value, old) => {
  if (old && !value && routerCanBePushed.value) {
    router.push({ name: ROUTE_NAME.TRANSACTIONS })
    emit('close')
    routerCanBePushed.value = false
  }
})

onUnmounted(() => {
  dirty?.(false)
})
</script>

<script lang="ts">
export default {
  name: 'RepositoryForm',
}
</script>
