export enum UserPlanName {
  PROFESSIONAL = 'professional',
  STANDARD = 'standard',
}

export enum UserSubscriptionStatus {
  FUTURE = 'future',
  IN_TRIAL = 'in_trial',
  ACTIVE = 'active',
  NON_RENEWING = 'non_renewing',
  PAUSED = 'paused',
  CANCELLED = 'cancelled',
  TRANSFERED = 'transferred',
  INACTIVE = 'inactive',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
}
