<template>
  <AllpositLogo class="app-logo" :class="mainClasses" alt="Allposit" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { LogoSize } from './utils/enums'

import AllpositLogo from '@/assets/images/logo.svg'

type Props = {
  size?: LogoSize
}

const props = defineProps<Props>()

defineOptions({ name: 'AppLogo' })

const mainClasses = computed(() => ({
  [`app-logo--${props.size}`]: props.size,
}))
</script>

<style>
.app-logo {
  @apply h-4 w-4;
  @apply text-white;

  &--lg {
    @apply h-11 w-11;
  }
}
</style>
