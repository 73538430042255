<template>
  <div class="main-desktop-panel">
    <div class="main-desktop-panel__menu">
      <template v-for="(item, index) in items" :key="item.label">
        <MainDesktopPanelItem v-bind="{ item }" :wide="isPanelOpened" />
        <template v-if="index === 0">
          <MainDesktopPanelRepository />
          <MainDesktopPanelTree
        /></template>
      </template>
      <MainDesktopPanelAddAsset />
    </div>
    <div class="main-desktop-panel__bottom">
      <TogglePanelButton
        v-tooltip.right="t('Toggle panel')"
        :value="isPanelOpened"
        @toggle="handleToggle"
      />
    </div>
  </div>
  <MainDesktopPanelTreeSection v-if="isTreeOpened" />
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { OpenedFlag } from './utils/type'

import { PANEL_OPENED, TREE_OPENED } from './utils/const'

import { useNavigationStore } from '@/store/navigation'

import { useLocale } from '@/plugins/i18n'

import MainDesktopPanelAddAsset from './MainDesktopPanelAddAsset.vue'
import MainDesktopPanelRepository from './MainDesktopPanelRepository.vue'
import MainDesktopPanelTree from './MainDesktopPanelTree.vue'
import MainDesktopPanelTreeSection from './MainDesktopPanelTreeSection.vue'
import TogglePanelButton from './components/TogglePanelButton.vue'
import MainDesktopPanelItem from './MainDesktopPanelItem.vue'

const { t } = useLocale('layouts.Main')

const navigationStore = useNavigationStore()

const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)
const isPanelOpened = inject<OpenedFlag>(PANEL_OPENED)

const items = computed(() => navigationStore.getParentNavigation)

const handleToggle = () => {
  isTreeOpened && (isTreeOpened.value = false)
  isPanelOpened && (isPanelOpened.value = !isPanelOpened.value)
}
</script>

<style>
.main-desktop-panel {
  @apply flex flex-col items-center;
  @apply p-2 gap-4;
  @apply bg-body-gray;
  @apply border-r border-light-border;
  @apply z-10;
  @apply transition-all;

  .main-desktop--opened-tree & {
    @apply border-common-border;
  }

  &__menu {
    @apply flex flex-col;
    @apply flex-auto;
    @apply gap-2;
  }

  &__bottom {
    @apply w-full;
    @apply flex justify-end;
  }

  &__button {
    @apply flex items-center;
    @apply -ml-[1px];
    @apply cursor-pointer;
    @apply rounded-md;
    @apply border border-transparent;
    @apply hover:bg-body-dark;
    @apply text-light-text;

    .v-popper--shown & {
      @apply bg-body-dark;
    }
  }

  &__button-icon {
    @apply w-8;
    @apply relative;
    @apply flex items-center justify-center;
    @apply shrink-0;
    @apply aspect-square;

    svg {
      @apply w-5 h-5;
    }
  }

  &__button-status-icon {
    @apply !w-2.5 !h-2.5;
    @apply absolute;
    @apply top-0.5 right-0;
    @apply text-indigo-400;
  }

  &__button-caption {
    @apply w-0;
    @apply text-xs;
    @apply whitespace-nowrap;
    @apply overflow-hidden;
    @apply transition-all;

    .main-desktop--opened-panel & {
      @apply w-32;
    }
  }
}
</style>
