<template>
  <div class="ui-filter__param__field--name">
    <UIInputDropdown
      ref="fieldRef"
      v-bind="{ container, size }"
      v-model="field"
      :data="fieldsData"
      id-key="key"
      :placeholder="t('Select field')"
    />
  </div>
  <div class="ui-filter__param__remove ui-filter__param__remove-sm">
    <RemoveButton :disabled="isRemoveButtonDisabled" @click="handleRemove" />
  </div>
  <div class="ui-filter__param__field--comparison">
    <UIInputDropdown
      v-if="isComparisonVisible"
      ref="comparisonRef"
      v-bind="{ container, size }"
      v-model="comparison"
      :data="comparisonData"
      id-key="key"
      :placeholder="t('Select comparison')"
    />
  </div>
  <div class="ui-filter__param__field--value">
    <GroupsItemParamValue
      v-if="isValueVisible"
      ref="valueRef"
      v-model="paramValue"
      v-bind="{ comparison, fieldSettings }"
    />
  </div>
  <div class="ui-filter__param__remove ui-filter__param__remove-md">
    <RemoveButton :disabled="isRemoveButtonDisabled" @click="handleRemove" />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeMount, ref, useTemplateRef } from 'vue'

import { ButtonSizes, I18nTranslate } from '@types'
import { FilterField, FilterParam } from '../utils/types'
import { FilterComparison } from '../utils/enums'

import {
  getComparisonData,
  getDefaultComparison,
  getDefaultValue,
} from './utils/helpers'

import { UIInputDropdown } from '@ui'
import GroupsItemParamValue from './GroupsItemParamValue.vue'
import RemoveButton from './RemoveButton.vue'

type Props = {
  fields?: FilterField[]
  param: FilterParam
  groupIndex: number
  index: number
}

type Emits = {
  'remove:param': [key: string]
  'update:param': [key: string, data: Partial<FilterParam>]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  start() {
    fieldRef.value?.select().toggle(true)
  },
})

const container = inject<string>('container')
const size = inject<ButtonSizes>('size')
const t = inject<I18nTranslate>('t', (data: string) => data)

const fieldRef = useTemplateRef('fieldRef')
const comparisonRef = useTemplateRef('comparisonRef')
const valueRef = useTemplateRef('valueRef')

const fieldSettings = ref<FilterField>()

const isFilled = computed(
  () =>
    !!comparison.value &&
    (!!paramValue.value ||
      [FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
        comparison.value,
      )),
)

const isRemoveButtonDisabled = computed(
  () => props.groupIndex === 0 && props.index === 0 && !isFilled.value,
)

const fieldsData = computed(() =>
  props.fields?.map(field => ({ key: field.name, value: field.caption })),
)

const comparisonData = computed(() =>
  getComparisonData(fieldSettings.value).map(key => ({
    key,
    value: t(key),
  })),
)

const field = computed({
  get() {
    return props.param.field
  },
  set(field) {
    fieldSettings.value = props.fields?.find(item => item.name === field)
    const comparison = getDefaultComparison(fieldSettings.value)
    const value = getDefaultValue(comparison, fieldSettings.value)
    updateParam({ comparison, field, value })
    setTimeout(() => {
      comparisonRef.value?.select().toggle(true)
      valueRef.value?.initData?.()
    })
  },
})

const comparison = computed({
  get() {
    return props.param.comparison
  },
  set(comparison) {
    fieldSettings.value = props.fields?.find(item => item.name === field.value)
    const value = getDefaultValue(
      comparison,
      fieldSettings.value,
      props.param.comparison,
      paramValue.value,
    )
    updateParam({ comparison, value })
    setTimeout(() => {
      valueRef.value?.start()
    })
  },
})

const paramValue = computed({
  get() {
    return props.param.value
  },
  set(value) {
    updateParam({ value })
  },
})

const isComparisonVisible = computed(() => !!field.value)
const isValueVisible = computed(() => !!comparison.value)

const updateParam = (data: Partial<FilterParam>) => {
  if (!props.param.key) return
  emit('update:param', props.param.key, data)
}

const handleRemove = () => {
  if (!props.param.key) return
  emit('remove:param', props.param.key)
}

onBeforeMount(() => {
  fieldSettings.value = props.fields?.find(
    item => item.name === props.param.field,
  )
})
</script>

<script lang="ts">
export default {
  name: 'FilterGroupsItemParam',
}
</script>

<style scoped lang="postcss">
.ui-filter__param {
  &__field {
    &--comparison,
    &--value {
      @apply col-span-2 md:col-auto;

      & > * {
        @apply mt-2 md:mt-0;
      }
    }
  }
  &__remove {
    @apply flex items-center;

    &-sm {
      @apply md:hidden;
    }
    &-md {
      @apply hidden md:block;
    }
  }
}
</style>
