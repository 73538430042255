<template>
  <div v-if="isPanelVisible" class="app-reload-page">
    <UILoading message="Reloading the page..." />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useSwipe, UseSwipeDirection } from '@vueuse/core'

import { UILoading } from '@ui'

useSwipe(document.body, {
  threshold: 200,
  onSwipeEnd: (e: TouchEvent, direction: UseSwipeDirection) => {
    if (!e.cancelable || direction !== 'down') return
    handleReload()
  },
})

const isPanelVisible = ref(false)

const handleReload = () => {
  isPanelVisible.value = true
  window.location.reload()
}
</script>

<style lang="postcss">
.app-reload-page {
  @apply h-10;
  @apply fixed inset-x-0 top-0;
  @apply flex items-center justify-center;
  @apply bg-gray-50 dark:bg-gray-900;
  @apply bg-opacity-90 dark:bg-opacity-50;
  @apply z-[10000];
}
</style>
