<template>
  <UIModal
    v-bind="{ isOpen }"
    title="You have invitation to collaboration"
    :type="ModalType.DIALOG"
    :uncloseable="hasUnanswered"
    size="lg"
    wrapper-class="app-invites"
    @hide="handleHide"
  >
    <InvitesGrid @close="handleHide" />
  </UIModal>
</template>

<script setup lang="ts">
import { computed, inject, ref, watch } from 'vue'

import { ModalType } from '@types'

import { SET_CURRENT_REPOSITORY } from '@/const'

import { handleCatchedError } from '@/helpers/common'

import { useRepositoriesStore } from '@/store/repositories'

import InvitesGrid from './InvitesGrid.vue'

import { UIModal } from '@ui'

defineOptions({ name: 'AppInvites' })

const setCurrentRepository = inject<(id?: string) => void>(
  SET_CURRENT_REPOSITORY,
)

const repositoriesStore = useRepositoriesStore()

const isOpen = ref(false)

const hasUnanswered = computed(
  () =>
    !!repositoriesStore.invitesList?.filter(
      invite => invite.accepted === undefined,
    ).length,
)

const handleHide = async (repositoryId?: string) => {
  if (hasUnanswered.value) return
  isOpen.value = false
  try {
    await repositoriesStore.fetchRepositories()
    setCurrentRepository && setCurrentRepository(repositoryId)
  } catch (e) {
    handleCatchedError(e as string)
  }
}

const handleShow = () => {
  isOpen.value = true
}

watch(
  () => [repositoriesStore.loadingInvites, hasUnanswered.value],
  ([loading, unanswered]) => {
    if (loading || !unanswered) return
    handleShow()
  },
  {
    immediate: true,
  },
)
</script>

<style>
.app-invites {
  @apply flex-auto;
  @apply -mt-6 -mb-4 lg:px-2;
}
</style>
