<template>
  <template v-if="isComponentVisible">
    <slot v-bind="{ items }" />
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useDark, useToggle } from '@vueuse/core'

import {
  ArrowRightEndOnRectangleIcon,
  Cog6ToothIcon,
  MoonIcon,
  SunIcon,
} from '@heroicons/vue/24/outline'

import { ROUTE_NAME } from '@/const'

import { useUserStore } from '@/store/user'

defineOptions({ name: 'MainUser' })

const userStore = useUserStore()

const router = useRouter()

const isDark = useDark()
const toggleDark = useToggle(isDark)

const darkThemeIcon = computed(() => (isDark.value ? SunIcon : MoonIcon))

const isComponentVisible = computed(() => userStore.getEmail)

const items = computed(() => [
  {
    label: isDark.value ? 'Light Theme' : 'Dark Theme',
    icon: darkThemeIcon.value,
    action: () => {
      toggleDark()
    },
  },
  {
    key: 'settings',
    label: 'Settings',
    icon: Cog6ToothIcon,
    action: (hide: () => void) => {
      hide()
      router.push({ name: ROUTE_NAME.SETTINGS })
    },
  },
  {
    label: 'Sign out',
    icon: ArrowRightEndOnRectangleIcon,
    action: handleSignOut,
    attrs: {
      'data-refid': 'headerSignout',
    },
  },
])

const handleSignOut = async () => {
  await userStore.signOut()
}
</script>
