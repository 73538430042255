<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog
      v-bind="{ zIndex, size: '2md' }"
      title="Add Accounts and Assets"
      @hide="handleClose"
    >
      <div class="m-auto">
        <CreateActions @click="handleClose" />
      </div>
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { ModalClass, ModalState } from '@types'

import { TransitionRoot } from '@headlessui/vue'
import { UIDialog } from '@ui'
import CreateActions from '@/views/components/CreateActions.vue'

type Props = {
  modal: ModalClass<any>
}

const props = defineProps<Props>()

const zIndex = computed(() => props.modal.zIndex)
const isOpen = computed(() => props.modal.state !== ModalState.CLOSED)

const handleClose = () => {
  props.modal.close()
}
</script>
