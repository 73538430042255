<template>
  <Dropdown
    v-bind="{ container, shown, popperClass }"
    ref="containerRef"
    :disabled="dropdownDisabled"
    :triggers="[]"
    strategy="fixed"
    theme="dropdown"
    class="ui-input-dropdown"
    :class="mainClasses"
    :auto-hide="false"
    auto-size
  >
    <InputField
      v-bind="{ ...props, ...$attrs, dataRefid, disabled }"
      ref="inputRef"
      v-model="searchModel"
      :placeholder="searchPlaceholder"
      :readonly="isReadonly"
      autocomplete="off"
      @click:esc="handleClickEsc"
      @click:enter="handleClickEnter"
      @click:tab="handleClickTab"
      @key:up="handleKeyUp"
      @blur="handleBlur"
      @focus="handleFocus"
    >
      <template #leading><slot name="leading" /></template>
      <template v-if="!readonly" #trailing>
        <UILoadingIcon v-if="loading" />
        <slot v-else name="trailing" v-bind="{ handleHoldFocus, handleToggle }">
          <component
            :is="trailingComponent"
            class="ui-input-dropdown__arrow"
            @mousedown="handleHoldFocus"
            @click="handleToggle"
          />
        </slot>
      </template>
    </InputField>
    <template #popper>
      <div ref="popperRef" class="ui-input-dropdown__popper">
        <template v-for="(item, index) in items" :key="item[idKey] + index">
          <div
            v-if="item.value === DELIMETER_KEYWORD"
            class="ui-input-dropdown__item--delimeter"
          />
          <DropdownItem
            v-else
            v-bind="{ idKey, item, valueKey }"
            :current-value="modelValue"
            :selected="index === selectedIndex"
            @mousedown="handleHoldFocus"
            @click:item="handleClickItem"
          >
            <slot v-bind="{ item }" name="item" />
          </DropdownItem>
        </template>
        <div
          v-if="!items.length && !isFooterSlotFilled"
          class="ui-input-dropdown__empty"
        >
          <template v-if="loading">{{ t('Waiting for response') }}...</template>
          <template v-else>{{ t('Nothing to show') }}</template>
        </div>
        <slot name="footer" />
      </div>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import {
  computed,
  nextTick,
  onBeforeMount,
  onBeforeUpdate,
  onMounted,
  ref,
  useSlots,
  useTemplateRef,
  watch,
} from 'vue'
import { Dropdown } from 'floating-vue'
import {
  MaybeElement,
  onClickOutside,
  pausableWatch,
  useDebounceFn,
} from '@vueuse/core'

import { EMPTY_VALUE_PLACEHOLDER } from '@/const/common'
import { DELIMETER_KEYWORD } from './utils/const'
import { InputDropdownItem } from './utils/types'
import { CommonEmits, CommonProps, InputData } from '../utils/types'

import { useLocale } from '@/plugins/i18n'

import DropdownItem from './components/Item.vue'
import InputField from '../components/InputField.vue'
import { UILoadingIcon } from '@ui'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'

export type Props = CommonProps & {
  dataRefid?: string

  data?: InputDropdownItem[]
  loading?: boolean

  idKey?: string
  valueKey?: string

  placeholder?: string

  disableSearch?: boolean
  disabled?: boolean

  readonly?: boolean
  simple?: boolean
  container?: string
  popperClass?: string

  isGridEdit?: boolean

  valueHandler?: (data: any) => string
}

type Emits = CommonEmits & {
  select: [data: InputDropdownItem]
  search: [data: string]
}

const {
  container,
  idKey = 'value',
  valueKey = 'value',
  disableSearch,
  simple,
  valueHandler,
  ...props
} = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<InputData>()
const searchModel = defineModel<string>('search', { default: '' })
const searchPlaceholder = ref(props.placeholder || '')

const exposeObj = {
  toggle(flag: boolean) {
    shown.value = flag
    return exposeObj
  },
  blur() {
    inputRef.value?.blur()
    return exposeObj
  },
  focus() {
    inputRef.value?.focus()
    return exposeObj
  },
  select() {
    inputRef.value?.select()
    return exposeObj
  },
  clear() {
    inputRef.value?.clear()
    return exposeObj
  },
  reset() {
    setInputSearchModel(modelValue.value)
    items.value = data.value
    return exposeObj
  },
  begin() {
    inputRef.value?.begin()
    return exposeObj
  },
}

defineExpose(exposeObj)

const slots = useSlots()
const { t } = useLocale('components.UI.Input')

const containerRef = useTemplateRef<MaybeElement>('containerRef')
const inputRef = useTemplateRef('inputRef')
const popperRef = useTemplateRef('popperRef')

const shown = ref(false)

const items = ref<InputDropdownItem[]>([])
const selectedIndex = ref<number>()

const holdFocus = ref(false)

const isFooterSlotFilled = ref(false)
const data = computed(() => props.data || [])
const isReadonly = computed(() => simple || props.readonly)
const dropdownDisabled = computed(() => props.disabled || props.readonly)

const popperClass = computed(
  () =>
    `ui-input-dropdown__popper ${props.popperClass ? ` ${props.popperClass}` : ''}`,
)

const mainClasses = computed(() => ({
  'ui-input-dropdown--simple': simple,
}))

const trailingComponent = computed(() =>
  shown.value ? ChevronUpIcon : ChevronDownIcon,
)

const handleToggle = async () => {
  if (props.disabled) return
  shown.value = !shown.value
  await nextTick()
  inputRef.value?.focus()
  holdFocus.value = false
}

const setInputSearchModel = (value?: InputData) => {
  pause()
  let newValue
  if (valueHandler) {
    newValue = valueHandler(value)
  } else if (value) {
    newValue =
      data.value.find(item => item[idKey] === value)?.[valueKey] || value
  } else {
    newValue = ''
  }

  if (newValue === EMPTY_VALUE_PLACEHOLDER) {
    searchModel.value = ''
    searchPlaceholder.value = EMPTY_VALUE_PLACEHOLDER
  } else {
    searchModel.value = newValue as string
    searchPlaceholder.value = props.placeholder || ''
  }
  nextTick(resume)
}

const handleClickItem = async (item: InputDropdownItem) => {
  if (item.readonly) return
  modelValue.value = item[idKey]
  pause()
  searchModel.value = item[valueKey]
  shown.value = false
  await nextTick()
  resume()
  inputRef.value?.focus()
  holdFocus.value = false
  emit('select', item)
}

const handleClickEsc = (e: KeyboardEvent) => {
  if (shown.value) {
    e.stopImmediatePropagation()
  }
  shown.value = false
  emit('click:esc', e)
}

const handleClickEnter = (e: KeyboardEvent) => {
  const value =
    (selectedIndex.value && items.value[selectedIndex.value]) ||
    items.value?.[0]
  value && handleClickItem(value)
  emit('click:enter', e)
}

const handleClickTab = (e: KeyboardEvent) => {
  if (!isFooterSlotFilled.value) {
    shown.value = false
  }
  emit('click:tab', e)
}

const handleHoldFocus = () => {
  if (props.disabled) return
  holdFocus.value = true
}

const handleBlur = () => {
  if (holdFocus.value) return
  emit('blur')
}

const handleFocus = () => {
  emit('focus')
}

const selectNextItem = () => {
  if (selectedIndex.value === undefined) {
    selectedIndex.value = 0
  } else if (selectedIndex.value < items.value.length - 1) {
    selectedIndex.value++
  }
  if (items.value[selectedIndex.value].value === DELIMETER_KEYWORD) {
    selectNextItem()
  }
}
const selectPreviousItem = () => {
  if (selectedIndex.value === undefined) {
    selectedIndex.value = items.value.length - 1
  } else if (selectedIndex.value > 0) {
    selectedIndex.value--
  }
  if (items.value[selectedIndex.value].value === DELIMETER_KEYWORD) {
    selectPreviousItem()
  }
}

const handleKeyUp = (e: KeyboardEvent) => {
  switch (e.key) {
    case 'ArrowUp':
      e.preventDefault()
      if (!shown.value) return
      selectPreviousItem()
      break
    case 'ArrowDown':
      e.preventDefault()
      if (!shown.value) {
        shown.value = true
        return
      }
      selectNextItem()
      break
  }
}

const onSearch = useDebounceFn((value: string) => {
  const search = value.toString().toLowerCase()
  if (!disableSearch) {
    items.value = data.value.filter(item =>
      item[valueKey].toLowerCase().includes(search),
    )
  }
  shown.value = shown.value || !!search
  emit('search', value)
}, 500)

watch(selectedIndex, value => {
  if (value === undefined) return
  document
    .querySelector(
      '.v-popper__popper--shown .ui-input-dropdown__item--selected',
    )
    ?.scrollIntoView()
})

const { pause, resume } = pausableWatch(searchModel, value => {
  if (value === '') {
    modelValue.value = undefined
    selectedIndex.value = undefined
  }
  onSearch(value)
})

watch(modelValue, setInputSearchModel)

watch(data, value => {
  items.value = value
})

onClickOutside(containerRef, event => {
  if (popperRef.value && event.composedPath().includes(popperRef.value)) {
    return
  }
  shown.value = false
})

onBeforeMount(async () => {
  items.value = data.value
  await nextTick()
  if (searchModel.value) return
  setInputSearchModel(modelValue.value)
})

onMounted(() => {
  if (props.isGridEdit) {
    setTimeout(() => {
      inputRef.value?.select()
    }, 0)
  }
})

onBeforeUpdate(() => {
  isFooterSlotFilled.value = !!slots.footer?.()
})
</script>

<script lang="ts">
export default {
  name: 'InputDropdown',
}
</script>

<style lang="postcss">
.ui-input-dropdown {
  &__arrow {
    @apply cursor-pointer;
  }
  &__empty {
    @apply px-4 py-2;
    @apply text-sm;
    @apply text-gray-400 dark:text-gray-500;
  }
  .input-field__icon,
  .input-field__input {
    @apply text-gray-950 dark:text-gray-50;
    @apply pr-0;
  }

  &--simple {
    .input-field__input {
      @apply text-inherit;
    }
  }

  &__popper {
    @apply max-h-64;
  }

  &__item--delimeter {
    @apply -mb-[1px];
    @apply border-t border-gray-200 dark:border-gray-700;
  }
}
</style>
