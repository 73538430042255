import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'

import { DATE_FORMATS } from '@/helpers/dates/utils/const'

import { dateFormat } from '@/helpers/dates'

import { useInactivityHandler } from '@/plugins/inactivity'

import { useDashboardsSettingsStore } from '@/store/settings/dashboards'

import { useUserStore } from '.'

import api from '@/store/api'
import { DASHBOARD_VIEWS } from '@/const'

const US_DATE_FORMAT = Intl.DateTimeFormat()
  .resolvedOptions()
  .timeZone.includes('America/')

export const useUserSettingsStore = defineStore('user-settings', () => {
  // INIT
  const dashboardsSettingsStore = useDashboardsSettingsStore()
  const userStore = useUserStore()
  const inactivityHandler = useInactivityHandler()

  const data = ref<Record<string, any>>()

  const isInited = ref(false)

  // GETTERS
  const getDateFormat = computed(
    () => data.value?.dateFormat || getActualDateFormat(),
  )
  const getLastActiveRepository = computed(
    () => data.value?.lastActiveRepository,
  )
  const getAllowedInactivityTime = computed(
    () => data.value?.allowedInactivityTime,
  )
  const getEarlyAccess = computed(() => data.value?.earlyAccess)
  const getPinnedMenu = computed<string[]>(
    () =>
      data.value?.pinnedMenu || [
        `dashboards-dashboard${JSON.stringify({ slug: 'portfolio' })}`,
        `dashboards-dashboard${JSON.stringify({ slug: 'linked-transactions' })}`,
      ],
  )

  // SETTERS

  const setData = async (value: Record<string, any>) => {
    data.value = value
  }

  const setDateFormat = (dateFormat: string) => {
    update({ dateFormat })
  }

  const setLastActiveRepository = (lastActiveRepository?: string) => {
    update({ lastActiveRepository })
  }

  const setAllowedInactivityTime = (allowedInactivityTime?: number) => {
    if (!allowedInactivityTime) return
    update({ allowedInactivityTime })
  }

  const pinMenu = (key: string) => {
    const pinnedMenu: string[] = getPinnedMenu.value
    const index = pinnedMenu.findIndex(item => item === key)
    if (!~index) {
      pinnedMenu.push(key)
      update({ pinnedMenu })
    }
  }

  const unpinMenu = (key: string) => {
    const pinnedMenu: string[] = data.value?.pinnedMenu || []
    const index = pinnedMenu.findIndex(item => item === key)
    if (~index) {
      pinnedMenu.splice(index, 1)
      update({ pinnedMenu })
    }
  }

  const togglePinMenu = (key: string) => {
    const pinnedMenu: string[] = data.value?.pinnedMenu || []
    const index = pinnedMenu.findIndex(item => item === key)
    if (!~index) {
      pinnedMenu.push(key)
    } else {
      pinnedMenu.splice(index, 1)
    }
    update({ pinnedMenu })
  }

  // ACTIONS

  const getActualDateFormat = () =>
    US_DATE_FORMAT ? DATE_FORMATS[1] : DATE_FORMATS[0]

  const fetch = async () => {
    const allpositUuid = await userStore.getAndValidateUuid()
    if (!allpositUuid) return
    const result = await api.get(`users/${allpositUuid}/settings`)
    setData(result.data)
    isInited.value = true
  }

  const update = async (value: Record<string, any>) => {
    const allpositUuid = await userStore.getAndValidateUuid()
    if (!allpositUuid) return
    const params = {
      ...data.value,
      ...value,
    }
    api.post(`users/${allpositUuid}/settings`, params)
    data.value = params
  }

  const clear = () => {
    isInited.value = false
    data.value = undefined
  }

  watch(
    () => [isInited.value, getAllowedInactivityTime.value],
    ([inited, waitingTime]) => {
      if (!inited) return
      inactivityHandler.start(waitingTime)
    },
    { immediate: true },
  )

  watch(
    getDateFormat,
    value => {
      dateFormat.value = value
    },
    { immediate: true },
  )

  return {
    isInited,

    getDateFormat,
    getLastActiveRepository,
    getAllowedInactivityTime,
    getEarlyAccess,
    getPinnedMenu,

    setDateFormat,
    setLastActiveRepository,
    setAllowedInactivityTime,

    pinMenu,
    unpinMenu,
    togglePinMenu,

    fetch,

    clear,
  }
})
