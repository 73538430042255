<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <UIDialog
      v-bind="{ zIndex, title }"
      size="lg"
      wrapper-class="!p-0"
      @hide="handleClose"
    >
      <template v-if="isChanges" #title>
        <div class="mapping-rules__title">
          <span>Preview changes</span>
          <span v-if="criteria" class="mapping-rules__title__criteria"
            >If {{ criteria }}</span
          >
        </div>
      </template>
      <RulesSettings
        v-if="!isChanges"
        v-bind="{ actionsModel, criteriaModel }"
        @update:actions-model="actionsModel = $event"
        @update:criteria-model="criteriaModel = $event"
      >
        <UIButton
          :disabled="previewDisabled"
          label="Preview changes"
          variant="secondary"
          @click="handleClickPreview"
        />
        <UIButtonDropdown
          v-bind="{ label, disabled, items }"
          :variant="actionVariant"
          @click:item="handleClickItem"
        />
      </RulesSettings>
      <RulesChanges v-else :data="ruleData">
        <template v-if="!readonly">
          <UIButton
            label="Rule settings"
            variant="secondary"
            @click="handleClickEdit"
          />
          <UIButtonDropdown
            v-bind="{ label, disabled, items }"
            :variant="actionVariant"
            @click:item="handleClickItem"
          />
        </template>
      </RulesChanges>
    </UIDialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { cloneDeep, isEqual } from 'lodash'
import { useMagicKeys } from '@vueuse/core'

import { DropdownItem, FilterComparison, TransactionRule } from '@types'
import { SettingsActions } from './utils/enums'

import { NOTIFICATION_DELAY, OVERLAY_DURATION } from '@/const/common'
import { DEFAULT_ACTIONS, DEFAULT_CRITERIA, PREFILLED_ID } from './utils/const'

import { handleCatchedError } from '@/helpers/common'
import { useNotifications } from '@/plugins/notification'

import { useLinkedDataMappingRulesStore } from '@/store/linkedData/rules'
import { useModalsStore } from '@/store/modals'

import { TransitionRoot } from '@headlessui/vue'
import RulesSettings from './RulesSettings.vue'
import RulesChanges from './RulesChanges.vue'
import { UIButton, UIButtonDropdown, UIDialog } from '@ui'

type Props = {
  readonly?: boolean
}

type Emits = {
  refresh: []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const { escape } = useMagicKeys()
const linkedDataMappingRulesStore = useLinkedDataMappingRulesStore()
const modalsStore = useModalsStore()

const { progress, remove, update } = useNotifications()

const exposeObj = {
  init(data: TransactionRule) {
    currentRule.value = data
    return exposeObj
  },
  reset() {
    resetData()
    return exposeObj
  },
  toggle(flag: boolean, changes?: boolean) {
    if (changes !== undefined) {
      isChanges.value = changes
    }
    isOpen.value = flag
    return exposeObj
  },
}

defineExpose(exposeObj)

const currentRule = ref<TransactionRule>()

const isOpen = ref(false)
const isChanges = ref(false)

const criteriaModel = ref(cloneDeep(DEFAULT_CRITERIA))
const actionsModel = ref(cloneDeep(DEFAULT_ACTIONS))

const zIndex = computed(() => modalsStore.getZIndex())

const ruleId = computed(() => currentRule.value?.id)

const title = computed(() => (ruleId.value ? 'Edit rule' : 'New rule'))
const label = computed(() => (ruleId.value ? 'Save' : 'Create'))

const ruleData = computed(() => ({
  criteria: filledCriteria.value,
  actions: filledActions.value,
}))
const currentRuleData = computed(() => currentRule.value?.data)

const criteria = computed(() =>
  ruleData.value.criteria
    .map(item => [item.type, item.comparison, item.value].join(' '))
    .join(' AND '),
)

const filledCriteria = computed(() =>
  Object.values(criteriaModel.value).filter(
    criteria =>
      criteria.comparison &&
      ([FilterComparison.EMPTY, FilterComparison.NOTEMPTY].includes(
        criteria.comparison,
      ) ||
        (criteria.value !== '' && criteria.value !== null)),
  ),
)
const filledActions = computed(() =>
  Object.values(actionsModel.value).filter(
    action => action.value ?? action.tag_ids,
  ),
)

const isCriteriaEmpty = computed(() => filledCriteria.value.length === 0)
const isActionsEmpty = computed(() => filledActions.value.length === 0)

const isCriteriaChanged = computed(
  () => !isEqual(filledCriteria.value, currentRuleData.value?.criteria),
)
const isActionsChanged = computed(
  () => !isEqual(filledActions.value, currentRuleData.value?.actions),
)
const previewDisabled = computed(
  () => isCriteriaEmpty.value || isActionsEmpty.value,
)

const disabled = computed(
  () =>
    previewDisabled.value ||
    (!!ruleId.value && !isCriteriaChanged.value && !isActionsChanged.value),
)

const actionVariant = computed(() => (disabled.value ? 'light' : undefined))

const items = computed(() =>
  ruleId.value
    ? disabled.value
      ? [SettingsActions.APPLY_CHANGES]
      : [SettingsActions.SAVE, SettingsActions.SAVE_AND_APPLY]
    : disabled.value
      ? []
      : [SettingsActions.CREATE, SettingsActions.CREATE_AND_APPLY],
)

const handleSave = async (
  event?: MouseEvent,
  apply_to_existing_transactions?: boolean,
) => {
  event?.stopImmediatePropagation()
  const data = {
    criteria: filledCriteria.value,
    actions: filledActions.value,
  }
  let refresh = false
  const isEditing = ruleId.value && ruleId.value !== PREFILLED_ID
  const nid = await progress({
    message: `${isEditing ? 'Updating' : 'Creating'} rule`,
  })
  try {
    if (isEditing) {
      linkedDataMappingRulesStore.update(ruleId.value, {
        ...currentRule.value,
        data,
        apply_to_existing_transactions,
      })
    } else {
      const order = linkedDataMappingRulesStore.getList.at(-1)?.order || 0
      await linkedDataMappingRulesStore.store({
        order: order + 1,
        data,
        apply_to_existing_transactions,
      })
      refresh = true
    }
    await update(
      nid,
      {
        type: 'success',
        message: `Rule ${isEditing ? 'updated' : 'created'}`,
      },
      NOTIFICATION_DELAY,
    )
  } catch (e) {
    await remove(nid)
    handleCatchedError(e as string, { data })
  }
  handleClose(refresh)
}

const handleClickItem = (item: DropdownItem<any>) => {
  switch (item.label) {
    case SettingsActions.APPLY_CHANGES:
      handleApply()
      break
    case SettingsActions.CREATE:
    case SettingsActions.SAVE:
      handleSave(undefined, false)
      break
    default:
      handleSave(undefined, true)
      break
  }
}

const handleApply = async () => {
  if (!currentRule.value?.data) return
  const nid = await progress({
    message: 'Apply changes',
  })
  try {
    await linkedDataMappingRulesStore.apply(currentRule.value?.data)
    await update(
      nid,
      {
        type: 'success',
        message: 'Changes applied',
      },
      NOTIFICATION_DELAY,
    )
  } catch (e) {
    await remove(nid)
    handleCatchedError(e as string, { data: currentRule.value?.data })
  }
  handleClose()
}

const handleClickPreview = () => {
  isChanges.value = true
}

const handleClickEdit = () => {
  isChanges.value = false
}

const handleClose = (refresh = false) => {
  isOpen.value = false
  setTimeout(() => {
    isChanges.value = false
    currentRule.value = undefined
  }, OVERLAY_DURATION)
  if (refresh) emit('refresh')
}

const resetData = () => {
  Object.entries(DEFAULT_CRITERIA).forEach(([key, value]) => {
    criteriaModel.value[key] = cloneDeep(value)
  })
  Object.entries(DEFAULT_ACTIONS).forEach(([key, value]) => {
    actionsModel.value[key] = cloneDeep(value)
  })
}

watch(
  ruleId,
  value => {
    if (!value) {
      resetData()
    } else {
      currentRuleData.value?.criteria.forEach(criteria => {
        criteriaModel.value[criteria.type] = cloneDeep(criteria)
      })
      currentRuleData.value?.actions.forEach(action => {
        actionsModel.value[action.type] = cloneDeep(action)
      })
    }
  },
  { immediate: true },
)
watch(escape, value => {
  if (!value) return
  handleClose()
})

defineOptions({
  name: 'LinkedDataRulesPopup',
})
</script>

<style lang="postcss">
.mapping-rules {
  &__title {
    @apply flex flex-col;

    &__criteria {
      @apply text-xs;
      @apply text-gray-400 dark:text-gray-500;
    }
  }
}
</style>
