<template>
  <component
    v-bind="{ disabled, href, to }"
    :is="component"
    ref="buttonRef"
    class="btn"
    :class="mainClasses"
    @click="handleClick"
    @keydown:enter="handleClick"
  >
    <component
      :is="iconComponent"
      v-if="isPrefixIconVisible"
      class="btn__icon"
      :class="iconClasses"
      aria-hidden="true"
    />
    <div v-if="numberLabel" class="btn__label--number">
      {{ numberLabel }}
    </div>
    <div v-if="label" :class="labelClasses">
      {{ label }}
    </div>
    <slot />
    <component
      :is="iconComponent"
      v-if="isPostfixIconVisible"
      class="btn__icon"
      :class="iconClasses"
      aria-hidden="true"
    />
  </component>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import type { Component } from 'vue'
import { RouteRecordName, RouteParams } from 'vue-router'

import { ButtonIconPosition, ButtonSizes, ButtonVariant } from './utils/types'

import UILoadingIcon from '../Loading/LoadingIcon.vue'
import { ArrowPathIcon } from '@heroicons/vue/24/outline'

type Props = {
  label?: string
  numberLabel?: number

  tag?: 'button' | 'a'
  size?: ButtonSizes
  variant?: ButtonVariant

  href?: string
  to?: {
    name: RouteRecordName
    params?: RouteParams
  }

  className?: string

  icon?: Component
  iconPos?: ButtonIconPosition

  loading?: boolean
  loadingArrows?: boolean

  full?: boolean
  disabled?: boolean

  hasBackground?: string

  hideLabelOnMobile?: boolean
  hideLabelOnTablet?: boolean
}

type Emits = {
  click: [event?: MouseEvent]
}

const {
  tag = 'button',
  size = 'default',
  variant = 'primary',
  iconPos = 'prefix',
  ...props
} = defineProps<Props>()

const emit = defineEmits<Emits>()

const buttonRef = useTemplateRef<HTMLElement>('buttonRef')

defineExpose({
  focus() {
    buttonRef.value?.focus()
  },
})

const component = computed(() => (props.to ? 'router-link' : tag))
const href = computed(() => (tag === 'a' ? props.href : null))

const iconComponent = computed(() =>
  props.loading
    ? props.loadingArrows
      ? ArrowPathIcon
      : UILoadingIcon
    : props.icon,
)
const isPrefixIconVisible = computed(
  () => iconComponent.value && iconPos === 'prefix',
)
const isPostfixIconVisible = computed(
  () => iconComponent.value && iconPos === 'postfix',
)

const mainClasses = computed(() => ({
  [`btn--${size} btn--${variant}`]: true,
  'btn--full': props.full,
  'btn--disabled': props.disabled,
  'btn--loading': props.loading,
  [`focus:ring-offset-${props.hasBackground}`]: props.hasBackground,
  ...(props.className ? { [props.className]: true } : {}),
}))

const labelClasses = computed(() => ({
  'btn__label--hide-mobile': props.hideLabelOnMobile,
  'btn__label--hide-tablet': props.hideLabelOnTablet,
  'btn__label--loading': props.loading,
}))

const iconClasses = computed(() => ({
  [`btn__icon--${size} btn__icon--${variant}`]: true,
  'btn__icon--spin': props.loading,
  'btn__icon--loading': props.loading && !props.loadingArrows,
}))

const handleClick = (event?: MouseEvent) => {
  if (props.disabled || props.loading) return
  emit('click', event)
}
</script>

<script lang="ts">
export default {
  name: 'UIButton',
}
</script>

<style scoped lang="postcss">
.btn {
  @apply inline-flex items-center justify-center;
  @apply gap-x-1.5;
  @apply border border-transparent;
  @apply focus:outline-none;
  @apply dark:ring-offset-gray-700;
  @apply cursor-pointer;

  &--full {
    @apply w-full;
  }

  &--disabled {
    @apply opacity-50 !important;
  }

  &--disabled,
  &--loading {
    @apply cursor-default;
  }

  &--xxsmall {
    @apply px-2.5 py-1.5;
    @apply text-xs font-medium;
    @apply rounded;
  }
  &--xsmall {
    @apply px-3 py-2;
    @apply text-xs leading-3 font-medium;
    @apply rounded;
  }
  &--small {
    @apply px-2.5 py-2;
    @apply text-xs leading-4 font-medium;
    @apply rounded-md;
  }
  &--default {
    @apply px-4 py-2.5;
    @apply text-sm leading-4 font-medium;
    @apply rounded-md;
  }
  &--large {
    @apply px-4 py-2;
    @apply text-base font-medium;
    @apply rounded-md;
  }
  &--xlarge {
    @apply px-6 py-3;
    @apply text-base font-medium;
    @apply rounded-md;
  }

  &--primary {
    @apply text-white dark:text-gray-100;
    @apply bg-indigo-600 dark:bg-indigo-500;

    &:not(.btn--disabled) {
      @apply hover:bg-indigo-700 dark:hover:bg-indigo-600;
      @apply focus:ring-2 focus:ring-offset-2;
      @apply focus:ring-indigo-500 dark:focus:ring-indigo-400;
    }
  }
  &--secondary {
    @apply text-indigo-700 dark:text-indigo-300;
    @apply bg-indigo-100 dark:bg-indigo-800;

    &:not(.btn--disabled) {
      @apply hover:bg-indigo-200 dark:hover:bg-indigo-900;
      @apply focus:ring-2 focus:ring-offset-2;
      @apply focus:ring-indigo-500 dark:focus:ring-indigo-400;
    }
  }
  &--light-secondary {
    @apply text-indigo-700 dark:text-indigo-400;

    &:not(.btn--disabled) {
      @apply focus:bg-indigo-100 dark:focus:bg-indigo-800;
      @apply hover:bg-indigo-50 dark:hover:bg-indigo-900;
    }
  }
  &--light {
    @apply border-gray-300 dark:border-gray-700;
    @apply text-gray-700 dark:text-gray-300;
    @apply bg-white dark:bg-gray-800;

    &:not(.btn--disabled) {
      @apply hover:bg-gray-50 dark:hover:bg-gray-900;
      @apply focus:ring-2 focus:ring-offset-2;
      @apply focus:ring-gray-500 dark:focus:ring-gray-400;
    }
  }
  &--light-gray {
    @apply text-gray-500 dark:text-gray-400;

    &:not(.btn--disabled) {
      @apply hover:bg-gray-100 focus:bg-gray-200;
      @apply dark:hover:bg-gray-700 dark:focus:bg-gray-600;
    }
  }
  &--dark {
    @apply text-white dark:text-gray-200;
    @apply bg-gray-800 dark:bg-gray-300;

    &:not(.btn--disabled) {
      @apply hover:bg-gray-900 dark:hover:bg-gray-400;
      @apply focus:ring-2 focus:ring-offset-2;
      @apply focus:ring-gray-700 dark:focus:ring-gray-600;
    }
  }
  &--red {
    @apply text-white;
    @apply bg-red-600 dark:bg-red-500;

    &:not(.btn--disabled) {
      @apply hover:bg-red-700 dark:hover:bg-red-600;
      @apply focus:ring-2 focus:ring-offset-2;
      @apply focus:ring-red-500 dark:focus:ring-red-400;
    }
  }
  &--light-red {
    @apply text-red-500 dark:text-red-400;

    &:not(.btn--disabled) {
      @apply hover:bg-red-50  focus:bg-red-100;
      @apply dark:hover:bg-red-900 dark:focus:bg-red-800;
    }
  }

  &--simple {
    @apply p-0;

    &:not(.btn--disabled) {
      @apply hover:text-gray-600 dark:hover:text-gray-400;
    }
  }

  &__label {
    @apply truncate;

    &--simple.btn__label--loading {
      @apply opacity-40;
    }

    &--number {
      @apply w-[1.15rem] h-[1.15rem];
      @apply shrink-0;
      @apply flex items-center justify-center;
      @apply -ml-0.5 -my-1;
      @apply bg-indigo-500;
      @apply text-white dark:text-gray-100;
      @apply text-[10px] font-bold;
      @apply rounded-full;
    }

    &--hide-mobile {
      @apply hidden sm:block;
    }

    &--hide-tablet {
      @apply sm:hidden md:block;
    }
  }

  &__icon {
    @apply shrink-0;
    @apply -mx-0.5;

    &--spin {
      @apply ease-linear;
      -webkit-animation: spinner 1.5s linear infinite;
      animation: spinner 1.5s linear infinite;
    }

    &--loading {
      @apply h-6 w-6;
      @apply absolute;
      @apply text-gray-200 dark:text-gray-700;
    }

    &--xxsmall {
      @apply h-3 w-3;
    }
    &--xsmall,
    &--small {
      @apply h-3 w-3;
    }
    &--default {
      @apply h-4 w-4;
    }
    &--large {
      @apply h-5 w-5;
    }
    &--xlarge {
      @apply h-6 w-6;
    }

    &--simple.btn__icon--loading {
      @apply opacity-40;
    }
  }
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
