<template>
  <UIGrid v-bind="{ columns, items, sm }" wrapped>
    <template #cellAccepted="{ displayValue, item }">
      <template v-if="displayValue">
        <UIButton
          label="Go To Repo"
          variant="secondary"
          v-bind="{ size }"
          :disabled="isGoToRepoDisabled"
          @click="hanleClickGoToRepo(item)"
          @mouseup.stop
          @mousedown.stop
        />
      </template>
      <template v-else>
        <UIButton
          label="Accept"
          variant="light-secondary"
          v-bind="{ size }"
          :icon="CheckIcon"
          @click="handleClickAccept(item)"
          @mouseup.stop
          @mousedown.stop
        />
        <UIButton
          label="Decline"
          variant="light-red"
          v-bind="{ size }"
          :icon="XMarkIcon"
          @click="handleClickDecline(item)"
          @mouseup.stop
          @mousedown.stop
        />
      </template>
    </template>
  </UIGrid>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { RepositoryInvite } from '@types'

import { EMPTY_VALUE_PLACEHOLDER } from '@/const/common'
import { ACCESS_TYPES } from '@/const/repositories'

import { useRepositoriesStore } from '@/store/repositories'

import { UIButton, UIGrid } from '@ui'
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'

type Emits = {
  close: [data: string]
}

const emit = defineEmits<Emits>()

defineOptions({ name: 'MainInvitesGrid' })

const size = 'xxsmall'

const repositoriesStore = useRepositoriesStore()

const sm = '1fr 1fr'
const columns = computed(() => [
  {
    name: 'email',
    caption: 'From',
    default: '2fr',
  },
  {
    name: 'name',
    caption: 'Repository',
  },
  {
    name: 'access_type',
    caption: 'Permissions',
    formatter: (item: RepositoryInvite) =>
      ACCESS_TYPES[Number(item) - 1] || EMPTY_VALUE_PLACEHOLDER,
    default: '120px',
  },
  {
    name: 'accepted',
    caption: '',
    unsortable: true,
    cellValueClasses: '!overflow-visible ui-grid__cell--text-right',
    default: '180px',
  },
])

const items = computed(() =>
  repositoriesStore.invitesList.map(item => ({
    ...item,
    email: item.inviter?.email || '',
    name: item.repository?.name || '',
  })),
)

const unAnsweredInvites = computed(
  () =>
    !!repositoriesStore.invitesList?.filter(
      invite => invite.accepted === undefined,
    ).length,
)

const isGoToRepoDisabled = computed(
  () => unAnsweredInvites.value || repositoriesStore.loadingList,
)

const handleClickAccept = (item: RepositoryInvite) => {
  if (!item.id) return
  repositoriesStore.acceptRepositoryInvite(item.id)
}

const handleClickDecline = (item: RepositoryInvite) => {
  if (!item.id) return
  repositoriesStore.declineRepositoryInvite(item.id)
}

const hanleClickGoToRepo = (item: RepositoryInvite) => {
  emit('close', item.repository.id)
}
</script>
