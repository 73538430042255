<template>
  <div v-if="instance" class="private-fund-flows-form">
    <div class="private-fund-flows-form__input">
      <component :is="instance.getFormDate()" v-bind="{ instance }" />
    </div>
    <div
      class="private-fund-flows-form__input private-fund-flows-form__input--type"
    >
      <component
        :is="instance.getFormType()"
        placeholder="Select type"
        v-bind="{ instance, restrictions }"
        focus-on-load
      />
    </div>
    <template v-if="type">
      <div class="private-fund-flows-form__input" />
      <div class="private-fund-flows-form__input">
        <component
          :is="instance.getFormAmount2()"
          :placeholder="`Amount in ${currency}`"
          v-bind="{ instance }"
          focus-on-load
        />
      </div>
      <div class="private-fund-flows-form__input">
        <UIButton
          v-bind="{
            disabled: isButtonDisabled,
            icon: PlusIcon,
            label: '',
          }"
          @click="handleSubmit"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, onMounted, ref } from 'vue'

import { Transaction, TransactionClass } from '@types'

import { TRANSACTION_FIELD } from '@/const'

import { UIButton } from '@ui'
import { PlusIcon } from '@heroicons/vue/24/outline'

type Props = {
  defaultData: Transaction
  restrictions: string[]
}

type Emits = {
  submit: [data: TransactionClass]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const currency = inject<ComputedRef<string>>('currency')

const instance = ref<TransactionClass>()

const isButtonDisabled = computed(() => instance.value?.isValid)

const type = computed(() => instance.value?.field(TRANSACTION_FIELD.TYPE).value)

const handleSubmit = () => {
  if (!instance.value) return
  emit('submit', instance.value)
}

onMounted(() => {
  instance.value = new TransactionClass(props.defaultData)
})
</script>

<style scoped>
.private-fund-flows-form {
  @apply grid grid-cols-[min-content_1fr_min-content];
  @apply gap-2 sm:gap-3;

  &__input {
    &--type {
      @apply col-span-2;
    }
  }
}
</style>
