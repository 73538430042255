<template>
  <component
    :is="item.icon"
    v-if="item.icon"
    class="more-section__item-icon"
    :class="iconClasses"
    aria-hidden="true"
  />
  <span v-else class="more-section__item-symbol" :class="symbolClasses">
    {{ displaySymbol }}
  </span>
  <span class="more-section__item-text" :class="textClasses">
    <slot>{{ item.label }}</slot>
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { NavigationItem } from '@types'

import { getNameSymbols } from './utils/helpers'
type Props = {
  item: NavigationItem
  active?: boolean
  disabled?: boolean
}

const props = defineProps<Props>()

const displaySymbol = computed(
  () => props.item.label && getNameSymbols(props.item.label),
)

const iconClasses = computed(() => ({
  'more-section__item-icon--active': props.active,
  'more-section__item-icon--disabled': props.disabled,
}))

const symbolClasses = computed(() => ({
  'more-section__item-symbol--active': props.active,
  'more-section__item-symbol--disabled': props.disabled,
}))

const textClasses = computed(() => ({
  'more-section__item-text--active': props.active,
  'more-section__item-text--disabled': props.disabled,
}))
</script>
