import { Amplify } from 'aws-amplify'
import { sessionStorage } from 'aws-amplify/utils'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'

export default (
  userPoolId: string,
  userPoolClientId: string,
  region = 'us-east-1',
  domain: string,
) => {
  const config = {
    Auth: {
      Cognito: {
        userPoolId,
        userPoolClientId,
        region,
        loginWith: {
          oauth: {
            domain,
            scopes: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [`${window.location.origin}/dashboards`],
            redirectSignOut: [`${window.location.origin}/login`],
            responseType: 'code',
            providers: ['Apple', 'Google'],
          } as any,
        },
      },
    },
  }

  Amplify.configure(config)

  cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage)
}
