<template>
  <div class="main-desktop-tabs">
    <MainDesktopTabsParent v-if="parent" v-bind="{ parent, disabled }" />
    <nav class="main-desktop-tabs__container">
      <MainDesktopTabsScroller v-bind="{ items, disabled }" />
      <div
        data-refid="tabsPostfixContainer"
        ref="postfixRef"
        class="main-desktop-tabs__postfix"
      />
    </nav>
    <div data-refid="actionsContainer" class="main-desktop-tabs__actions" />
  </div>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

import { useNavigationStore } from '@/store/navigation'
import { useUserSettingsStore } from '@/store/user/settings'

import MainDesktopTabsParent from './MainDesktopTabsParent.vue'
import MainDesktopTabsScroller from './MainDesktopTabsScroller.vue'

type Props = {
  disabled?: boolean
}

defineProps<Props>()

const navigationStore = useNavigationStore()
const userSettingsStore = useUserSettingsStore()

const postfixRef = useTemplateRef('postfixRef')

const parent = computed(() => navigationStore.getCurrentParent)
const currentChild = computed(() => navigationStore.getCurrentChild)

const pinnedItems = computed(() => {
  const items = userSettingsStore.getPinnedMenu
  if (items.find(item => item === currentChild.value?.key)) {
    return items
  }
  return [...items, currentChild.value?.key]
})

const items = computed(() =>
  pinnedItems.value
    .map(key => {
      return navigationStore.getCurrentChildNavigation?.find(
        item => item.key === key,
      )
    })
    .filter(item => !!item),
)
</script>

<style scoped>
.main-desktop-tabs {
  @apply relative;
  @apply flex items-center;
  @apply mb-5 -mt-4 sm:mt-0 -mx-4 sm:-mx-default;
  @apply px-4 sm:px-default;
  @apply border-b border-gray-200 dark:border-gray-600;

  &__container {
    @apply flex flex-auto;
    @apply pl-2.5 pr-1;
    @apply overflow-hidden;
  }

  &__postfix {
    > *:first-child {
      @apply ml-1.5;
    }
    > *:last-child {
      @apply -mr-1;
    }
  }

  &__actions {
    > *:first-child {
      @apply ml-2.5;
    }
  }

  &__postfix,
  &__actions {
    @apply flex items-center;
    @apply gap-2;
  }
}
</style>
