import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { defineStore } from 'pinia'

import { useMainStore } from '@/store/main'

import { TransactionClass } from '@types'

import { generateId } from '@/entities/utils/helpers'
import { isDirtyList, unsavedGridBlocker } from '../utils/helpers'

import { ROUTE_NAME } from '@/const'

import { useTransactionsStore } from './index'
import { useRepositoriesStore } from '../repositories'
import { getCurrentDate } from '@/helpers/dates'

export const useTransactionsBunchStore = defineStore(
  'transactions-bunch',
  () => {
    // INIT

    const mainStore = useMainStore()
    const transactionsStore = useTransactionsStore()
    const repositoryStore = useRepositoriesStore()

    const router = useRouter()

    const transactionsList = computed(() => transactionsStore.getList)

    // STORE

    const list = ref<Map<string, TransactionClass>>(new Map())

    // ACTIONS

    const createElement = (data?: any) => {
      const id = generateId()
      const repository_id = repositoryStore.getCurrentRepository?.id
      const element = new TransactionClass(
        { id, repository_id, date: getCurrentDate({}), ...data },
        true,
      )
      const entries = Array.from(list.value.entries())
      list.value = new Map([[id, element], ...entries])
      return element
    }

    const unshiftElement = (element: TransactionClass) => {
      const entries = Array.from(list.value.entries())
      list.value = new Map([[element.id, element], ...entries])
      return element
    }

    const duplicateElement = (id: string) => {
      const entries = Array.from(list.value.entries())
      const index = entries.findIndex(item => item[0] === id)
      if (index > -1) {
        const id = generateId()
        const entry = entries[index][1]
        const data = entry.get()
        const element = new TransactionClass({ ...data, id }, true)
        const prefixBunch = entries.slice(0, index + 1)
        const postfixBunch = entries.slice(index + 1)
        list.value = new Map([...prefixBunch, [id, element], ...postfixBunch])
      }
    }

    // GETTERS

    const getList = computed(() => (list.value.size ? list.value : new Map()))

    const getElementById = (id: string) =>
      list.value.get(id) as TransactionClass

    const isDirty = computed(() => isDirtyList(list.value))

    // FILL STORE

    watch(transactionsList, value => {
      list.value = new Map()
      value.forEach(item => {
        const instance = new TransactionClass(item)
        list.value.set(item.id, instance)
      })
    })

    // ADD BLOCKER IF DIRTY

    watch(isDirty, value => {
      if (value) {
        mainStore.addBlocker(
          'transactionsHasChanges',
          unsavedGridBlocker('transactions', () =>
            router.push({ name: ROUTE_NAME.TRANSACTIONS }),
          ),
        )
      } else {
        mainStore.removeBlocker('transactionsHasChanges')
      }
    })

    // CLEAR STORE

    const clear = () => {
      list.value = new Map()
    }

    return {
      createElement,
      unshiftElement,
      duplicateElement,

      getList,
      getElementById,
      isDirty,

      clear,
    }
  },
)
