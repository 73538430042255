<template>
  <ul class="app-notifications">
    <li v-for="item in notifies" :key="item.id">
      <div class="app-notifications__container">
        <NotificationsItem v-bind="{ item }">
          <template #header>
            <div class="app-notifications__remove">
              <XCircleIcon
                class="app-notifications__remove-icon"
                aria-hidden="true"
                @click="handleRemove(item.id)"
              />
            </div>
          </template>
        </NotificationsItem>
      </div>
    </li>
    <li v-for="(item, index) in errors" :key="index">
      <router-link
        :to="{
          name: ROUTE_NAME.TRANSACTIONS_ITEM,
          params: { id: item.id },
        }"
        class="app-notifications__container"
        @click="handleClickItem"
      >
        <NotificationsItem v-bind="{ item }" @remove="handleRemove" />
      </router-link>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { TaskNotify } from './utils/types'

import { ROUTE_NAME } from '@/const'

import { handleCatchedError } from '@/helpers/common'

import { useTasksStore } from '@/store/tasks'

import { XCircleIcon } from '@heroicons/vue/24/outline'
import NotificationsItem from './NotificationsItem.vue'

type Props = {
  notifies: TaskNotify[]
  errors: TaskNotify[]
}

type Emits = {
  'click:item': []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({ name: 'AppNotifications' })

const tasksStore = useTasksStore()

const handleRemove = (taskId: string) => {
  try {
    tasksStore.deleteEntry(taskId)
  } catch (e) {
    handleCatchedError(e as string, { taskId })
  }
}

const handleClickItem = () => {
  emit('click:item')
}
</script>

<style>
.app-notifications {
  @apply divide-y divide-gray-200 dark:divide-gray-700;
  @apply -mx-4 sm:-mx-6 md:mx-0;

  &__container {
    @apply block;
    @apply hover:bg-gray-50 dark:hover:bg-gray-850;
  }

  &--wrapped {
    @apply w-[20rem] max-h-[20rem];
    @apply bg-white dark:bg-transparent !mx-0;
    @apply overflow-auto;
  }

  &__item {
    @apply px-4 py-4 sm:px-6;
  }

  &__date {
    @apply truncate text-xs font-medium text-indigo-600;
  }

  &__message {
    @apply text-sm text-gray-500;
  }

  &__status {
    @apply mt-2 sm:mt-0;
    @apply flex items-center;
    @apply text-xs text-gray-400 whitespace-nowrap;
  }

  &__remove {
    @apply ml-2;
    @apply flex flex-shrink-0;
  }

  &__remove-icon {
    @apply w-6 sm:w-4 h-auto;
    @apply shrink-0;
    @apply text-red-400 hover:text-red-600;
    @apply cursor-pointer;
  }
}
</style>
