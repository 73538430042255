import { ROUTE_NAME } from '@/const'

import { Cog6ToothIcon, UserCircleIcon } from '@heroicons/vue/24/outline'

export const SETTINGS_LINKS = [
  {
    label: 'Common settings',
    route: ROUTE_NAME.SETTINGS,
    icon: Cog6ToothIcon,
  },
  {
    label: 'Account settings',
    route: ROUTE_NAME.SETTINGS_ACCOUNT,
    icon: UserCircleIcon,
  },
]
