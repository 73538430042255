<template>
  <button class="existing-connector">
    <div class="existing-connector__name">{{ connector.name }}</div>
    <div class="existing-connector__access-method">
      {{ connector.access_method }}
    </div>
    <div class="existing-connector__repositories">
      <RectangleStackIcon />
      <div class="existing-connector__repositories-text">
        {{ repositories }}
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { LinkedDataConnector } from '@types'
import { RectangleStackIcon } from '@heroicons/vue/24/solid'

type Props = {
  connector: LinkedDataConnector
}

const { connector } = defineProps<Props>()

const repositories = computed<string>(() =>
  connector.repositories.map(repo => repo.name).join(', '),
)
</script>

<style lang="postcss" scoped>
.existing-connector {
  @apply w-full p-2;
  @apply text-left;
  @apply rounded-lg;
  @apply text-gray-800 dark:text-gray-200;
  @apply bg-white dark:bg-gray-800;
  @apply border border-gray-300 dark:border-gray-700;
  @apply hover:border-gray-700 dark:hover:border-gray-500;
  @apply cursor-pointer;
  @apply transition-colors;

  &__name {
    @apply text-base;
  }

  &__access-method {
    @apply mb-1;
    @apply text-xs;
    @apply text-gray-500 dark:text-gray-400;
    @apply overflow-hidden text-ellipsis;
    @apply whitespace-nowrap;
  }

  &__repositories {
    @apply flex items-center;
    @apply text-xs;
    @apply gap-1;
    @apply text-gray-500 dark:text-gray-400;

    svg {
      @apply shrink-0;
      @apply w-4 h-4;
    }
  }

  &__repositories-text {
    @apply overflow-hidden text-ellipsis;
    @apply whitespace-nowrap;
  }
}
</style>
