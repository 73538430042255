<template>
  <ViewTabDropdown
    ref="dropdownRef"
    v-bind="{ item, disabled }"
    :triggers="[]"
    @edit="isEditMode = true"
    @close="handleClose"
  >
    <div v-if="isEditMode" class="more-section__item">
      <MoreSectionLinkItem v-bind="{ item, disabled }">
        <InputString v-model="labelValue" @save="handleSave" />
      </MoreSectionLinkItem>
    </div>
    <router-link
      v-else
      v-slot="{ isExactActive }"
      :to="{ name: props.item.route, params: props.item.routeParams }"
      active-class=""
      exact-active-class=""
      class="more-section__item group"
      :class="linkClasses"
      @click="handleClick"
      @contextmenu="handleContextMenu"
    >
      <MoreSectionLinkItem
        v-bind="{ item, disabled }"
        :active="isExactActive"
      />
    </router-link>
  </ViewTabDropdown>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef } from 'vue'

import { NavigationItem } from '@types'

import InputString from './InputString.vue'
import MoreSectionLinkItem from './MoreSectionLinkItem.vue'
import ViewTabDropdown from './ViewTabDropdown.vue'

type Props = {
  item: NavigationItem
  disabled?: boolean
}

type Emits = {
  click: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const dropdownRef = useTemplateRef('dropdownRef')

const isEditMode = ref(false)
const labelValue = ref(props.item.label || '')

const linkClasses = computed(() => ({
  'more-section__item--disabled': props.disabled,
}))

const handleSave = () => {
  props.item.update?.(labelValue.value)
  isEditMode.value = false
}

const handleClick = () => {
  if (props.disabled) return
  emit('click')
}

const handleClose = () => {
  emit('click')
}

const handleContextMenu = (event: MouseEvent) => {
  event.preventDefault()
  if (props.disabled) return
  dropdownRef.value?.show()
}
</script>

<style>
.more-section__item {
  &--disabled {
    @apply pointer-events-none;
  }
}
</style>
