<template>
  <div v-bind="$attrs" @click="handleOpen">
    <TreeIcon class="main-mobile-footer-icon" aria-hidden="true" />
    <span class="main-mobile-footer-caption">{{ t('Asset tree') }}</span>
  </div>
  <Teleport to="body">
    <UIModal
      v-bind="{ isOpen }"
      :type="ModalType.SLIDEPANEL"
      :title="t('Asset tree')"
      direction="to-top"
      wrapper-class="main-mobile-footer-tree"
      size="md"
      hide-minimise-button
      @hide="handleClose"
    >
      <MainTree />
    </UIModal>
  </Teleport>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { ModalType } from '@types'

import { useLocale } from '@/plugins/i18n'

import MainTree from './components/Tree.vue'
import TreeIcon from '@/assets/images/icons/tree.svg'
import { UIModal } from '@ui'

const { t } = useLocale('layouts.Main')

const isOpen = ref(false)

const handleOpen = () => {
  isOpen.value = true
}

const handleClose = () => {
  isOpen.value = false
}
</script>

<style>
.main-mobile-footer-tree {
  @apply !px-0;

  .ui-tree__search {
    @apply px-2;
  }

  .app-tree .ui-tree__item {
    @apply pr-1;
    @apply hover:bg-gray-100;
    @apply dark:bg-gray-800 dark:hover:bg-gray-750;
  }

  .app-tree__panel {
    @apply px-6;
  }

  .app-tree__checkbox {
    @apply opacity-100;

    .ui-checkbox__control {
      @apply border-gray-200 dark:border-gray-600;
    }
  }
}
</style>
